import { IconMediaPlay } from '@uilib/business-components/index';
import { Text, Tag, FlexContainer } from 'components/ui-library';
import i18n from 'Services/i18n';
import RemediationBody from 'Bricks/remediation-body';

const RULE_ACTION_TYPES = window.serverInfo.constants.ruleActionTypes;
const ACTIONS = [
    {
        value: RULE_ACTION_TYPES.triggerAlarm,
        name: 'REPORT_DETECTION',
        fullName: 'REPORT_DETECTION',
        color: 'active',
        isActive: false,
    },
    {
        value: RULE_ACTION_TYPES.markAsScript,
        name: 'MARK_AS_SCRIPT',
        fullName: 'MARK_AS_SCRIPT',
        color: 'active',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.hideCommandLine,
        name: 'HIDE_COMMAND_LINE',
        fullName: 'HIDE_COMMAND_LINE',
        color: 'active',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.blockProcessExecutable,
        name: 'BLOCK_EXECUTABLE',
        fullName: 'EXECUTABLE_BLOCKED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.cleanAndBlockProcessExecutable,
        name: 'CLEAN_AND_BLOCK_EXECUTABLE',
        fullName: 'EXECUTABLE_CLEANED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.blockParentProcessExecutable,
        name: 'BLOCK_EXECUTABLE',
        fullName: 'PARENT_EXECUTABLE_BLOCKED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.cleanAndBlockParentProcessExecutable,
        name: 'CLEAN_AND_BLOCK_EXECUTABLE',
        fullName: 'PARENT_EXECUTABLE_CLEANED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.dropEvent,
        name: 'DROP_EVENT',
        fullName: 'DROP_EVENT',
        color: 'active',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.isolateFromNetwork,
        name: 'ISOLATE_COMPUTER_FROM_NETWORK',
        fullName: 'ISOLATED_FROM_THE_NETWORK',
        color: 'orange',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.markAsCompromised,
        name: 'MARK_AS_COMPROMISED',
        fullName: 'MARK_AS_COMPROMISED',
        color: 'orange',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.killProcess,
        name: 'KILL_PROCESS_ON_THIS_COMPUTER',
        fullName: 'PROCESS_KILLED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.killParentProcess,
        name: 'KILL_PROCESS_ON_THIS_COMPUTER',
        fullName: 'PARENT_PROCESS_KILLED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.storeEvent,
        name: 'STORE_EVENT',
        fullName: 'STORE_EVENT',
        color: 'active',
        isActive: false,
    },
    {
        value: RULE_ACTION_TYPES.shutdown,
        name: 'SHUTDOWN_COMPUTER',
        fullName: 'COMPUTER_SHUT_DOWN',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.reboot,
        name: 'REBOOT_COMPUTER',
        fullName: 'COMPUTER_REBOOTED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.submitToLiveGuard,
        name: 'SUBMIT_TO_ESET_LIVEGUARD',
        fullName: 'SUBMITED_TO_LIVEGUARD',
        color: 'orange',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.submitParentToLiveGuard,
        name: 'SUBMIT_TO_ESET_LIVEGUARD',
        fullName: 'SUBMITED_PARENT_TO_LIVEGUARD',
        color: 'orange',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.logout,
        name: 'LOGOUT_USER',
        fullName: 'USER_LOGGED_OUT',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.reportIncident,
        name: 'REPORT_INCIDENT',
        fullName: 'INCIDENT_REPORTED',
        color: 'active',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.blockModule,
        name: 'BLOCK_EXECUTABLE',
        fullName: 'MODULE_BLOCKED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.cleanAndBlockModule,
        name: 'CLEAN_AND_BLOCK_EXECUTABLE',
        fullName: 'MODULE_CLEANED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.markModuleSuspicious,
        name: 'MARK_MODULE_SUSPICIOUS',
        fullName: 'MODULE_MARKED_SUSPICIOUS',
        color: 'orange',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.blockProcessSuspiciousModules,
        name: 'BLOCK_PROCESS_SUSPICIOUS_MODULES',
        fullName: 'SUSPICIOUS_MODULES_BLOCKED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.cleanAndBlockProcessSuspiciousModules,
        name: 'BLOCK_AND_CLEAN_PROCESS_SUSPICIOUS_MODULES',
        fullName: 'SUSPICIOUS_MODULES_BLOCKED_AND_CLEANED',
        color: 'red',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.submitModuleToLiveGuard,
        name: 'SUBMIT_TO_ESET_LIVEGUARD',
        fullName: 'SUBMITED_MODULE_TO_LIVEGUARD',
        color: 'orange',
        isActive: true,
    },
    {
        value: RULE_ACTION_TYPES.markAsResolved,
        name: 'MARK_DETECTION_AS_RESOLVED',
        fullName: 'DETECTION_MARKED_AS_RESOLVED',
        color: 'active',
        isActive: true,
    },
];

function calculateEffectiveActions(builtInActions, usersActions) {
    if (usersActions !== null) {
        const builtInActionsMask = builtInActions & ~RULE_ACTION_TYPES.usersActions;
        const usersActionsMask = usersActions & RULE_ACTION_TYPES.usersActions;
        return builtInActionsMask | usersActionsMask;
    }
    return builtInActions;
}

function actionIsDisabledByUser(action, builtInActions, usersActions) {
    if (!(action.value & RULE_ACTION_TYPES.usersActions)) {
        // action is not users action, can not be disabled by user
        return false;
    }
    return builtInActions & action.value && !(usersActions & action.value);
}

function getActionsDisabledByUser(builtInActions, usersActions) {
    if (usersActions !== null) {
        return ACTIONS.filter((action) => actionIsDisabledByUser(action, builtInActions, usersActions)).reduce(
            (value, action) => value | action.value,
            0
        );
    }
    return null;
}

function actionIsEnabledByUser(action, builtInActions, usersActions) {
    if (!(action.value & RULE_ACTION_TYPES.usersActions)) {
        // action is not users action, can not be enabled by user
        return false;
    }
    return usersActions & action.value && !(builtInActions & action.value);
}

function getActionsEnabledOnlyInUsersActions(builtInActions, usersActions) {
    return ACTIONS.filter((action) => actionIsEnabledByUser(action, builtInActions, usersActions)).reduce(
        (value, action) => value | action.value,
        0
    );
}

function containsActiveActions(builtInActions, usersActions) {
    const effectiveActions = calculateEffectiveActions(builtInActions, usersActions);
    return ACTIONS.some((action) => action.isActive && action.value & effectiveActions);
}

function RuleActions(props) {
    const actons = ACTIONS.filter((action) => (action.value & props.actionsValue) !== 0)
        .filter((action) => !(props.cell && action.isActive === false))
        .map((action) => (
            <Tag
                icon={<IconMediaPlay fill="currentcolor" />}
                key={action.value}
                color={props.disabled & action.value ? 'inactive' : action.color}
                border={true}
                title={true}
            >
                {i18n(props.fullName ? action.fullName : action.name)}
            </Tag>
        ));

    return <FlexContainer className={props.cell ? 'ei-tags--cell' : ''}>{actons}</FlexContainer>;
}

function RuleActionsText(props) {
    const actionsToDisplay = ACTIONS.flatMap((action) =>
        action.isActive === false || (action.value & props.actionsValue) === 0 ? [] : [i18n(action.fullName)]
    ).join(', ');
    return <Text>{actionsToDisplay}</Text>;
}

function RuleActionsOverview(props) {
    return (
        <RemediationBody
            disableHeaderBorder
            remediationHeader={[
                {
                    label: <Text fontWeight="bold">BUILT_IN_ACTIONS</Text>,
                    content: (
                        <RuleActions
                            actionsValue={props.actions}
                            disabled={getActionsDisabledByUser(props.actions, props.usersActions)}
                        />
                    ),
                },
                {
                    label: <Text fontWeight="bold">USER_ACTIONS</Text>,
                    content: (
                        <RuleActions
                            actionsValue={getActionsEnabledOnlyInUsersActions(props.actions, props.usersActions)}
                        />
                    ),
                },
            ]}
        />
    );
}

export {
    RuleActions,
    RuleActionsText,
    RuleActionsOverview,
    getActionsDisabledByUser,
    containsActiveActions,
    calculateEffectiveActions,
};
