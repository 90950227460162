import React from 'react';
import { Button } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

const ButtonWrapper = React.forwardRef((props, ref) => {
    return <Button {...props} ref={ref} text={i18n(props.text)} />;
});

export default ButtonWrapper;
