import { IconArrowTriangularDown } from '@uilib/business-components/index';
import React from 'react';
import { Button, FlexContainer } from 'components/ui-library';
import i18n from 'Services/i18n';

const DropdownButton = React.forwardRef((props, ref) => (
    <Button ref={ref} id={props.id} disabled={props.disabled} onClick={props.toggle} type="secondary">
        <FlexContainer className="no-wrap" align="center">
            <span>{i18n(props.title)}</span>
            <IconArrowTriangularDown fill="currentcolor" />
        </FlexContainer>
    </Button>
));

export default DropdownButton;
