import { List } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

function ListWrapper(props) {
    return (
        <List
            {...props}
            items={props.items === undefined ? undefined : props.items.map(({ text }) => ({ text: i18n(text) }))}
        />
    );
}

function ListItemWrapper(props) {
    return <List.Item {...props} text={i18n(props.text)} children={i18n(props.children)} />;
}

ListWrapper.Item = ListItemWrapper;

export default ListWrapper;
