import { IconActionEdit, IconActionDelete } from '@uilib/business-components/index';
import i18n from 'Services/i18n';
import Filters from 'Services/Filters';

import HighlightBlank from 'Bricks/highlight-blank';
import { List } from 'Bricks/List';

import { Link, FlexContainer, Spacer } from 'components/ui-library';
import colors from 'eset-ui-colors';

function CommentList(props) {
    return props.comments.map((comment, index) => {
        const label = comment.lastEditTime
            ? i18n('USER_CREATED_LAST_EDIT', {
                  user: comment.authorName,
                  whenCreated: Filters.localDate(comment.creationTime),
                  whenEdited: Filters.localDate(comment.lastEditTime),
              })
            : i18n('USER_CREATED', {
                  user: comment.authorName,
                  whenCreated: Filters.localDate(comment.creationTime),
              });

        return (
            <List.Item highlightBlank={false} label={label}>
                <HighlightBlank keepWhiteSpaces={true} value={comment.commentValue} />
                {props.username === comment.authorName ? (
                    <div style={{ marginLeft: 'auto' }}>
                        <FlexContainer align="center" justify="center">
                            <Spacer type="mr-3">
                                <Link
                                    onClick={() => props.handleEditComment(comment.commentId, comment.commentValue)}
                                    icon={<IconActionEdit fill={colors.primary} disabled={props.disabled} />}
                                    text="EDIT"
                                    disabled={props.disabled}
                                />
                            </Spacer>
                            <Spacer type="mr-3">
                                <Link
                                    onClick={() => props.handleDeleteComment(comment.commentId)}
                                    icon={<IconActionDelete fill={colors.primary} disabled={props.disabled} />}
                                    text="DELETE"
                                    disabled={props.disabled}
                                />
                            </Spacer>
                        </FlexContainer>
                    </div>
                ) : null}
            </List.Item>
        );
    });
}

export default CommentList;
