import { Component } from 'react';

import { Button, ConfirmationModal } from 'components/ui-library';

import { EVENT, EventNames } from 'Services/Eventing';
import { BACKEND, withBackend } from 'Services/backend';

class RemediationConfirmationModal extends Component {
    constructor(props) {
        super(props);

        this.options = {
            message: '',
        };

        this.state = {
            confirmationWindowVisible: false,
        };
    }

    componentDidMount() {
        this.remediationRebootUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_REBOOT_EVENT,
            (message, { computerId, alarmId }) => {
                this.reboot(computerId, alarmId);
            }
        );

        this.remediationShutdownUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_SHUTDOWN_EVENT,
            (message, { computerId, alarmId }) => {
                this.shutdown(computerId, alarmId);
            }
        );

        this.remediationKillUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_KILL_EVENT,
            (message, { processId, alarmId }) => {
                this.kill(processId, alarmId);
            }
        );

        this.remediationScanUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_SCAN_EVENT,
            (message, { computerId, alarmId }) => {
                this.scan(computerId, alarmId);
            }
        );

        this.remediationSyncUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_SYNC_EVENT,
            (message, { computerId, alarmId }) => {
                this.sync(computerId, alarmId);
            }
        );

        this.remediationSysInspectorLogUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_SYSINSPECTORLOG_EVENT,
            (message, { computerId, alarmId }) => {
                this.generateSysInspectorLog(computerId, alarmId);
            }
        );

        this.remediationIsolateUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_ISOLATE_EVENT,
            (message, { computerId, callback, alarmId }) => {
                this.isolate(computerId, callback, alarmId);
            }
        );

        this.remediationIntegrateUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_INTEGRATE_EVENT,
            (message, { computerId, callback, alarmId }) => {
                this.integrate(computerId, callback, alarmId);
            }
        );

        this.remediationLogoutUnsubscribe = EVENT.subscribe(
            EventNames.REMEDIATION_LOGOUT_EVENT,
            (message, { computerId, alarmId }) => {
                this.logout(computerId, alarmId);
            }
        );
    }

    componentWillUnmount() {
        this.remediationRebootUnsubscribe();
        this.remediationShutdownUnsubscribe();
        this.remediationKillUnsubscribe();
        this.remediationScanUnsubscribe();
        this.remediationSyncUnsubscribe();
        this.remediationSysInspectorLogUnsubscribe();
        this.remediationIsolateUnsubscribe();
        this.remediationIntegrateUnsubscribe();
    }

    handleConfirmationClose = () => {
        this.setState({
            confirmationWindowVisible: false,
        });
    };

    handleConfirmationAction = () => {
        this.handleConfirmationClose();

        BACKEND.post(this.options.url, this.options.request, this.props.componentUuid)
            .success((response) => {
                this.showNotificationWindow({
                    success: true,
                    text: this.options.summary,
                });
                if (this.options.callback) {
                    this.options.callback();
                }
            })
            .execute();
    };

    confirmAction(options) {
        this.options = options;
        if (options.message === undefined && options.text === undefined) {
            this.handleConfirmationAction();
        } else {
            this.setState({
                confirmationWindowVisible: true,
            });
        }
    }

    showNotificationWindow(options) {
        EVENT.publish(EventNames.SIMPLE_ALERT_EVENT, {
            type: options.success ? 'confirm' : 'error',
            message: options.success ? 'REMEDIATION_SUCCEEDED' : 'REMEDIATION_FAILED',
            details: options.text,
        });
    }

    reboot(computerId, alarmId) {
        this.confirmAction({
            message: 'REBOOT_THIS_COMPUTER',
            text: 'THIS_WILL_CAUSE_SELECTED_COMPUTER_TO_INITIATE_REBOOT_THIS_COULD_CAUSE_LOSS_OF_DATA',
            request: { action: 'reboot', computer: computerId, alarm: alarmId },
            summary: 'COMPUTER_IS_QUEUED_FOR_REBOOT',
            url: 'remediation',
        });
    }

    shutdown(computerId, alarmId) {
        this.confirmAction({
            message: 'SHUTDOWN_THIS_COMPUTER',
            text: 'THIS_WILL_CAUSE_SELECTED_COMPUTER_TO_INITIATE_SHUTDOWN_THIS_COULD_CAUSE_LOSS_OF_DATA',
            request: { action: 'shutdown', computer: computerId, alarm: alarmId },
            summary: 'COMPUTER_IS_QUEUED_FOR_SHUTDOWN',
            url: 'remediation',
        });
    }

    logout(computerId, alarmId) {
        this.confirmAction({
            message: 'LOGOUT_USER',
            text: 'THIS_WILL_LOGOUT_USER',
            request: { action: 'logOutUser', computer: computerId, alarm: alarmId },
            summary: 'USER_IS_QUEUED_FOR_LOGOUT',
            url: 'remediation',
        });
    }

    kill(processId, alarmId) {
        this.confirmAction({
            message: 'KILL_SELECTED_PROCESS',
            text: 'THIS_WILL_CAUSE_IMMEDIATE_TERMINATION_OF_SELECTED_PROCESS_AND_MAY_LEAD_TO_INSTABILITY_OR_DATA_LOSS',
            request: { action: 'killProcess', dbProcessId: processId, alarm: alarmId },
            summary: 'PROCESS_IS_QUEUED_FOR_TERMINATION',
            url: 'remediation',
        });
    }

    scan(computerId, alarmId) {
        this.confirmAction({
            message: 'SCAN_THIS_COMPUTER',
            text: 'THIS_WILL_TRIGGER_SCANNING_OF_SELECTED_COMPUTER',
            request: { action: 'scan', computer: computerId, alarm: alarmId },
            summary: 'COMPUTER_IS_QUEUED_FOR_SCAN',
            url: 'remediation',
        });
    }

    sync(computerId, alarmId) {
        this.confirmAction({
            request: { action: 'sync', computer: computerId, alarm: alarmId },
            summary: 'COMPUTER_SHOULD_SENT_EVENTS_SOON',
            url: 'remediation',
        });
    }

    generateSysInspectorLog(computerId, alarmId) {
        this.confirmAction({
            message: 'GENERATE_SYS_INSPECTOR_LOG_FOR_THIS_COMPUTER',
            text: 'SELECTED_COMPUTER_WILL_BE_REQUESTED_TO_EXECUTE_SYSINSPECTOR_TO_GENERATE_SYSTEM_LOG',
            request: { action: 'sysinspectorlog', computer: computerId, alarm: alarmId },
            summary: 'LOG_IS_QUEUED_FOR_GENERATION',
            url: 'remediation',
        });
    }

    isolate(computerId, callback, alarmId) {
        this.confirmAction({
            message: 'ISOLATE_THIS_COMPUTER_FROM_NETWORK',
            text:
                'SELECTED_COMPUTERS_WILL_BE_ISOLATED_FROM_NETWORK_AND_ALL_CONNECTIONS_EXCEPT_THOSE_NEEDED_FOR_CORRECT_OPERATION_OF_ESET_PRODUCTS_WILL_BE_BLOCKED_THIS_WILL_LIKELY_INTERRUPT_NORMAL_OPERATION_OF_COMPUTERS_AND_SHOULD_BE_USED_IN_EMERGENCY_CASES_ONLY_ISOLATION_CAN_BE_ENDED_BY_USING_END_NETWORK_ISOLATION_BUTTON_CONTEXT_MENU_ITEM',
            request: { action: 'isolate', computer: computerId, alarm: alarmId },
            summary: 'COMPUTER_IS_QUEUED_FOR_NETWORK_ISOLATION',
            url: 'remediation',
            callback,
        });
    }

    integrate(computerId, callback, alarmId) {
        this.confirmAction({
            message: 'END_THIS_COMPUTER_ISOLATION_FROM_NETWORK',
            text:
                'THIS_WILL_END_ISOLATION_FROM_NETWORK_CONNECTIONS_WILL_BE_ALLOWED_AGAIN_THIS_SHOULD_ONLY_BE_USED_WHEN_SECURITY_ISSUE_HAS_BEEN_RESOLVED',
            request: { action: 'integrate', computer: computerId, alarm: alarmId },
            summary: 'COMPUTER_IS_QUEUED_FOR_BEGIN_REMOVED_FROM_NETWORK_ISOLATION',
            url: 'remediation',
            callback,
        });
    }

    render() {
        return (
            <ConfirmationModal
                show={this.state.confirmationWindowVisible}
                type="warning"
                message={this.options.message}
                text={this.options.text}
                onDispose={this.handleConfirmationClose}
                buttons={[
                    <Button
                        id="eid-remediation-button-yes"
                        type="primary"
                        text="YES"
                        onClick={this.handleConfirmationAction}
                    />,
                    <Button
                        id="eid-remediation-button-no"
                        type="secondary"
                        text="NO"
                        onClick={this.handleConfirmationClose}
                    />,
                ]}
            />
        );
    }
}

export default withBackend(RemediationConfirmationModal);
