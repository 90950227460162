import {
    IconMenuExpand,
    IconMenuCollapse,
    IconArrowSplit,
    IconFolderTree,
    IconObjectTags,
    IconMenuMoreVertical,
} from '@uilib/business-components/index';
import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Headline, ConfirmationModal, Dropdown, SplitPane } from 'components/ui-library';

import Filters from 'Services/Filters';
import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';

import Panel from 'Bricks/Panel';
import Grid from 'Bricks/Grid/Grid';
import { escapeRegExp, isAffectedRowsLessThanSelectedRows, updateLocalFiltersWithTags } from 'Bricks/Helpers';
import TagsEditModal from 'Bricks/tags-edit-modal';
import IconButton from 'Bricks/icon-button';
import { TABLE_COLUMN_WIDTH, TABLE_PAGE_SIZE } from 'Bricks/Grid/Grid';
import GridCells from 'Bricks/Grid/Cells';

import GroupsPane from './groups-pane';
import TagsPane from './tags-pane';
import { toast } from 'components/ui-library';
import { EVENT, EventNames } from 'Services/Eventing';

import './index.scss';

const PANE_VIEW_MODE_STATE = 'eid-state';
const PANE_VIEW_MODE_BOTH = 'eid-both';
const PANE_VIEW_MODE_TAGS = 'eid-tags';
const PANE_VIEW_MODE_GROUPS = 'eid-groups';

const DEFAULT_TAGS_GROUPS_HORIZONTAL_RATIO = 20;
const DEFAULT_TAGS_GROUPS_VERTICAL_RATIO = 50;

function PaneSwitcher(props) {
    const PANE_MENU_CONTENT = [
        {
            header: 'SIDE_PANEL_LAYOUT',
            items: [
                {
                    id: PANE_VIEW_MODE_STATE,
                    icon: props.collapsed ? (
                        <IconMenuExpand fill="currentcolor" />
                    ) : (
                        <IconMenuCollapse fill="currentcolor" />
                    ),
                    title: props.collapsed ? 'SHOW_SIDE_PANEL' : 'HIDE_SIDE_PANEL',
                    callback: (event) => {
                        props.onClick(event, !props.collapsed);
                    },
                },
                {
                    id: PANE_VIEW_MODE_BOTH,
                    title: 'GROUPS_AND_TAGS',
                    icon: <IconArrowSplit fill="currentcolor" />,
                    callback: (event) => {
                        props.onChange(event, PANE_VIEW_MODE_BOTH);
                        if (props.collapsed) {
                            props.onClick(event, !props.collapsed);
                        }
                    },
                },
                {
                    id: PANE_VIEW_MODE_GROUPS,
                    icon: <IconFolderTree fill="currentcolor" />,
                    title: 'GROUPS',
                    callback: (event) => {
                        props.onChange(event, PANE_VIEW_MODE_GROUPS);
                        if (props.collapsed) {
                            props.onClick(event, !props.collapsed);
                        }
                    },
                },
                {
                    id: PANE_VIEW_MODE_TAGS,
                    icon: <IconObjectTags fill="currentcolor" />,
                    title: 'TAGS',
                    callback: (event) => {
                        props.onChange(event, PANE_VIEW_MODE_TAGS);
                        if (props.collapsed) {
                            props.onClick(event, !props.collapsed);
                        }
                    },
                },
            ],
        },
    ];

    function handleClick(event) {
        props.onClick(event, !props.collapsed);
    }

    return (
        <Fragment>
            {props.expandable && props.title && (
                <Headline className="ui-fix-page-headline-title" bottomMargin={false} type="h2">
                    {i18n(props.title)}
                </Headline>
            )}
            {props.expandable && (
                <Fragment>
                    {props.showMenu ? (
                        <Dropdown
                            id={'side-panel-options'}
                            placement="right"
                            reference={
                                <IconButton
                                    testDataLabel="eid-switch-side-panel"
                                    white
                                    size={24}
                                    icon={<IconMenuMoreVertical fill="currentcolor" />}
                                />
                            }
                            content={
                                props.collapsed
                                    ? PANE_MENU_CONTENT
                                    : PANE_MENU_CONTENT.map((content) => ({
                                          ...content,
                                          items: content.items.filter((child) => child.id !== props.viewMode),
                                      }))
                            }
                        />
                    ) : (
                        <IconButton
                            testDataLabel="eid-switch-side-panel"
                            white
                            size={16}
                            icon={
                                props.collapsed ? (
                                    <IconMenuExpand fill="currentcolor" />
                                ) : (
                                    <IconMenuCollapse fill="currentcolor" />
                                )
                            }
                            onClick={handleClick}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

function ComplexTableView(props) {
    const SETTINGS_URL = `users/settings/complex_${props.storage}`;

    const [initialized, setInitialized] = useState(false);
    const [viewMode, setViewMode] = useState(props.showGroupsTree ? PANE_VIEW_MODE_BOTH : PANE_VIEW_MODE_TAGS);

    const [collapsed, setCollapsed] = useState(!props.showGroupsTree);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagsEditOpen, setTagsEditOpen] = useState(false);

    const [tagToDelete, setTagToDelete] = useState(0);
    const [tagDetails, setTagDetails] = useState([]);
    const [isTagDeleteModalOpen, setIsTagDeleteModalOpen] = useState(false);

    const [expandedGroups, setExpandedGroups] = useState([1]);
    const [tags, setTags] = useState([]);
    const [activeTags, setActiveTags] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [page, setPage] = useState(0);
    const [pattern, setPattern] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const tableViewRef = useRef(null);

    const [verticalTagsGroupsFirstPaneRatio, setVerticalTagsGroupsFirstPaneRatio] = useState(
        DEFAULT_TAGS_GROUPS_VERTICAL_RATIO
    );
    const [horizontalTagsGroupsFirstPaneRatio, setHorizontalTagsGroupsFirstPaneRatio] = useState(
        DEFAULT_TAGS_GROUPS_HORIZONTAL_RATIO
    );

    const disableSaveHorizontal = useRef(collapsed || !initialized);
    const disableSaveVertical = useRef(collapsed || !initialized || viewMode !== PANE_VIEW_MODE_BOTH);

    useEffect(() => {
        disableSaveHorizontal.current = collapsed || !initialized;
    }, [collapsed, initialized]);

    useEffect(() => {
        disableSaveVertical.current = collapsed || !initialized || viewMode !== PANE_VIEW_MODE_BOTH;
    }, [viewMode, collapsed, initialized]);

    function saveHorizontalTagsGroupsFirstPaneRatio(size) {
        !disableSaveHorizontal.current && setHorizontalTagsGroupsFirstPaneRatio(size);
    }

    function saveVerticalTagsGroupsFirstPaneRatio(size) {
        !disableSaveVertical.current && setVerticalTagsGroupsFirstPaneRatio(size);
    }

    function resetSplitPanels() {
        setHorizontalTagsGroupsFirstPaneRatio(DEFAULT_TAGS_GROUPS_HORIZONTAL_RATIO);
        setVerticalTagsGroupsFirstPaneRatio(DEFAULT_TAGS_GROUPS_VERTICAL_RATIO);
        setViewMode(props.showGroupsTree ? PANE_VIEW_MODE_BOTH : PANE_VIEW_MODE_TAGS);
        setCollapsed(!props.showGroupsTree);
    }

    // Switch onParamsCreated with our internal one for tags purposes.
    function onParamsCreated(requestBody) {
        if (props.options.onParamsCreated) {
            props.options.onParamsCreated(requestBody);
        }
        requestBody.localFilters = updateLocalFiltersWithTags(requestBody.localFilters, activeTags);
    }

    function onSelectionParamsCreated(requestBody) {
        if (props.options.onSelectionParamsCreated) {
            props.options.onSelectionParamsCreated(requestBody);
        }
        requestBody.localFilters = updateLocalFiltersWithTags(requestBody.localFilters, activeTags);
    }

    let columns = [...props.options.columns];
    columns.splice(2, 0, {
        field: 'tags',
        name: 'TAGS',
        width: TABLE_COLUMN_WIDTH.WIDE,
        cellComponent: GridCells.TagsCell,
        visible: props.showTagsColumn,
    });

    let gridOptions = { ...props.options, onParamsCreated, onSelectionParamsCreated, columns };

    const commandingConfiguration = {
        commands: props.commandingConfiguration.commands,
        toolbar: {
            attachIncidentCommands: props.commandingConfiguration.toolbar.attachIncidentCommands,
            left: props.commandingConfiguration.toolbar.left,
            right: props.commandingConfiguration.toolbar.right,
            rightStatic: props.commandingConfiguration.toolbar.rightStatic,
        },
        context: {
            header: props.commandingConfiguration.context.header,
            layout: props.commandingConfiguration.context.layout,
            dynamicLayout: props.commandingConfiguration.context.dynamicLayout,
            attachToCommand: props.commandingConfiguration.context.attachToCommand,
        },
        groupContext:
            props.commandingConfiguration.groupContext === undefined
                ? undefined
                : {
                      header: props.commandingConfiguration.groupContext.header,
                      layout: props.commandingConfiguration.groupContext.layout,
                      dynamicLayout: props.commandingConfiguration.groupContext.dynamicLayout,
                      attachToCommand: props.commandingConfiguration.groupContext.attachToCommand,
                      disableNewTabAction: props.commandingConfiguration.groupContext.disableNewTabAction,
                  },
    };

    const newCommands = {
        TAGS: { name: 'TAGS', icon: <IconObjectTags fill="currentcolor" />, clbk: onTagsEdit, multiSelection: true },
    };

    if (props.commandingConfiguration.tags !== false) {
        commandingConfiguration.toolbar.right = ['TAGS', ...commandingConfiguration.toolbar.right];
        if (!commandingConfiguration.context.dynamicLayout?.includes('TAGS')) {
            commandingConfiguration.context.dynamicLayout = [
                'TAGS',
                ...(commandingConfiguration.context.dynamicLayout ? commandingConfiguration.context.dynamicLayout : []),
            ];
        }
        if (
            commandingConfiguration.groupContext &&
            !commandingConfiguration.groupContext.dynamicLayout?.includes('TAGS')
        ) {
            commandingConfiguration.groupContext.dynamicLayout = [
                'TAGS',
                ...(commandingConfiguration.groupContext.dynamicLayout
                    ? commandingConfiguration.groupContext.dynamicLayout
                    : []),
            ];
        }
    }
    const menuName = 'CONTEXT_MENU';
    newCommands[menuName] = {
        commands: [
            ...(commandingConfiguration.context.layout ? commandingConfiguration.context.layout : []),
            ...(commandingConfiguration.context.dynamicLayout ? [commandingConfiguration.context.dynamicLayout] : []),
        ],
        name: commandingConfiguration.context.header,
        multiSelection: true,
    };

    if (commandingConfiguration.context.layout || commandingConfiguration.context.dynamicLayout) {
        commandingConfiguration.toolbar.contextMenu = menuName;
    }

    if (commandingConfiguration.groupContext) {
        for (const id in commandingConfiguration.groupContext?.layout) {
            if (!commandingConfiguration.toolbar.groupContextMenu) {
                commandingConfiguration.toolbar.groupContextMenu = {};
            }
            if (!commandingConfiguration.toolbar.groupContextMenu?.[id]) {
                const CONTEXT_MENU = {
                    commands: [
                        ...(commandingConfiguration.groupContext?.layout[id]
                            ? commandingConfiguration.groupContext?.layout[id]
                            : []),
                        ...(commandingConfiguration.groupContext.dynamicLayout
                            ? [commandingConfiguration.context.dynamicLayout]
                            : []),
                    ],
                    name: commandingConfiguration.context.header,
                    disableNewTabAction: commandingConfiguration.groupContext?.disableNewTabAction?.[id],
                    multiSelection: true,
                };
                const menuName = `CONTEXT_MENU_${id}`;
                newCommands[menuName] = CONTEXT_MENU;
                commandingConfiguration.toolbar.groupContextMenu[id] = menuName;
            }
        }
    }
    commandingConfiguration.commands = { ...commandingConfiguration.commands, ...newCommands };

    useEffect(() => {
        loadSettings();
    }, []);

    useEffect(() => {
        if (initialized) {
            saveSettings();
        }
    }, [
        collapsed,
        viewMode,
        activeTags,
        expandedGroups,
        verticalTagsGroupsFirstPaneRatio,
        horizontalTagsGroupsFirstPaneRatio,
    ]);

    useEffect(() => {
        if (initialized) {
            tableViewRef.current?.reloadData();
        }
    }, [initialized, activeTags]);

    useEffect(() => {
        setIsLoading(true);
        if ((totalCount !== null && tags.length === totalCount) || tags.length > page * TABLE_PAGE_SIZE) {
            return;
        }
        const requestBody = {
            localFilters: { filterTree: pattern !== '' ? { name: { LIKE: escapeRegExp(pattern) } } : {} },
            sortOrders: [{ column: 'name', ascend: true }],
            pageSize: TABLE_PAGE_SIZE,
            session: false, // Tags might be removed through the EI or ESMC.
        };

        BACKEND.post(`tags/${page}`, requestBody, props.componentUuid)
            .success((response) => {
                setTotalCount(response.totalCount);
                setTags(tags.concat(response.entities.map((entity) => ({ ...entity, active: false }))));
            })
            .always(() => {
                setIsLoading(false);
            })
            .execute();
    }, [props.componentUuid, pattern, page, tags]);

    function handlePaneSwitchClick(event, value) {
        setCollapsed(value);
    }

    function handlePaneSwitchChange(event, mode) {
        setViewMode(mode);
    }

    //-------------------------------------
    // SETTINGS LOAD & SAVE
    //-------------------------------------
    function saveSettings() {
        BACKEND.put(
            SETTINGS_URL,
            {
                collapsed,
                viewMode,
                activeTags,
                expandedGroups,
                verticalTagsGroupsFirstPaneRatio,
                horizontalTagsGroupsFirstPaneRatio,
            },
            props.componentUuid
        ).execute();
    }

    function loadSettings() {
        BACKEND.get(SETTINGS_URL, props.componentUuid)
            .success((response) => {
                if (response.collapsed !== undefined) {
                    setCollapsed(response.collapsed);
                }
                if (response.viewMode !== undefined) {
                    setViewMode(response.viewMode);
                }
                if (response.activeTags !== undefined) {
                    setActiveTags(response.activeTags);
                }
                if (response.expandedGroups !== undefined) {
                    setExpandedGroups(response.expandedGroups);
                }
                if (response.verticalTagsGroupsFirstPaneRatio !== undefined) {
                    setVerticalTagsGroupsFirstPaneRatio(response.verticalTagsGroupsFirstPaneRatio);
                }
                if (response.horizontalTagsGroupsFirstPaneRatio !== undefined) {
                    setHorizontalTagsGroupsFirstPaneRatio(response.horizontalTagsGroupsFirstPaneRatio);
                }
            })
            .always(() => {
                setInitialized(true);
            })
            .execute();
    }

    //-------------------------------------
    // GROUPS VIEW GROUP EXPAND/COLLAPSE
    //-------------------------------------
    function handleGroupsExpandedChange(expanded) {
        setExpandedGroups(expanded);
    }

    //-------------------------------------
    // TAGS VIEW UPLOAD
    //-------------------------------------
    function refreshTags() {
        setTotalCount(null);
        setTags([]);
        setPage(0);
    }

    function handleTagsViewScroll(scrollTop, scrollTopMax) {
        if (totalCount !== null && tags.length < totalCount && scrollTop > 0.8 * scrollTopMax) {
            setPage(page + 1);
        }
    }

    function handleTagsPatternChange(value, event) {
        setPattern(value);
        refreshTags();
    }

    //-------------------------------------
    // TAGS ACTIVATION
    //-------------------------------------
    function handleLocalFiltersTagsChange(event, localFiltersTags) {
        setActiveTags(
            Array.isArray(localFiltersTags) ? localFiltersTags.map((tag) => ({ id: tag.value, name: tag.label })) : []
        );
    }

    function handleTagClick(value, event) {
        const activeTagsTemp = [...activeTags];
        const TAG_IDX = activeTagsTemp.findIndex((tag) => tag.id === value.id);
        if (TAG_IDX === -1) {
            activeTagsTemp.splice(0, 0, value);
        } else {
            activeTagsTemp.splice(TAG_IDX, 1);
        }
        setActiveTags(activeTagsTemp);
    }

    //-------------------------------------
    // TAG SELECTION EDIT
    //-------------------------------------
    function onTagsEdit(event) {
        tableViewRef.current.updateBackendWithSelectionEx(
            'post',
            'tags/list',
            {
                objectType: props.tagsObjectType,
                processId: props.tagHelpers.processId,
                moduleId: props.tagHelpers.moduleId,
                ...props.options.additionalDataForRequestBody,
            },
            (selectedTags) => {
                setSelectedTags(selectedTags);
                setTagsEditOpen(true);
            }
        );
    }

    function handleTagsEditClose(event) {
        setTagsEditOpen(false);
    }

    function handleTagsEditChange(event, diff) {
        tableViewRef.current.updateBackendWithSelectionEx(
            'post',
            'tags/update',
            {
                objectType: props.tagsObjectType,
                added: diff.added,
                removed: diff.removed,
                processId: props.tagHelpers.processId,
                moduleId: props.tagHelpers.moduleId,
                ...props.options.additionalDataForRequestBody,
            },
            (response, selectedCount) => {
                isAffectedRowsLessThanSelectedRows(response, selectedCount);
                refreshTags();
                EVENT.publish(EventNames.DETAILS_MODIFIED_EVENT);
                tableViewRef.current.reloadData();
            },
            (respose) => {
                toast.error(i18n('TAG_CREATION_FAILED'), { autoClose: 3000 });
            }
        );
    }

    //-------------------------------------
    // TAG REMOVAL
    //-------------------------------------
    function handleTagRemove(value, event) {
        setTagToDelete(value);

        BACKEND.get(`tags/${value.id}/count`, props.componentUuid)
            .success((response) => {
                setTagDetails(response);
                setIsTagDeleteModalOpen(true);
            })
            .execute();
    }

    function handleTagDeleteModalDispose(event) {
        setIsTagDeleteModalOpen(false);
    }

    function handleTagDelete(event) {
        setIsTagDeleteModalOpen(false);

        BACKEND.post(`tags/${tagToDelete.id}/delete`, {}, props.componentUuid)
            .success((response) => {
                setActiveTags(activeTags.filter((tag) => tag.id !== tagToDelete.id));
                refreshTags();
            })
            .execute();
    }

    function handleGroupsChange(groupId, groupName) {
        tableViewRef.current.reloadData();
    }

    return (
        <Fragment>
            <SplitPane
                forceFirstPaneRatio={collapsed || !initialized}
                firstPaneRatio={collapsed || !initialized ? 0 : horizontalTagsGroupsFirstPaneRatio}
                savePaneRatio={saveHorizontalTagsGroupsFirstPaneRatio}
            >
                <Panel
                    testDataLabel="eid-tags-groups-side-panel"
                    headerClassName="complex-table-view-header"
                    headerType="big"
                    title={props.title}
                    childrenAlignRight
                    header={
                        <PaneSwitcher
                            showMenu={props.showGroupsTree}
                            viewMode={viewMode}
                            expandable={props.expandable}
                            collapsed={collapsed}
                            onClick={handlePaneSwitchClick}
                            onChange={handlePaneSwitchChange}
                        />
                    }
                >
                    <SplitPane
                        horizontal
                        forceFirstPaneRatio={viewMode === PANE_VIEW_MODE_GROUPS || viewMode === PANE_VIEW_MODE_TAGS}
                        firstPaneRatio={
                            viewMode === PANE_VIEW_MODE_GROUPS
                                ? 100
                                : viewMode === PANE_VIEW_MODE_TAGS
                                ? 0
                                : verticalTagsGroupsFirstPaneRatio
                        }
                        savePaneRatio={saveVerticalTagsGroupsFirstPaneRatio}
                    >
                        {viewMode === PANE_VIEW_MODE_TAGS ? (
                            <Fragment />
                        ) : (
                            <GroupsPane
                                isLoading={!initialized}
                                expanded={expandedGroups}
                                onExpandedChange={handleGroupsExpandedChange}
                                onChange={handleGroupsChange}
                            />
                        )}
                        {viewMode === PANE_VIEW_MODE_GROUPS ? (
                            <Fragment />
                        ) : (
                            <TagsPane
                                isLoading={isLoading || !initialized}
                                testDataLabel="eid-tags-pane"
                                tags={tags}
                                activeTags={activeTags}
                                onScroll={handleTagsViewScroll}
                                pattern={pattern}
                                onPatternChange={handleTagsPatternChange}
                                onTagClick={handleTagClick}
                                onTagRemove={handleTagRemove}
                            />
                        )}
                    </SplitPane>
                </Panel>
                <Grid
                    testDataLabel={props.testDataLabel}
                    title={
                        collapsed && (
                            <PaneSwitcher
                                showMenu={props.showGroupsTree}
                                viewMode={viewMode}
                                expandable={props.expandable}
                                collapsed={collapsed}
                                title={props.title}
                                onClick={handlePaneSwitchClick}
                                onChange={handlePaneSwitchChange}
                            />
                        )
                    }
                    ref={tableViewRef}
                    storage={props.storage}
                    options={gridOptions}
                    localFiltersOptions={props.localFiltersOptions}
                    localFiltersTags={tags}
                    localFiltersActiveTags={activeTags}
                    localFiltersTagsOnChange={handleLocalFiltersTagsChange}
                    commandingConfiguration={commandingConfiguration}
                    showWarningEventsStoringDisabled={props.showWarningEventsStoringDisabled}
                    linkFilters={props.linkFilters}
                    idParameter={props.idParameter}
                    settingsInitialized={initialized}
                    CustomCommandingComponent={props.CustomCommandingComponent}
                    resetSplitPanels={resetSplitPanels}
                    filterId={props.filterId}
                />
            </SplitPane>
            <TagsEditModal
                open={tagsEditOpen}
                tags={selectedTags}
                onChange={handleTagsEditChange}
                onClose={handleTagsEditClose}
            />
            <ConfirmationModal
                show={isTagDeleteModalOpen}
                type="warning"
                message="THIS_TAG_WILL_BE_DELETED_AND_REMOVED_FROM_ALL_PLACES"
                text={
                    <Fragment>
                        <div>{i18n('TAG_IS_USED_ON_THE_OBJECTS', { tagToDeleteName: tagToDelete.name })}</div>
                        {tagDetails.map((tagDetail) => (
                            <div key={tagDetail.type}>
                                {Filters.dbObjectsToDelete(tagDetail.type)}: {tagDetail.count}
                            </div>
                        ))}
                    </Fragment>
                }
                onDispose={handleTagDeleteModalDispose}
                buttons={[
                    <Button id="eid-simple-alert-button-ok" type="primary" text="DELETE" onClick={handleTagDelete} />,
                ]}
            />
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
ComplexTableView.propTypes = {
    showGroupsTree: PropTypes.bool,
    showTagsColumn: PropTypes.bool,
    expandable: PropTypes.bool,
    tagsObjectType: PropTypes.number.isRequired,
    tagHelpers: PropTypes.shape({
        processId: PropTypes.number,
        moduleId: PropTypes.number,
    }),
};

//-----------------------------------------------------------------------------
ComplexTableView.defaultProps = {
    showGroupsTree: false,
    showTagsColumn: false,
    expandable: true,
    tagHelpers: {
        processId: null,
        moduleId: null,
    },
};

export default withBackend(ComplexTableView);
