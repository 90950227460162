import { useMemo } from 'react';
import classnames from 'classnames';
import { Table } from 'components/ui-library';

import i18n from 'Services/i18n';
import GridCells from 'Bricks/Grid/Cells';

import { PropTypes } from 'prop-types';

import 'Bricks/Grid/Cells.scss';

function SimpleTable(props) {
    const columns = useMemo(
        () =>
            props.options.columns.map((col, index) => ({
                id: col.field,
                header: index === 0 ? `${i18n(col.field)} (${props.options.data.length})` : col.field,
                width: props.columnsAutoSize ? undefined : col.width || 200,
                minWidth: col.width || 200,
                flexGrow: props.flexGrow,
                sortDisabled: true,
                visible: col.visible === undefined || col.visible,
            })),
        [props.options]
    );

    const data = useMemo(() => {
        const entities = props.options.entities || [];
        return props.options.data.map((row, index) => {
            return {
                id: index,
                colorType: 'normal',
                isCheckboxDisabled: props.options.isRowCheckboxDisabled?.(entities[index]),
                cells: row.map((cell, cellIndex) => {
                    const columnsData = props.options.columns[cellIndex];
                    const CellComponent = columnsData.cellComponent ? columnsData.cellComponent : GridCells.DefaultCell;

                    return {
                        id: columnsData.field,
                        customElement: (
                            <div
                                className={classnames('table-cell', 'ei-selectable-text', {
                                    'table-cell--centered': columnsData.centered,
                                    'table-cell--padding': columnsData.padding,
                                })}
                            >
                                <CellComponent
                                    entity={entities[index]}
                                    settings={props.options.settings}
                                    value={cell}
                                    onClick={columnsData.onClick}
                                />
                            </div>
                        ),
                    };
                }),
            };
        });
    }, [props.options]);

    const selectionChangeHandle = (isAllSelected, selectedItems) => {
        if (props.options.selectionChangeHandle) {
            const selectedItemsIds = isAllSelected
                ? [...Array(props.options.data.length).keys()].filter((item) => !selectedItems.includes(item))
                : selectedItems;
            props.options.selectionChangeHandle(
                selectedItemsIds.map((index) => props.options.data[index]),
                isAllSelected,
                selectedItems
            );
        }
    };

    return (
        <Table
            id={props.id}
            columnsAutoSize={props.columnsAutoSize}
            height={props.halfHeight ? '50vh' : null}
            className={`simple-table ${props.className}`}
            noHeaderTopBorder={true}
            columns={columns}
            selectAllDisplay={Boolean(props.options.selectAll)}
            selectionChangeHandle={props.options.disableSelection ? null : selectionChangeHandle}
            noDataTitle={i18n('NO_DATA')}
            data={data}
            selectedItems={props.selectedItems}
            selectAllItems={props.selectAllItems}
            isLoading={props.isLoading}
            rowCount={data.length}
        />
    );
}

SimpleTable.propTypes = {
    className: PropTypes.string,
    options: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        data: PropTypes.array.isRequired,
        entities: PropTypes.array,
        selectAll: PropTypes.bool,
        selectionChangeHandle: PropTypes.func,
    }).isRequired,
};

SimpleTable.defaultProps = {
    className: '',
};

export default SimpleTable;
