import { Fragment } from 'react';
import { FlexContainer } from 'components/ui-library';
import classnames from 'classnames';
import './index.scss';

function RemediationBody(props) {
    return (
        <Fragment>
            <table className={classnames('remediation-actions-table', { 'header-border': !props.disableHeaderBorder })}>
                {props.remediationHeader && (
                    <tbody className="remediation-header">
                        {props.remediationHeader.map((headerItem, index) => (
                            <tr key={`header-item-${index}`}>
                                <td>{headerItem.label}</td>
                                <td colSpan="2">{headerItem.content}</td>
                            </tr>
                        ))}
                    </tbody>
                )}
                {props.remediationContent?.map((contentItem, index) => (
                    <tbody key={`content-item-${index}`} className="remediation-content">
                        <tr>
                            <td>{contentItem.label}</td>
                            <td>{contentItem.content}</td>
                            <td>{/*just for visual consistency */}</td>
                        </tr>
                    </tbody>
                ))}
            </table>
            {props.remediationFooter && (
                <FlexContainer direction="column" className="remediation-footer">
                    {props.remediationFooter}
                </FlexContainer>
            )}
        </Fragment>
    );
}

export default RemediationBody;
