import { Breadcrumb } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

export default function BreadcrumbWrapper(props) {
    return (
        <Breadcrumb
            {...props}
            items={props.items.map((element) => ({
                ...element,
                content: i18n(element.content),
            }))}
        />
    );
}
