import { useEffect } from 'react';
import { Loading, Spacer, Link, Theme, FlexContainer, PlaceholderScreen } from 'components/ui-library';
import classnames from 'classnames';
import './index.scss';
export function LoadingPanel(props) {
    return props.show ? (
        <div
            className={classnames('full-page-panel', {
                'full-page-only-label': props.onlyLabel,
                'relative-page-panel': props.relative,
            })}
        >
            {!props.onlyLabel && <Loading show={true} size={100} />}
            {props.children && (
                <FlexContainer direction="column-reverse" align="center" className="full-page-label-container">
                    {props.children}
                </FlexContainer>
            )}
        </div>
    ) : null;
}

export function CancellableLoadingPanel(props) {
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            props.onCancel(true);
        };

        const removeBeforeUnload = () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };

        if (props.show) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
            removeBeforeUnload();
        }

        return removeBeforeUnload;
    }, [props.show]);

    return (
        <LoadingPanel show={props.show} onlyLabel={props.onlyLabel}>
            <Spacer type="p-2">
                <Theme.Provider
                    theme={{
                        Link: {
                            color: {
                                primary: 'rgb(0, 148, 162)',
                                hover: 'rgb(0, 148, 162)',
                            },
                        },
                    }}
                >
                    <Link text="CANCEL" onClick={() => props.onCancel(false)} />
                </Theme.Provider>
            </Spacer>
        </LoadingPanel>
    );
}

export function PlaceholderScreenPanel(props) {
    return props.show ? (
        <div className="full-page-panel">
            <PlaceholderScreen
                actionButton={props.actionButton}
                icon={props.icon}
                primaryMessage={props.primaryMessage}
                secondaryMessage={props.secondaryMessage}
            />
        </div>
    ) : null;
}
