// Common constants and utilities used by the application.

const ESET_HELP_URL = 'https://help.eset.com';
const ESET_HELP_NAVIGATE_PRODUCT = 'ei_navigate';
const ESET_HELP_DEPLOY_PRODUCT = 'ei_deploy';
const ESET_HELP_LANG_ID = '1033';

const VERSION = `${window.serverInfo.versionInfo.major}.${window.serverInfo.versionInfo.minor}`;

function createEsetHelpLink(topic, deployment = false, helpAnchor) {
    const section = topic !== undefined ? `&topic=${topic}` : '';
    const product = deployment ? ESET_HELP_DEPLOY_PRODUCT : ESET_HELP_NAVIGATE_PRODUCT;
    const anchor = helpAnchor !== undefined ? `#${helpAnchor}` : '';
    return `${ESET_HELP_URL}/getHelp?product=${product}&version=${VERSION}&lang=${ESET_HELP_LANG_ID}${section}${anchor}`;
}

function createEsetRulesGuideLink() {
    return `${ESET_HELP_URL}/getHelp/?product=ei_rules&version=${VERSION}&lang=${ESET_HELP_LANG_ID}`;
}

export { createEsetHelpLink, createEsetRulesGuideLink };
