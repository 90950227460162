import { IconActionFilter } from '@uilib/business-components/index';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';

import { Button, Checkbox, Dropdown, Modal, Section, FormGroup, Input, checkState } from 'components/ui-library';
import IconButton from 'Bricks/icon-button';

import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';

import Selector from 'Bricks/selector';

import './index.scss';

//-----------------------------------------------------------------------------
class LocalFiltersPresets extends Component {
    constructor(props) {
        super(props);

        this.presetsUri = 'users/presets/' + props.storage;

        this.userPresets = [];
        this.esetPresets = [];

        this.state = {
            includeTableSettings: checkState.unchecked,

            manageActionDisabled: true,

            presetWindowVisible: false,

            manageWindowVisible: false,

            deleteButtonDisabled: true,

            presetName: '',
            presetNameErrorText: '',
            presetNameDescription: '',
        };
    }

    initializeEsetPresets() {
        // Initialize default ESET presets.
        this.esetPresets = [];
        if (this.props.default) {
            for (const defaultPreset of this.props.default) {
                // To create ESET filters-set we have to start with initial one and then
                // apply on this filters-set ESET settings.
                const presetInitialState = lodash.cloneDeep(this.props.initialTableState);
                for (const initialPreset of presetInitialState.localFilters.common) {
                    if (defaultPreset.filters[initialPreset.id] !== undefined) {
                        // This filter has to be configured according to ESET settings.
                        if (defaultPreset.filters[initialPreset.id].visible !== undefined) {
                            initialPreset.visible = defaultPreset.filters[initialPreset.id].visible;
                        }
                        if (defaultPreset.filters[initialPreset.id].active !== undefined) {
                            initialPreset.active = defaultPreset.filters[initialPreset.id].active;
                        }
                        if (defaultPreset.filters[initialPreset.id].submit !== undefined) {
                            initialPreset.submit = defaultPreset.filters[initialPreset.id].submit;
                        }
                        if (defaultPreset.filters[initialPreset.id].operator !== undefined) {
                            initialPreset.operator = defaultPreset.filters[initialPreset.id].operator;
                        }
                        if (defaultPreset.filters[initialPreset.id].value !== undefined) {
                            initialPreset.value = defaultPreset.filters[initialPreset.id].value;
                        }
                    }
                }
                this.esetPresets.push({ name: defaultPreset.name, data: presetInitialState });
            }
        }
    }

    initializeUserPresets() {
        // Initialize stored user presets.
        BACKEND.get(this.presetsUri, this.props.componentUuid)
            .success((response) => {
                if (response) {
                    for (const userPreset of response.presets) {
                        this.userPresets.push({ name: userPreset.name, data: JSON.parse(userPreset.data) });
                    }
                    this.userPresets.sort((preset1st, preset2nd) => preset1st.name.localeCompare(preset2nd.name));
                }
            })
            .execute();
    }

    handleMenuAction = (event, action) => {
        if (typeof action === 'function') {
            const FILTERS_BODY = lodash.cloneDeep(this.props.localFilters.get(true));
            action(event, FILTERS_BODY.filterTree);
        }
    };

    handleSaveAction = (event, localFiltersBody) => {
        this.setState({
            presetWindowVisible: true,
            presetName: '',
            presetNameErrorText: '',
            presetNameDescription: '',
        });
    };

    validatePresetNameChange = (value, event) => {
        const NEW_PRESET_NAME_PATTERN = /^\S.*$/;
        const NEW_PRESET_NAME = value;
        let errorText = '';
        let description = '';

        const PRESET_MAX_LENGTH = window.serverInfo.dbColumnsLength.presets.name;

        if (NEW_PRESET_NAME.length === 0) {
            errorText = 'PRESET_NAME_IS_EMPTY';
        } else if (NEW_PRESET_NAME.length > PRESET_MAX_LENGTH) {
            errorText = i18n('PRESET_NAME_IS_TOO_LONG_MAX_N_CHARACTERS', { count: PRESET_MAX_LENGTH });
        } else if (!NEW_PRESET_NAME_PATTERN.test(NEW_PRESET_NAME)) {
            errorText = 'PRESET_NAME_IS_INVALID';
        } else {
            for (const preset of this.userPresets) {
                if (preset.name === NEW_PRESET_NAME) {
                    description = 'PRESET_WITH_THIS_NAME_EXISTS_PRESS_SAVE_TO_OVERWRITE';
                }
            }
        }

        this.setState({
            presetName: NEW_PRESET_NAME,
            presetNameErrorText: errorText,
            presetNameDescription: description,
        });

        return errorText === '';
    };

    handleSubmitPresetName = () => {
        if (this.validatePresetNameChange(this.state.presetName)) {
            this.handlePresetWindowClose();

            const SETTINGS = this.props.tableApi.getSettings();
            SETTINGS.name = this.state.presetName;
            SETTINGS.tags = this.props.activeTags;
            if (this.state.includeTableSettings !== checkState.checked) {
                SETTINGS.table = undefined;
            }

            const preset = this.userPresets.find((userPreset) => {
                return userPreset.name === this.state.presetName;
            });
            if (preset) {
                // Preset name already exists - update its data only.
                preset.data = SETTINGS;
            } else {
                this.userPresets.push({ name: this.state.presetName, data: SETTINGS });
                this.userPresets.sort((preset1st, preset2nd) => preset1st.name.localeCompare(preset2nd.name));
            }
            BACKEND.put(this.presetsUri, SETTINGS, this.props.componentUuid).execute();
        }
    };

    handleResetFiltersAction = (event, localFiltersBody) => {
        this.props.tableApi.initializeLocalFilters(this.props.initialTableState, true, true);
        this.props.onActiveTagsChange([]);
    };

    handleResetViewAction = (event, localFiltersBody) => {
        this.props.tableApi.initializeLocalFilters(this.props.initialTableState, true, true);
        this.props.tableApi.initializeTable(this.props.initialTableState, true);
        this.props.tableApi.resetSplitPanels();
        this.props.onActiveTagsChange([]);
    };

    handleManageAction = (event, localFiltersBody) => {
        this.manageWindowMenu = this.userPresets.map((preset) => {
            return {
                id: preset.name,
                label: preset.name,
                checked: preset.disabled,
            };
        });

        this.setState({
            manageWindowVisible: true,
        });
    };

    handlePresetLoad = (event, preset) => {
        if (preset.data.localFilters) {
            // Presets > v1.3 - table and local filterss.
            this.props.tableApi.initializeLocalFilters(preset.data, true);
            this.props.tableApi.initializeTable(preset.data);

            this.props.onActiveTagsChange(preset.data.tags || []);
        } else {
            // Presets v1.2.1 - just local filters.
            const SETTINGS = { localFilters: preset.data };
            this.props.tableApi.initializeLocalFilters(SETTINGS, true);
        }
    };

    handlePresetWindowClose = () => {
        this.setState({
            includeTableSettings: checkState.unchecked,
            presetWindowVisible: false,
        });
    };

    handleManageWindowChange = (event, selection) => {
        this.userPresets = this.userPresets.map((preset) => {
            const newPreset = { ...preset };
            if (selection[newPreset.name] !== undefined) {
                newPreset.disabled = selection[newPreset.name];
            }
            return newPreset;
        });

        for (let i = this.userPresets.length - 1; i >= 0; --i) {
            // Start from the end to properly delete elements.
            if (this.userPresets[i].disabled) {
                BACKEND.delete(this.presetsUri, this.userPresets[i].data, this.props.componentUuid).execute();
                this.userPresets.splice(i, 1);
            }
        }

        const IS_DELETE_BUTTON_DISABLED = this.userPresets.filter((preset) => preset.disabled).length === 0;
        this.setState({
            deleteButtonDisabled: IS_DELETE_BUTTON_DISABLED,
        });
    };

    handleManageWindowClose = (event) => {
        this.setState({
            manageWindowVisible: false,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.initialTableState !== this.props.initialTableState) {
            this.initializeEsetPresets();
            this.initializeUserPresets();
        }
    }

    handleTableSettingsChange = (checked, value, event) => {
        this.setState({
            includeTableSettings: checked,
        });
    };

    render() {
        if (!this.props.visible) {
            return null; // Display nothing.
        }

        const menuActions = [
            { name: 'SAVE_FILTERS', clbk: this.handleSaveAction, disabled: false },
            { name: 'RESET_FILTERS', clbk: this.handleResetFiltersAction, disabled: false },
            { name: 'RESET_VIEW', clbk: this.handleResetViewAction, disabled: false },
            { name: 'MANAGE', clbk: this.handleManageAction, disabled: this.userPresets.length === 0 },
        ].concat(this.props.actions || []);

        const presetsMenuContent = [];

        const menuActionsGroup = {
            header: 'FILTERS_PRESETS',
            items: menuActions.map((menuAction, index) => {
                return {
                    id: `dropdown-presets-action-${index}`,
                    title: menuAction.name,
                    icon: menuAction.icon,
                    callback: (event) => this.handleMenuAction(event, menuAction.clbk),
                    disabled: typeof menuAction.disabled === 'function' ? menuAction.disabled() : menuAction.disabled,
                };
            }),
        };
        presetsMenuContent.push(menuActionsGroup);

        if (this.userPresets.length > 0) {
            const userPresetsGroup = {
                header: 'SAVED_PRESETS',
                items: this.userPresets.map((userPreset, index) => {
                    return {
                        id: `dropdown-presets-user-${index}-${userPreset.name}`,
                        title: userPreset.name,
                        callback: (event) => this.handlePresetLoad(event, userPreset),
                    };
                }),
            };
            presetsMenuContent.push(userPresetsGroup);
        }

        if (this.esetPresets.length > 0) {
            const esetPresetsGroup = {
                header: 'DEFAULT_PRESETS',
                items: this.esetPresets.map((esetPreset, index) => {
                    return {
                        id: `dropdown-presets-eset-${index}-${esetPreset.name}`,
                        title: esetPreset.name,
                        callback: (event) => this.handlePresetLoad(event, esetPreset),
                    };
                }),
            };
            presetsMenuContent.push(esetPresetsGroup);
        }

        return (
            <Fragment>
                <Dropdown
                    id={'local-filters-presets'}
                    reference={
                        <IconButton
                            white
                            id="table-presets-button"
                            testDataLabel="eid-switch-side-panel"
                            className="local-filters-presets-button"
                            title="PRESETS"
                            icon={<IconActionFilter fill="currentcolor" />}
                        />
                    }
                    placement="bottom"
                    content={presetsMenuContent}
                />
                <Modal
                    show={this.state.presetWindowVisible}
                    title="SAVE_NEW_PRESET"
                    onDispose={this.handlePresetWindowClose}
                    buttons={[
                        <Button
                            id="eid-save-preset-button-save"
                            type="primary"
                            text="SAVE"
                            onClick={this.handleSubmitPresetName}
                        />,
                        <Button
                            id="eid-save-preset-button-cancel"
                            type="secondary"
                            text="CANCEL"
                            onClick={this.handlePresetWindowClose}
                        />,
                    ]}
                >
                    <Section limitContentWidth={false}>
                        <FormGroup
                            id="preset-name"
                            labelText="ENTER_NAME_OF_NEW_PRESET"
                            description={this.state.presetNameDescription}
                            required={true}
                            hasError={this.state.presetNameErrorText}
                            validationText={this.state.presetNameErrorText}
                            content={
                                <Input
                                    type="text"
                                    autoFocus
                                    value={this.state.presetName}
                                    onEnterPress={this.handleSubmitPresetName}
                                    onChange={(value, event) => this.validatePresetNameChange(value, event)}
                                />
                            }
                        />
                    </Section>
                    <Section limitContentWidth={false}>
                        <FormGroup
                            id={`eid-selector-checkbox-include-columns`}
                            content={
                                <Checkbox
                                    text={i18n('INCLUDE_VISIBLE_COLUMNS_AND_SORTING')}
                                    checked={this.state.includeTableSettings}
                                    onChange={this.handleTableSettingsChange}
                                    testDataLabel="eid-checkbox"
                                />
                            }
                        />
                    </Section>
                </Modal>
                <Selector
                    isOpen={this.state.manageWindowVisible}
                    title="MANAGE_PRESETS"
                    options={this.manageWindowMenu}
                    onChange={this.handleManageWindowChange}
                    onClose={this.handleManageWindowClose}
                    selectButtonText="DELETE"
                />
            </Fragment>
        );
    }
}

//-----------------------------------------------------------------------------
LocalFiltersPresets.propTypes = {
    visible: PropTypes.bool,
};

//-----------------------------------------------------------------------------
LocalFiltersPresets.defaultProps = {
    visible: false,
};

export default withBackend(LocalFiltersPresets);
