import { IconActionDelete } from '@uilib/business-components/index';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, Spacer, Loading, Tooltip } from 'components/ui-library';
import { BACKEND, withBackend } from 'Services/backend';
import { createGroupsTreeDictionary } from 'Bricks/Helpers';
import IconButton from 'Bricks/icon-button';
import AccessGroupModal from 'Bricks/access-group-modal';

function AccessGroupFilter(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [groups, setGroups] = useState(null);
    const [showAccessGroupModal, setShowAccessGroupModal] = useState(false);
    const [groupsTreeDictionary, setGroupsTreeDictionary] = useState({});

    function closeAccessGroupModal() {
        setShowAccessGroupModal(false);
    }

    function openAccessGroupModal() {
        setShowAccessGroupModal(true);
    }

    useEffect(() => {
        setIsLoading(true);

        BACKEND.post(
            'groupsTree/0',
            { accessRight: window.serverInfo.constants.accessRight.AR_Groups_Computers_View },
            props.componentUuid
        )
            .success((response) => {
                setGroupsTreeDictionary(createGroupsTreeDictionary(response));
                setGroups([response]);
            })
            .always(() => {
                setIsLoading(false);
            })
            .execute();
    }, []);

    return (
        <Fragment>
            <Spacer type="pl-2 pr-1">
                {isLoading ? (
                    <Loading show size="30" />
                ) : (
                    <Link
                        onClick={openAccessGroupModal}
                        display="inline"
                        text={groupsTreeDictionary[props.value]?.name || 'SELECT'}
                    />
                )}
            </Spacer>
            <Tooltip tooltipContent="CLEAR">
                <IconButton
                    transparent
                    icon={<IconActionDelete fill="currentcolor" />}
                    onClick={() => props.onChange()}
                />
            </Tooltip>
            <AccessGroupModal
                groups={groups}
                preselectedGroupId={props.value}
                show={showAccessGroupModal}
                close={closeAccessGroupModal}
                onChange={props.onChange}
            />
        </Fragment>
    );
}

export default withBackend(AccessGroupFilter);
