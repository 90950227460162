import { Fragment, useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react';
import { FormGroup, Select, Spacer, Tooltip, Checkbox, checkState, Text } from 'components/ui-library';
import Computers from './computers';
import i18n from 'Services/i18n';

function LearningModeSettings(props, ref) {
    const [menuPortal, setMenuPortal] = useState(null);
    const computersRef = useRef();

    useImperativeHandle(ref, () => ({
        getSelectionRequestBody: () => computersRef.current.gridApi.getSelectionRequestBody(),
    }));

    useEffect(() => {
        setMenuPortal(document.getElementById(props.modalWindowId));
    }, []);

    return (
        <Fragment>
            <Text>ENABLE_LEARNING_MODE_SELECT_COMPUTERS</Text>
            <Spacer type="py-2">
                <FormGroup
                    required={true}
                    showLabel={false}
                    hasError={!props.validStatus}
                    validationText="SELECT_AT_LEAST_ONE_COMPUTER"
                    content={
                        <Computers ref={computersRef} onComputersSelectionChange={props.onComputersSelectionChange} />
                    }
                />
            </Spacer>
            <Tooltip tooltipContent={<Text>ENABLE_AUTOEXCLUSIONS_LEARNING_MODE_TOOLTIP</Text>}>
                <div>
                    <Checkbox
                        checked={props.autoEnableExclusionsSelection}
                        onChange={(val) => props.setAutoEnableExclusionsSelectionChange(val)}
                        testDataLabel="eid-checkbox"
                        text={i18n('ENABLE_AUTOEXCLUSIONS_LEARNING_MODE')}
                    />
                </div>
            </Tooltip>
            {props.learningPeriods.length > 1 && (
                <Spacer type="py-2">
                    <FormGroup
                        labelText="LEARNING_MODE_PERIOD"
                        content={
                            <Select
                                isSearchable={false}
                                isClearable={false}
                                menuPortalTarget={menuPortal}
                                options={props.learningPeriods}
                                value={props.learningPeriod}
                                onChange={props.onPeriodChange}
                            />
                        }
                    />
                </Spacer>
            )}
        </Fragment>
    );
}

LearningModeSettings = forwardRef(LearningModeSettings);

export default LearningModeSettings;
