import {
    IconStatusEnabled,
    IconSelectionRadio,
    IconStatusOk,
    IconStatusError,
    IconLetterI,
    IconStatusWarning,
    IconTimeClock,
    IconStatusSafe,
    IconFileObsolete,
    IconArrowExternal,
    IconHardwareComputer,
    IconTerminalExecutable,
    IconNumericOne,
    IconNumericTwo,
    IconNumericThree,
    IconFileClean,
    IconNetworkAd,
    IconActionEdit,
    IconObjectKey,
    IconUserUser,
    IconStatusBlocked,
    IconCompanyWindows,
    IconCompanyApple,
    IconTerminalScripts,
    IconMediaPlay,
    IconMediaAlarm,
} from '@uilib/business-components/index';
import React, { Component, Fragment, useState, useEffect } from 'react';
import classnames from 'classnames';
import {
    Progressbar,
    Spacer,
    Tooltip,
    Highlighter,
    FlexContainer,
    Tag,
    Text,
    Checkbox,
    checkState,
    Link,
} from 'components/ui-library';

import Filters from 'Services/Filters';
import i18n from 'Services/i18n';

import HighlightBlank from 'Bricks/highlight-blank';
import { escapeRegExp, isValidDbId, NotificationString } from 'Bricks/Helpers';
import { SimpleHash } from 'Bricks/hash';
import { RuleActions } from 'Bricks/rule-actions';
import { AlarmActions } from 'Bricks/remediation-common';
import TagsRow from 'Bricks/tags-row';
import { getMitreIdUrl } from 'Bricks/Helpers';

import ProcessIcon from 'Bricks/process-icon';
import { getGroupIcon } from 'Bricks/groups-tree';
import { Reputation, Popularity } from 'Bricks/livegrid-icons';
import EmptyIcon from 'Bricks/empty-icon';
import colors from 'eset-ui-colors';
import './Cells.scss';

function QuickTooltip(props) {
    const [tooltipContent, setTooltipContent] = useState(props.tooltipContent);
    useEffect(() => {
        /**
         * Fix case when props.tooltipContent is React component object
         * Tooltip doesn't get rendered again because for the Tooltip it's always the same React component
         */
        setTooltipContent(props.tooltipContent);
    }, [props.tooltipContent]);

    return props.disabled ? (
        props.children
    ) : (
        <Tooltip tooltipContent={<span className="ei-tooltip">{tooltipContent}</span>} children={props.children} />
    );
}

class CustomCell extends Component {
    constructor(props) {
        super(props);

        const value = this.formatHighlightedValue(this.getValue());

        this.state = {
            actionFieldActive: false,
            tooltipText: this.getTooltip(value),
            value,
        };
    }

    componentDidMount() {
        if (this.props.refreshInterval) {
            this.refreshInterval = setInterval(() => {
                const value = this.getValue();

                this.setState({ value: value });

                if (this.props.dynamicTooltip) {
                    this.props.onShowTooltip().then(
                        (response) => {
                            this.setState({
                                tooltipText: response,
                            });
                        },
                        (error) => console.log(error)
                    );
                } else {
                    this.setState({
                        tooltipText: this.getTooltip(value),
                    });
                }
            }, this.props.refreshInterval);
        }
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
    }

    handleActionFieldEnter = (event) => {
        this.setState({ actionFieldActive: true });
    };

    handleActionFieldLeave = (event) => {
        this.setState({ actionFieldActive: false });
    };

    handleRowClick = (event) => {
        this.props.onClick(event, this.state.actionFieldActive);
    };

    handleRowContextMenu = (event) => {
        this.props.onContextMenu(event, this.state.actionFieldActive);
    };

    handleMouseEnter = (event) => {
        if (this.props.dynamicTooltip) {
            this.setState({
                tooltipText: `${i18n('LOADING')}...`,
            });
            this.props.onShowTooltip(event).then(
                (response) => {
                    this.setState({
                        tooltipText: response,
                    });
                },
                (error) => console.log(error)
            );
        }
    };

    getValue = () => {
        return typeof this.props.filter === 'function'
            ? this.props.filter(this.props.entity[this.props.field], this.props.entity, this.props.dateType)
            : this.props.entity[this.props.field];
    };

    formatHighlightedValue(value) {
        if (value && this.props.highlight) {
            // g flag is needed to force match to return every match, not only first one, i flag means case invariant
            const re = new RegExp(escapeRegExp(this.props.highlight), 'ig');

            return <Highlighter searchRegex={re} text={value} />;
        }

        return value;
    }

    getTooltip = (value) => {
        return this.props.tooltipFilter ? this.props.tooltipFilter(value) : value;
    };

    render() {
        const CellComponent = typeof this.props.cellComponent === 'function' ? this.props.cellComponent : DefaultCell;
        const tooltipDisabled =
            (!this.props.tooltip && !this.props.dynamicTooltip) ||
            this.state.tooltipText === '' ||
            this.state.tooltipText === null ||
            this.state.tooltipText === undefined;

        return (
            <QuickTooltip disabled={tooltipDisabled} tooltipContent={i18n(this.state.tooltipText)}>
                <div
                    id={this.props.id}
                    className={classnames('clickable', 'table-cell', {
                        'table-cell--centered': this.props.centered,
                        'table-cell--padding': this.props.padding,
                    })}
                    onMouseDown={this.props.onMouseDown}
                    onClick={this.handleRowClick}
                    onContextMenu={this.handleRowContextMenu}
                    onMouseEnter={this.handleMouseEnter}
                >
                    <CellComponent
                        onActionFieldEnter={this.handleActionFieldEnter}
                        onActionFieldLeave={this.handleActionFieldLeave}
                        monospace={this.props.monospace}
                        entity={this.props.entity}
                        value={this.state.value}
                    />
                </div>
            </QuickTooltip>
        );
    }
}

//-----------------------------------------------------------------------------
// EI specific default cell template.
//-----------------------------------------------------------------------------
function DefaultCell(props) {
    return (
        <Fragment>
            {props.icon !== undefined &&
                React.cloneElement(props.icon, {
                    fill: colors[props.fillName] || 'currentcolor',
                    className: 'default-cell-icon',
                })}
            <HighlightBlank alignCell value={props.value} monospace={props.monospace} />
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
// COMMON
//-----------------------------------------------------------------------------
function FieldEnabledCell(props) {
    return (
        <DefaultCell
            entity={props.entity}
            icon={props.entity.enabled ? <IconStatusEnabled /> : <IconSelectionRadio />}
            fillName="iconColor"
            value={props.value}
        />
    );
}

function FieldValidCell(props) {
    return (
        <DefaultCell
            entity={props.entity}
            icon={props.entity.valid ? <IconStatusOk /> : <IconStatusError />}
            fillName={props.entity.valid ? 'success' : 'error'}
            value={props.value}
        />
    );
}

function FieldCleanedCell(props) {
    return (
        <DefaultCell
            entity={props.entity}
            icon={props.entity.cleaned ? <IconStatusEnabled /> : <IconSelectionRadio />}
            fillName="iconColor"
            value={props.value}
        />
    );
}

//-----------------------------------------------------------------------------

const SEVERITY_ICON_FILL_BY_VALUE = new Map([
    [1, [<IconLetterI fill="currentcolor" />, 'info']],
    [2, [<IconStatusWarning fill="currentcolor" />, 'warningBig']],
    [3, [<IconStatusError fill="currentcolor" />, 'error']],
]);

function SeverityCell(props) {
    const fillName = SEVERITY_ICON_FILL_BY_VALUE.get(props.value)[1];

    return SEVERITY_ICON_FILL_BY_VALUE.has(props.value)
        ? React.cloneElement(SEVERITY_ICON_FILL_BY_VALUE.get(props.value)[0], {
              ...(fillName && { fill: colors[fillName] }),
          })
        : null;
}

const STATUS_ICON_FILL_BY_VALUE = new Map([
    [-1, [<IconSelectionRadio fill="currentcolor" />, 'iconColor']],
    [0, [<IconStatusOk fill="currentcolor" />, 'success']],
    [1, [<IconLetterI fill="currentcolor" />, 'info']],
    [2, [<IconStatusWarning fill="currentcolor" />, 'warningBig']],
    [3, [<IconStatusError fill="currentcolor" />, 'error']],
]);

function StatusCell(props) {
    if (STATUS_ICON_FILL_BY_VALUE.has(props.value)) {
        const fillName = STATUS_ICON_FILL_BY_VALUE.get(props.value)[1];
        return React.cloneElement(STATUS_ICON_FILL_BY_VALUE.get(props.value)[0], {
            ...(fillName && { fill: colors[fillName] }),
        });
    } else {
        return null;
    }
}

const AUDIT_ICON_FILL_DATA_BY_VALUE = new Map([
    [
        window.serverInfo.constants.actionHistory.status.success,
        { iconFill: [<IconStatusOk fill="currentcolor" />, 'success'], value: 'SUCCESS' },
    ],
    [
        window.serverInfo.constants.actionHistory.status.forbidden,
        { iconFill: [<IconStatusWarning fill="currentcolor" />, 'warningBig'], value: 'FORBIDDEN' },
    ],
    [
        window.serverInfo.constants.actionHistory.status.failure,
        { iconFill: [<IconStatusError fill="currentcolor" />, 'error'], value: 'FAILURE' },
    ],
]);

function ActionHistoryStatusCell(props) {
    return AUDIT_ICON_FILL_DATA_BY_VALUE.has(props.value) ? (
        <DefaultCell
            icon={AUDIT_ICON_FILL_DATA_BY_VALUE.get(props.value).iconFill[0]}
            fillName={AUDIT_ICON_FILL_DATA_BY_VALUE.get(props.value).iconFill[1]}
            value={AUDIT_ICON_FILL_DATA_BY_VALUE.get(props.value).value}
        />
    ) : null;
}

function UnresolvedCell(props) {
    return (
        <span
            className={
                props.value > 0 ? `bubble ${Filters.bubbleClassByStatusOrSeverity.get(props.entity.status)}` : ''
            }
        >
            {i18n(props.value)}
        </span>
    );
}

function ReputationCell(props) {
    return props.value === null ? (
        <HighlightBlank className="ei-bleak-text" value={props.value} />
    ) : (
        <Reputation reputation={props.value} />
    );
}

function PopularityCell(props) {
    return props.value === null ? (
        <HighlightBlank className="ei-bleak-text" value={props.value} />
    ) : (
        <Popularity popularity={props.value} />
    );
}

function DtdAnalysisStatusCell(props) {
    if (props.value === null || props.value < 1 || props.value > 4) {
        return null;
    }

    return (
        <Tag
            color={Filters.DTD_ANALYSIS_STATUS_TAGS.get(props.value)}
            icon={Filters.DTD_ANALYSIS_STATUS_ICONS.get(props.value)}
        >
            {Filters.dynamicThreatDetectionStatusName(props.value)}
        </Tag>
    );
}

function DtdAnalysisStatusBar(props) {
    return null;
}

const DTD_STATE_ICON_BY_VALUE = new Map([
    [0, <IconTimeClock fill="currentcolor" />],
    [1, <IconTimeClock fill="currentcolor" />],
    [2, <IconStatusSafe fill="currentcolor" />],
    [3, <IconFileObsolete fill="currentcolor" />],
    [4, <IconTimeClock fill="currentcolor" />],
]);
function DtdAnalysisStateCell(props) {
    if (typeof props.value !== 'number' || props.value > 4 || props.value < 0) {
        return null;
    }
    return (
        <FlexContainer>
            {React.cloneElement(DTD_STATE_ICON_BY_VALUE.get(props.value), {
                fill: colors.iconColor,
            })}
            {Filters.dynamicThreatDetectionState(props.value)}
        </FlexContainer>
    );
}

function DtdAnalysisStateBubbleCell(props) {
    if (typeof props.value !== 'number' || props.value > 4 || props.value < 0) {
        return null;
    }
    return (
        <Tag icon={DTD_STATE_ICON_BY_VALUE.get(props.value)}>{Filters.dynamicThreatDetectionState(props.value)}</Tag>
    );
}

const EDTD_SUPPORTED_LANGUAGES = [
    'ar-EG',
    'cs-CZ',
    'de-DE',
    'el-GR',
    'es-CL',
    'es-ES',
    'fr-CA',
    'fr-FR',
    'hr-HR',
    'hu-HU',
    'id-ID',
    'it-IT',
    'ja-JP',
    'ko-KR',
    'pl-PL',
    'pt-BR',
    'ru-RU',
    'sk-SK',
    'tr-TR',
    'uk-UA',
    'zh-CN',
    'zh-TW',
    'en-US',
];

const EDTD_LANGUAGE_MAP = new Map([
    ['ar', 'ar-EG'],
    ['cs', 'cs-CZ'],
    ['de', 'de-DE'],
    ['el', 'el-GR'],
    ['es', 'es-ES'],
    ['fr', 'fr-FR'],
    ['hr', 'hr-HR'],
    ['hu', 'hu-HU'],
    ['id', 'id-ID'],
    ['it', 'it-IT'],
    ['ja', 'ja-JP'],
    ['ko', 'ko-KR'],
    ['pl', 'pl-PL'],
    ['pt', 'pt-BR'],
    ['ru', 'ru-RU'],
    ['sk', 'sk-SK'],
    ['tr', 'tr-TR'],
    ['uk', 'uk-UA'],
    ['zh', 'zh-CN'],
    ['en', 'en-US'],
]);

function selectReportLanguage(navigatorLanguage) {
    if (EDTD_SUPPORTED_LANGUAGES.includes(navigatorLanguage)) {
        return navigatorLanguage;
    }
    if (EDTD_LANGUAGE_MAP.has(navigatorLanguage)) {
        return EDTD_LANGUAGE_MAP.get(navigatorLanguage);
    }
    return 'en-US';
}

function DtdAnalysisBehaviourCell(props) {
    if (props.value === null) {
        return null;
    }
    const language = selectReportLanguage(navigator.language);
    return (
        <Link
            onClick={() =>
                window.open(
                    `https://d.edtd.eset.com/details?hash=${props.entity.cloudDtdSha1}&key=${props.entity.cloudDtdKey}&lang=${language}`
                )
            }
            iconRight={<IconArrowExternal fill="currentcolor" />}
        >
            {i18n('BEHAVIOUR_LINK')}
        </Link>
    );
}

function GroupsCountCell(props) {
    return props.value === undefined ? null : (
        <div className={Filters.getActionBubbleClass(props.entity.severity)}>
            <DefaultCell entity={props.entity} value={props.value} />
        </div>
    );
}

function AugurScoreCell(props) {
    let val = props.value;
    if (val !== null) {
        val = val + '%';
    }

    return <DefaultCell entity={props.entity} value={val} />;
}

//-----------------------------------------------------------------------------
// DETECTIONS
//-----------------------------------------------------------------------------
function AlarmNameActionCell(props) {
    // Alarm entity uses userType field with description stored in props.value field.
    // Audit entity contains above fields in the additionalData field (which is also stored in props.value field).
    const [ALARM_TYPE, ALARM_INFO] =
        props.entity.additionalData !== undefined
            ? props.entity.additionalData.split(';')
            : [props.entity.userType || props.entity['Alarms_userType'], props.value];

    return (
        <Fragment>
            <div
                className={classnames(Filters.getActionBubbleClass(props.entity.severity), 'bubble-overflow-visible', {
                    clickable: !!props.onClick,
                })}
                {...(!props.onClick && {
                    onMouseEnter: props.onActionFieldEnter,
                    onMouseLeave: props.onActionFieldLeave,
                })}
                onClick={(event) => props.onClick?.(event, props.entity.id)}
            >
                <DefaultCell
                    entity={props.entity}
                    icon={<IconStatusError />}
                    value={props.entity.isGroup ? ALARM_INFO : Filters.alarmUserType(ALARM_TYPE)}
                />
            </div>
            {!props.entity.isGroup && (
                <Spacer type="ml-2" className="ei-cell-overflow">
                    {ALARM_INFO || props.entity['Alarms_alarmInfo']}
                </Spacer>
            )}
        </Fragment>
    );
}

function AlarmsActionsTakenCell(props) {
    const isAlarmRuleActivated = props.entity.type === window.serverInfo.constants.alarmEventType.RULE_ACTIVATED;
    return isAlarmRuleActivated ? (
        <RuleActions fullName cell actionsValue={props.value} />
    ) : (
        <AlarmActions cell value={props.value} />
    );
}

// Looks like Computer/Process/Module cells on detections page were incorrectly used in Angular version!
// First the backend has to be modified to sent proper statues to the frontend.
// function AlarmComputerCell(props) {
//         return (
//             <div className={Filters.getActionBubbleClass(props.entity.computerStatus)}>
//                 <DefaultCell icon="eb.object.computer" fillName="iconColor" value={props.value} />
//             </div>
//         );
// }

function AlarmProcessCell(props) {
    return (
        <>
            {props.value !== undefined && props.value !== null && props.value !== '' && (
                <ProcessIcon integrityLevel={props.entity.processIntegrityLevel} />
            )}
            <HighlightBlank alignCell value={props.value} />
        </>
    );
}

// function AlarmModuleCell(props) {
//         return (
//             <div className={Filters.getActionBubbleClass(props.entity.moduleStatus)}>
//                 <DefaultCell icon="eb.object.executable" fillName="iconColor" value={props.value} />
//             </div>
//         );
// }

const PRIORITY_ICON_BY_VALUE = new Map([
    [1, <IconNumericOne fill="currentcolor" />],
    [2, <IconNumericTwo fill="currentcolor" />],
    [3, <IconNumericThree fill="currentcolor" />],
]);

function PriorityCell(props) {
    return PRIORITY_ICON_BY_VALUE.has(props.value)
        ? React.cloneElement(PRIORITY_ICON_BY_VALUE.get(props.value), {
              fill: colors.iconColor,
          })
        : null;
}

function ResolvedCell(props) {
    return props.value ? <IconStatusSafe fill={colors.iconColor} /> : null;
}

function ResolvedOccurrencesCell(props) {
    if (props.entity.isPrimary) {
        if (props.entity.resolvedOccurrences / props.entity.occurrenceCount === 1) {
            return <ResolvedCell value={true} />;
        }
        return `${props.entity.resolvedOccurrences}/${props.entity.occurrenceCount}`;
    }
    return <ResolvedCell {...props} />;
}

function OccurrencesCell(props) {
    if (props.entity.isPrimary) {
        return (
            <div className={classnames(Filters.getActionBubbleClass(props.entity.status), 'clickable')}>
                <DefaultCell value={props.entity.occurrenceCount} />
            </div>
        );
    }
    return null;
}

// //-----------------------------------------------------------------------------
// // EXCLUSIONS
// //-----------------------------------------------------------------------------
function ExclusionCriteriaCellTemplate(props) {
    if (props.entity.isAdvanced) {
        return <DefaultCell entity={props.entity} value="ADVANCED" />;
    }

    if (props.entity.node) {
        return null;
    }

    const exclusionCriteriaFieldNames = [
        ['fileNames', <IconFileClean fill="currentcolor" />],
        ['filePaths', <IconNetworkAd fill="currentcolor" />],
        ['cmdLines', <IconTerminalExecutable fill="currentcolor" />],
        ['signers', <IconActionEdit fill="currentcolor" />],
        ['hashes', <IconObjectKey fill="currentcolor" />],
        ['users', <IconUserUser fill="currentcolor" />],
    ];

    return (
        <Fragment>
            {exclusionCriteriaFieldNames
                .filter(([fieldName]) => props.entity[fieldName] >= 1)
                .map(([fieldName, icon]) => (
                    <span className="centered" key={fieldName}>
                        {props.entity[fieldName]}
                        {icon}
                    </span>
                ))}
        </Fragment>
    );
}

//-----------------------------------------------------------------------------
// EXECUTABLES
//-----------------------------------------------------------------------------
function ExecutableNameActionCell(props) {
    return (
        <div
            className={classnames(Filters.getActionBubbleClass(props.entity.status), { clickable: !!props.onClick })}
            {...(!props.onClick && {
                onMouseEnter: props.onActionFieldEnter,
                onMouseLeave: props.onActionFieldLeave,
            })}
            onClick={(event) => props.onClick?.(event, props.entity.id)}
        >
            <DefaultCell entity={props.entity} icon={<IconTerminalExecutable />} value={props.value} />
        </div>
    );
}

function OriginExecutableNameCell(props) {
    const { entity, value, onClick } = props;

    return (
        <div className={`bubble normal action-normal clickable`} onClick={(event) => onClick(event, entity.moduleId)}>
            <DefaultCell entity={entity} icon={<IconTerminalExecutable />} value={value} />
        </div>
    );
}

function OriginUrlCell(props) {
    return (
        <div className="origin-url-cell">
            <IconTerminalExecutable className="origin-url-cell__icon" fill={colors.iconColor} />
            <div>{props.value}</div>
        </div>
    );
}

function ExecutableSafeCell(props) {
    return props.value ? <IconStatusSafe fill={colors.success} /> : null;
}

function ExecutableBlockedCell(props) {
    return props.value ? <IconStatusBlocked fill={colors.iconColor} /> : null;
}

// //-----------------------------------------------------------------------------
// // MACHINES
// //-----------------------------------------------------------------------------
function ComputerNameActionCell(props) {
    return (
        <div
            className={classnames(Filters.getActionBubbleClass(props.entity.status), { clickable: !!props.onClick })}
            {...(!props.onClick && {
                onMouseEnter: props.onActionFieldEnter,
                onMouseLeave: props.onActionFieldLeave,
            })}
            onClick={(event) => props.onClick?.(event, props.entity.id)}
        >
            <DefaultCell entity={props.entity} icon={<IconHardwareComputer />} value={props.value} />
        </div>
    );
}

function TargetCell(props) {
    const { entity, value, onClick } = props;

    if (props.entity.isTreeGroup) {
        return <DefaultCell entity={entity} icon={getGroupIcon(entity.locationType)} value={value} />;
    }
    return (
        <div
            className={classnames(Filters.getActionBubbleClass(entity.status), 'clickable')}
            onClick={(event) => onClick(event, entity.id)}
        >
            <DefaultCell entity={entity} icon={<IconHardwareComputer />} value={value} />
        </div>
    );
}

function ComputerOsVersionCell(props) {
    function systemIcon(osVersion) {
        if (typeof osVersion === 'string' && osVersion.startsWith('w')) {
            return <IconCompanyWindows fill="currentcolor" />;
        } else if (typeof osVersion === 'string' && osVersion.startsWith('m')) {
            return <IconCompanyApple fill="currentcolor" />;
        } else {
            return <EmptyIcon />;
        }
    }

    return (
        <DefaultCell
            entity={props.entity}
            icon={systemIcon(props.entity.osVersion)}
            value={props.value}
            fillName="iconColor"
        />
    );
}

// //-----------------------------------------------------------------------------
// // SCRIPTS / EXECUTION HISTORY
// //-----------------------------------------------------------------------------
function ModuleOriginCell(props) {
    return (
        <Fragment>
            {props.hasUrlOrigin && (
                <Tag className={classnames('bubble', 'table-cell-tag', 'warning')}>
                    <Text color="white">DOWNLOADED</Text>
                </Tag>
            )}
            {props.hasEmailOrigin && (
                <Tag className={classnames('bubble', 'table-cell-tag', 'warning')}>
                    <Text color="white">EMAIL</Text>
                </Tag>
            )}
            {props.hasRdpclipOrigin && (
                <Tag className={classnames('bubble', 'table-cell-tag', 'warning')}>
                    <Text color="white">RDPCLIP</Text>
                </Tag>
            )}
        </Fragment>
    );
}

function EventArgumentCell(props) {
    const moduleOrigins =
        props.entity.arguments == null
            ? {}
            : props.entity.arguments.reduce((prev, curr) => {
                  return {
                      hasUrlOrigin: prev.hasUrlOrigin || curr.hasUrlOrigin,
                      hasEmailOrigin: prev.hasEmailOrigin || curr.hasEmailOrigin,
                      hasRdpclipOrigin: prev.hasRdpclipOrigin || curr.hasRdpclipOrigin,
                  };
              }, {});

    if (
        !(moduleOrigins.hasUrlOrigin || moduleOrigins.hasEmailOrigin || moduleOrigins.hasRdpclipOrigin || props.value)
    ) {
        return null;
    }

    return (
        <FlexContainer className="table-cell table-cell-section">
            <DefaultCell {...props} />
            <ModuleOriginCell {...moduleOrigins} />
        </FlexContainer>
    );
}

function ProcessActionCell(props) {
    return (
        <div
            className={Filters.getActionBubbleClass(props.entity.status)}
            onMouseEnter={props.onActionFieldEnter}
            onMouseLeave={props.onActionFieldLeave}
        >
            <ProcessIcon integrityLevel={props.entity.integrityLevel} />
            <HighlightBlank alignCell value={props.value} />
        </div>
    );
}

function ScriptNameActionCell(props) {
    return (
        <div
            className={Filters.getActionBubbleClass(props.entity.status)}
            onMouseEnter={props.onActionFieldEnter}
            onMouseLeave={props.onActionFieldLeave}
        >
            <DefaultCell entity={props.entity} icon={<IconTerminalScripts />} value={props.value} />
        </div>
    );
}

function ScriptSafeCell(props) {
    return props.value ? <IconStatusSafe fill={colors.success} /> : null;
}

//-----------------------------------------------------------------------------
// PROCESS LOADED MODULES (DLLS)
//-----------------------------------------------------------------------------
function ProcessLoadedModuleCell(props) {
    return (
        <div
            className="bubble normal action-normal"
            onMouseEnter={props.onActionFieldEnter}
            onMouseLeave={props.onActionFieldLeave}
        >
            <DefaultCell entity={props.entity} icon={<IconTerminalExecutable />} value={props.value} />
        </div>
    );
}

//-----------------------------------------------------------------------------
// RULES
//-----------------------------------------------------------------------------
function RuleNameActionCell(props) {
    let actionIcon = undefined;
    if (props.entity.hasActiveActions !== undefined) {
        actionIcon = props.entity.hasActiveActions ? <IconMediaPlay fill="currentcolor" /> : <EmptyIcon />;
    }
    return (
        <span
            className={Filters.getActionBubbleClass(props.entity.severity)}
            onMouseEnter={props.onActionFieldEnter}
            onMouseLeave={props.onActionFieldLeave}
        >
            <DefaultCell entity={props.entity} value={props.value} icon={actionIcon} />
        </span>
    );
}

function RuleActionsCell(props) {
    return <RuleActions cell actionsValue={props.value} />;
}

//-----------------------------------------------------------------------------
// TASKS
//-----------------------------------------------------------------------------
function TaskNameCell(props) {
    return (
        <div
            onMouseEnter={props.onActionFieldEnter}
            onMouseLeave={props.onActionFieldLeave}
            className="bubble normal action-normal"
        >
            <DefaultCell entity={props.entity} value={props.value} />
        </div>
    );
}

function SearchTaskNameCell(props) {
    const QUERY = JSON.parse(props.entity.searchQuery);
    const ICONS = {
        Alarms: <IconStatusError fill="currentcolor" />,
        Computers: <IconHardwareComputer fill="currentcolor" />,
        Executables: <IconTerminalExecutable fill="currentcolor" />,
        Processes: <IconMediaPlay fill="currentcolor" />,
        Events: <IconMediaAlarm fill="currentcolor" />,
        Users: <IconUserUser fill="currentcolor" />,
        Descriptions: '',
    };

    return (
        <>
            <div
                onMouseEnter={props.onActionFieldEnter}
                onMouseLeave={props.onActionFieldLeave}
                className="bubble normal action-normal bubble-overflow-visible"
            >
                <DefaultCell
                    entity={props.entity}
                    icon={ICONS[QUERY.display]}
                    value={Filters.searchDisplayLookup(QUERY.display)}
                />
            </div>
            <Spacer type="ml-2" className="ei-cell-overflow">
                {props.entity.name}
            </Spacer>
        </>
    );
}

function ProgressCell(props) {
    return <Progressbar className="ei-full-width" type="percentage" percentage={props.value} />;
}

function HashCell(props) {
    return <SimpleHash hash={props.value} isGroup={props.entity?.isGroup} />;
}

function SearchTaskStateCell(props) {
    const icon =
        props.entity.flags === 0 ? (
            <IconStatusOk fill="currentcolor" />
        ) : props.entity.flags === 1 ? (
            <IconStatusWarning fill="currentcolor" />
        ) : props.entity.flags === 2 ? (
            <IconStatusError fill="currentcolor" />
        ) : null;
    return <DefaultCell entity={props.entity} value={props.value} icon={icon} />;
}

function SearchResultsCell(props) {
    function calcSearchResultLabel() {
        const SEARCH_QUERY = JSON.parse(props.entity.searchQuery);
        const SEARCH_RESULS_LOOKUP = SEARCH_QUERY.display;

        return `${props.value} ${i18n(SEARCH_RESULS_LOOKUP)}`;
    }

    return <DefaultCell entity={props.entity} value={calcSearchResultLabel()} />;
}

function ActionHistoryObjectCell(props) {
    if (!isValidDbId(props.entity.ahId)) {
        return DefaultCell(props);
    }
    const object = window.serverInfo.constants.dbObject;
    switch (props.entity.ahObject) {
        case object.module:
            return ExecutableNameActionCell(props);
        case object.alarm:
            props.entity.severity = props.entity.status;
            const newProps = {
                ...props,
                entity: {
                    ...props.entity,
                    isGroup: false,
                },
                value: props.entity.additionalData,
            };

            return AlarmNameActionCell(newProps);
        case object.script:
            return ScriptNameActionCell(props);
        case object.computer:
            return ComputerNameActionCell(props);
        case object.process:
            return ProcessActionCell(props);
        case object.rule:
            props.entity.severity = props.entity.status;
            return RuleNameActionCell(props);
        case object.exclusion:
            return ExecutableNameActionCell(props);
        case object.task:
            return TaskNameCell(props);
        case object.setting:
            return (
                <DefaultCell
                    onActionFieldEnter={props.onActionFieldEnter}
                    onActionFieldLeave={props.onActionFieldLeave}
                    entity={props.entity}
                    value={Filters.serverSettingCategory2String(props.entity.ahId)}
                />
            );
        case object.incident:
            const incidentProps = {
                ...props,
                entity: {
                    ...props.entity,
                    severity: props.entity.status,
                },
            };
            return IncidentNameActionCell(incidentProps);

        default:
            return DefaultCell(props);
    }
}

function ActionHistorySecondaryObjectCell(props) {
    if (
        props.entity.ahObject === window.serverInfo.constants.dbObject.task &&
        props.entity.ahOperation === window.serverInfo.constants.actionHistory.operation.stateChanged &&
        props.entity.ahSecondaryId !== undefined
    ) {
        return <DefaultCell entity={props.entity} value={Filters.taskState(props.entity.ahSecondaryId)} />;
    } else if (
        props.entity.ahObject === window.serverInfo.constants.dbObject.incident &&
        (props.entity.ahOperation === window.serverInfo.constants.actionHistory.operation.objectAdded ||
            props.entity.ahOperation === window.serverInfo.constants.actionHistory.operation.objectRemoved)
    ) {
        const objectProps = { icon: '', value: props.entity.secObjectName };
        if (Number(props.entity.additionalData) === window.serverInfo.constants.dbObject.alarm) {
            objectProps.icon = <IconStatusError fill="currentcolor" />;
        } else if (Number(props.entity.additionalData) === window.serverInfo.constants.dbObject.computer) {
            objectProps.icon = <IconHardwareComputer fill="currentcolor" />;
        } else if (Number(props.entity.additionalData) === window.serverInfo.constants.dbObject.module) {
            objectProps.icon = <IconTerminalExecutable fill="currentcolor" />;
        } else if (Number(props.entity.additionalData) === window.serverInfo.constants.dbObject.process) {
            objectProps.icon = <IconMediaPlay fill="currentcolor" />;
        } else {
            objectProps.icon = <EmptyIcon fill="currentcolor" />;
        }
        return DefaultCell(objectProps);
    } else {
        return DefaultCell(props);
    }
}

function TagsCell(props) {
    return <TagsRow cell value={props.value} />;
}

function TargetsCell(props) {
    const targets = props.value ? props.value.split('&#1;') : [];
    const text = targets.length === 1 ? targets[0] : `${targets.length} targets`;
    return targets.length > 1 ? (
        <span
            className={
                targets.length > 1
                    ? `bubble ${Filters.bubbleClassByStatusOrSeverity.get(props.entity.severity ?? 0)}`
                    : ''
            }
        >
            <DefaultCell entity={props.entity} value={text} />
        </span>
    ) : (
        text
    );
}

function IsolatedCell(props) {
    const icon = Filters.isolatedIcon(props.entity.isolated);
    if (!icon) {
        return null;
    }
    return (
        <DefaultCell
            icon={Filters.isolatedIcon(props.entity.isolated)}
            fillName="iconColor"
            value={i18n(props.value)}
        />
    );
}

function getSeverityWithDesc(alertSeverity) {
    switch (alertSeverity) {
        case 1:
            return [1, 'INFORMATION'];
        case 2:
            return [1, 'NOTICE'];
        case 3:
            return [2, 'WARNING'];
        case 4:
            return [3, 'ERROR'];
        case 5:
            return [3, 'CRITICAL'];
        case 6:
            return [3, 'FATAL'];
        default:
            return [0, null];
    }
}

function AlertsCount(props) {
    const [severity] = getSeverityWithDesc(props.entity.alertsMaxSeverity);
    return (
        <div
            className={props.value > 0 ? `clickable ${Filters.actionBubbleClassByStatusOrSeverity.get(severity)}` : ''}
            {...(props.value > 0 ? { onMouseEnter: props.onActionFieldEnter } : {})}
            {...(props.value > 0 ? { onMouseLeave: props.onActionFieldLeave } : {})}
        >
            <DefaultCell entity={props.entity} value={props.value} />
        </div>
    );
}

function AlertNameActionCell(props) {
    const [severity] = getSeverityWithDesc(props.entity.severity);
    return (
        <div className={Filters.getActionBubbleClass(severity)}>
            <DefaultCell entity={props.entity} value={props.value} />
        </div>
    );
}

function AlertSeverityCell(props) {
    const [severity, desc] = getSeverityWithDesc(props.entity.severity);
    return SEVERITY_ICON_FILL_BY_VALUE.has(severity) ? (
        <DefaultCell
            entity={props.entity}
            value={desc}
            icon={SEVERITY_ICON_FILL_BY_VALUE.get(severity)[0]}
            fillName={SEVERITY_ICON_FILL_BY_VALUE.get(severity)[1]}
        />
    ) : null;
}

function IncidentNameActionCell(props) {
    return (
        <>
            <div
                className={Filters.getActionBubbleClass(props.entity.severity + 1)}
                onMouseEnter={props.onActionFieldEnter}
                onMouseLeave={props.onActionFieldLeave}
            >
                <DefaultCell entity={props.entity} value={props.value} />
            </div>
            {props.entity.investigatedByEset && (
                <Spacer type="ml-2" className="ei-cell-overflow">
                    <div className="bubble eset">
                        <HighlightBlank alignCell value="INVESTIGATED_BY_ESET" monospace={props.monospace} />
                    </div>
                </Spacer>
            )}
        </>
    );
}

function IncidentSeverityCell(props) {
    if (!Filters.incidentSeverityIcon.has(props.entity.severity)) {
        return null;
    }
    const [Icon, fillName] = Filters.incidentSeverityIcon.get(props.entity.severity);
    return (
        <DefaultCell
            entity={props.entity}
            icon={Icon && <Icon fill="currentcolor" />}
            fillName={fillName}
            value={props.value}
        />
    );
}

function IncidentStatusCell(props) {
    if (!Filters.incidentStatusIcon.has(props.entity.status)) {
        return null;
    }
    const [Icon, fillName] = Filters.incidentStatusIcon.get(props.entity.status);
    return <DefaultCell entity={props.entity} icon={Icon && <Icon />} fillName={fillName} value={props.value} />;
}

function NotificationDescriptionCell(props) {
    const anotherValue = NotificationString(props.entity, true);
    return (
        <div
            className={Filters.getActionBubbleClass(0)}
            onMouseEnter={props.onActionFieldEnter}
            onMouseLeave={props.onActionFieldLeave}
        >
            <DefaultCell entity={props.entity} value={anotherValue.note} />
        </div>
    );
}

//-----------------------------------------------------------------------------
// REMEDIATIONS
//-----------------------------------------------------------------------------

function ProcessNameActionCell(props) {
    const { entity, value, onClick } = props;
    return (
        <div
            className={classnames(Filters.getActionBubbleClass(props.entity.status), 'clickable')}
            onClick={(event) => onClick(event, entity.processId)}
        >
            <ProcessIcon integrityLevel={entity.integrityLevel} />
            <HighlightBlank alignCell value={value} />
        </div>
    );
}

function ProcessNameWithPIDActionCell(props) {
    const { entity, value, onClick } = props;
    return (
        <div
            className={classnames(Filters.getActionBubbleClass(props.entity.status), 'clickable')}
            onClick={(event) => onClick(event, entity.processId)}
        >
            <ProcessIcon integrityLevel={entity.integrityLevel} />
            <HighlightBlank
                alignCell
                value={Number.isInteger(entity.systemProcessId) ? `${value} (${entity.systemProcessId})` : value}
            />
        </div>
    );
}

function OsApiParameters(props) {
    if (!props.value) {
        return <HighlightBlank alignCell value={''} monospace={props.monospace} />;
    }

    const parameters = props.value.map((arg) => `${arg.value}, `);
    const tooltipContent = (
        <table>
            <tbody>
                {props.value?.map((arg, id) => (
                    <tr key={id}>
                        <td>
                            <Text fontWeight="bold">{arg.name}:</Text>
                        </td>
                        <td>
                            <Text>{arg.value}</Text>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <QuickTooltip tooltipContent={tooltipContent}>
            <span className="ei-cell-overflow">{parameters}</span>
        </QuickTooltip>
    );
}

function MitreLink(props) {
    return (
        <Spacer type="pr-2">
            <Link
                onClick={() => {
                    window.open(`${getMitreIdUrl(props.mitreId)}`, '_blank');
                }}
                text={`${props.mitreId}${props.name ? ` - ${props.name}` : ''},`}
            />
        </Spacer>
    );
}

function OsApiMitre(props) {
    const mitres = props.value;
    if (Array.isArray(props.value)) {
        return mitres?.map((mitre, id) => <MitreLink key={id} mitreId={mitre} />);
    }
    return <HighlightBlank alignCell value={''} monospace={props.monospace} />;
}

function RemediationReputationCell(props) {
    return (
        <QuickTooltip tooltipContent={Filters.eiReputation(props.value)}>
            <div>
                <ReputationCell value={props.value} />
            </div>
        </QuickTooltip>
    );
}

function RemediationActionsCell(props) {
    const entityId = props.entity.id || props.entity.processId;
    const actions = props.settings.getActions(entityId);
    return (
        <FlexContainer className="flex-nowrap hide-overflow">
            {actions &&
                Object.keys(actions)?.map((actionValue) => {
                    const currentAction = actions[actionValue];
                    return (
                        <Spacer type="px-1" key={currentAction.title}>
                            <Tooltip
                                placement="auto"
                                disabled={!currentAction.tooltip}
                                tooltipContent={currentAction.tooltip}
                                zIndex={99999}
                            >
                                <span>
                                    <Checkbox
                                        checked={currentAction.checkedState ? checkState.checked : checkState.unchecked}
                                        disabled={currentAction.disabled}
                                        onChange={(checked) => {
                                            props.settings.onChange(entityId, actionValue, checked);
                                            return checked;
                                        }}
                                        text={i18n(currentAction.title)}
                                    />
                                </span>
                            </Tooltip>
                        </Spacer>
                    );
                })}
        </FlexContainer>
    );
}

export default {
    DefaultCell,
    SeverityCell,
    StatusCell,
    PriorityCell,
    ResolvedCell,
    ResolvedOccurrencesCell,
    GroupsCountCell,
    AugurScoreCell,
    AlarmNameActionCell,
    AlarmsActionsTakenCell,
    AlarmProcessCell,
    ExclusionCriteriaCellTemplate,
    ExecutableNameActionCell,
    ExecutableSafeCell,
    ExecutableBlockedCell,
    ComputerNameActionCell,
    TargetCell,
    ReputationCell,
    PopularityCell,
    DtdAnalysisStatusCell,
    DtdAnalysisStatusBar,
    DtdAnalysisStateCell,
    DtdAnalysisStateBubbleCell,
    DtdAnalysisBehaviourCell,
    UnresolvedCell,
    ScriptNameActionCell,
    ScriptSafeCell,
    ProcessLoadedModuleCell,
    TaskNameCell,
    SearchTaskNameCell,
    ProgressCell,
    CustomCell,
    EventArgumentCell,
    FieldValidCell,
    FieldEnabledCell,
    FieldCleanedCell,
    ProcessActionCell,
    RuleNameActionCell,
    ProcessNameWithPIDActionCell,
    RuleActionsCell,
    ComputerOsVersionCell,
    OriginExecutableNameCell,
    OriginUrlCell,
    HashCell,
    SearchTaskStateCell,
    SearchResultsCell,
    ActionHistoryObjectCell,
    ActionHistorySecondaryObjectCell,
    ActionHistoryStatusCell,
    ModuleOriginCell,
    TagsCell,
    TargetsCell,
    IsolatedCell,
    AlertsCount,
    AlertNameActionCell,
    AlertSeverityCell,
    IncidentSeverityCell,
    IncidentStatusCell,
    IncidentNameActionCell,
    NotificationDescriptionCell,
    ProcessNameActionCell,
    RemediationActionsCell,
    RemediationReputationCell,
    OsApiParameters,
    OsApiMitre,
    OccurrencesCell,
};
