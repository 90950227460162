import { FormGroup } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

export default function FormGroupWrapper(props) {
    return (
        <FormGroup
            {...props}
            description={i18n(props.description)}
            labelText={i18n(props.labelText)}
            tooltip={i18n(props.tooltip)}
            validationText={i18n(props.validationText)}
            optionalText={i18n(props.optionalText)}
        />
    );
}
