class Navigator {
    constructor() {
        this.router = null;
    }

    init(router) {
        this.router = router;
    }

    _handler(options, openInNewTab = false) {
        return function handler(event, stateName, stateParams = {}) {
            if (this.router === null) {
                throw new Error('Navigator not initialized!');
            }

            if (!stateName || (typeof stateName !== 'string' && typeof stateName !== 'object')) {
                throw new Error(`Navigator::handler - parameter 'stateName' is required.`);
            }

            if ((event && event.ctrlKey) || openInNewTab) {
                window.open(this.router.stateService.href(stateName, stateParams));
            } else {
                this.router.stateService.go(stateName, stateParams, options);
            }
        };
    }

    go = this._handler();
    open = this._handler(undefined, true);
    replace = this._handler({ location: 'replace' });
}

const navigator = new Navigator();

export default navigator;
