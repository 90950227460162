import i18n from 'i18next';
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/min/locales';

const LANGUAGES = {
    'en-US': {
        label: 'ENGLISH',
        value: window.serverInfo.constants.supportedLanguages.EN_US,
        valueForHtml: 'en',
        supported: true,
        translation: require('Assets/translations/en-US.json'),
    },
    'ja-JP': {
        label: 'Japanese',
        value: window.serverInfo.constants.supportedLanguages.JA_JP,
        valueForHtml: 'ja',
        supported: true,
        translation: require('Assets/translations/ja-JP.json'),
    },
    'uk-UA': {
        label: 'Ukrainian',
        value: window.serverInfo.constants.supportedLanguages.UK_UA,
        valueForHtml: 'uk',
        supported: true,
        translation: require('Assets/translations/uk-UA.json'),
    },
    'zh-CN': {
        label: 'Chinese',
        value: window.serverInfo.constants.supportedLanguages.ZH_CN,
        valueForHtml: 'zh',
        supported: true,
        translation: require('Assets/translations/zh-CN.json'),
    },
};

function getLanguageByValue(valueForLanguage) {
    const languageEntry = Object.entries(LANGUAGES).find((language) => {
        const languageProperties = language[1];
        return languageProperties.value === valueForLanguage;
    });
    return languageEntry[0];
}

function isLanguageSupported(languageCode) {
    if (languageCode in LANGUAGES) {
        return LANGUAGES[languageCode].supported;
    }
    return false;
}

function getDefaultLanguage() {
    return getLanguageByValue(window.serverInfo.constants.defaultLanguage);
}

const DEFAULT_LANGUAGE = getDefaultLanguage();

function getStoredLanguage() {
    const storedLanguage = window.localStorage.getItem('language');
    return storedLanguage && isLanguageSupported(storedLanguage) ? storedLanguage : DEFAULT_LANGUAGE;
}

function setLanguageInHtmlDocument(language) {
    document.documentElement.setAttribute('lang', LANGUAGES[language].valueForHtml);
}

function defineLanguageProperty(callback) {
    Object.defineProperty(window, 'eiLocalization', {
        configurable: true,
        get: () => i18n.language,
        set: async (newLanguage) => {
            if (newLanguage !== i18n.language && newLanguage in LANGUAGES) {
                await i18n.changeLanguage(newLanguage);
                setLanguageInHtmlDocument(newLanguage);
                window.localStorage.setItem('language', newLanguage);
                callback();
            }
        },
    });
}

function generateResources() {
    return Object.keys(LANGUAGES)
        .filter((language) => {
            return LANGUAGES[language].supported;
        })
        .reduce((res, language) => {
            res[language] = {
                translation: LANGUAGES[language].translation,
            };
            return res;
        }, {});
}

function init(lng = DEFAULT_LANGUAGE) {
    setLanguageInHtmlDocument(lng);
    moment.locale(navigator.language);
    return i18n.init({
        resources: generateResources(),
        react: {
            transKeepBasicHtmlNodesFor: ['br', 'em', 'i', 'strong', 'p', 'b'],
        },
        lng,
        fallbackLng: DEFAULT_LANGUAGE,
    });
}

function t(template, context = {}) {
    if (typeof template !== 'string') {
        return template;
    }
    return i18n.exists(template, context) ? i18n.t(template, context) : template;
}

function getResources() {
    return i18n.options?.resources;
}

function HtmlTranslation(props) {
    function createTranslation() {
        const context = props.i18nContext ? props.i18nContext : {};
        return { __html: sanitizeHtml(t(props.i18nKey, context)) };
    }
    return <span dangerouslySetInnerHTML={createTranslation()} />;
}

function i18nFormattedTranslation(eiTagName, eiAttributeName, FormatterComponent, template, context) {
    const translation = t(template, context);
    const splitedByEiTagName = translation.split(new RegExp(`{${eiTagName}|{/${eiTagName}}`, 'g'));
    return splitedByEiTagName.filter(Boolean).map((value) => {
        const eiAttributeValue = value.match(new RegExp(`(?<=${eiAttributeName}=')(.*)(?='})`, 'g'))?.[0];
        return eiAttributeValue ? (
            <FormatterComponent tagValue={eiAttributeValue} value={value.match(new RegExp(`(?<='})(.*)`, 'g'))?.[0]} />
        ) : (
            value
        );
    });
}

//-----------------------------------------------------------------------------
HtmlTranslation.propTypes = {
    i18nKey: PropTypes.string.isRequired,
};

export {
    init,
    HtmlTranslation,
    getResources,
    i18nFormattedTranslation,
    defineLanguageProperty,
    getStoredLanguage,
    getLanguageByValue,
    LANGUAGES,
    DEFAULT_LANGUAGE,
};
export default t;
