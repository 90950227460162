import { useState } from 'react';

import i18n from 'Services/i18n';
import { BACKEND, withBackend } from 'Services/backend';

import Progress from 'Bricks/Progress';

import { Button, Modal } from 'components/ui-library';

function CommentDeleteWindow(props) {
    const [progress, setProgress] = useState(false);
    const onCommentDelete = () => {
        setProgress(true);
        BACKEND.delete(
            'comment',
            {
                commentId: props.commentId,
                objectId: props.objectId,
                objectType: props.objectType,
                ...props.additionalDataForRequestBody,
            },
            props.componentUuid
        )
            .success(() => {
                props.onChange();
            })
            .always(() => {
                setProgress(false);
                props.onClose();
            })
            .execute();
    };

    return (
        <Modal
            show={props.isOpen}
            title="ARE_YOU_SURE"
            onDispose={props.onClose}
            buttons={[
                <Button id="eid-comment-button-save" type="primary" text="YES" onClick={onCommentDelete} />,
                <Button id="eid-comment-button-cancel" type="secondary" text="NO" onClick={props.onClose} />,
            ]}
        >
            {i18n('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_COMMENT')}
            <Progress showProgress={progress} />
        </Modal>
    );
}

export default withBackend(CommentDeleteWindow);
