import { IconActionMove } from '@uilib/business-components/index';
import { Fragment } from 'react';
import moment from 'moment';

import classnames from 'classnames';
import { BACKEND } from 'Services/backend';
import { EVENT, EventNames } from 'Services/Eventing';
import i18n from 'Services/i18n';
import { NumberFormatter, Spacer, Text } from 'components/ui-library';
import HtmlList from 'Bricks/html-list';

function openEraInNewTab(componentUuid, idParameter) {
    const newBrowserTab = window.open('about:blank', '_blank');
    BACKEND.get('oneTimeToken', componentUuid)
        .success((response) => {
            const ottParameter = response.oneTimeToken ? `?ott=${response.oneTimeToken}` : '';
            newBrowserTab.location.href = `${window.serverInfo.eraWebconsoleUrl}${ottParameter}${idParameter}`;
        })
        .execute();
}

function openComputerDetailsEraInNewTab(componentUuid, uuid) {
    const idParameter = `#id=CLIENTS:id=CLIENT_DETAILS;u=${uuid}`;
    openEraInNewTab(componentUuid, idParameter);
}

function escapeRegExp(string) {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

function isValidDbId(id) {
    return typeof id === 'number' && id > 0;
}

function createSimpleSelectionBody(entityId) {
    return { selection: [{ column: 'id', uniqueIds: entityId, selectAll: false }] };
}

function getMitreIdUrl(id) {
    return window.serverInfo.mitreUrl + (typeof id === 'string' ? id.replace('.', '/') : '');
}

function NotificationString(notification, cell) {
    const notificationType = window.serverInfo.constants.notificationType;
    switch (notification.type) {
        case notificationType.automaticExclusion:
            return {
                title: 'AUTOMATIC_EXCLUSION_CREATED',
                note: notification.description,
            };
        case notificationType.enableExclusionSuggestion:
            return {
                title: 'ENABLE_EXCLUSION',
                note: notification.description,
            };
        case notificationType.livegridConnectivity:
            return {
                title: 'LIVEGRID_CONNECTION_LOST',
                note: notification.description,
            };
        case notificationType.purgeFailed:
            return {
                title: 'PURGE_FAILED_TITLE',
                note: notification.description,
            };
        case notificationType.fullDisk:
            return {
                title: 'NOT_ENOUGH_DISK_SPACE',
                note: notification.description,
            };
        case notificationType.tooLargeEstimatedDbSize:
            return {
                title: 'NOT_ENOUGH_DISK_SPACE',
                note: notification.description,
            };
        case notificationType.dbSettingOn:
            return {
                title: 'DATABASE_CONFIGURATION',
                note: notification.description,
            };
        case notificationType.fullDatabase:
            return {
                title: 'NOT_ENOUGH_DISK_SPACE',
                note: notification.description,
            };
        case notificationType.detectionsMute:
            return {
                title: 'DETECTIONS_MUTE',
                note: notification.description,
            };
        case notificationType.automaticEventFilter:
            return {
                title: 'AUTOMATIC_EVENT_FILTER_CREATED',
                note: notification.description,
            };
        default:
            return {
                title: '',
                note: '',
            };
    }
}

function stringCompare(a, b, ignoreCase) {
    if (ignoreCase) {
        return a.localeCompare(b, undefined, { sensitivity: 'base' }) === 0;
    } else {
        return a.localeCompare(b);
    }
}

function targetsTooltip(entity) {
    const targetsArray = entity.targets.split('&#1;');
    if (targetsArray.length > 1) {
        if (!entity.targetsTooltip) {
            entity.targetsTooltip = <HtmlList data={targetsArray} />;
        }
        return entity.targetsTooltip;
    }
}

function rulesTooltip(entity) {
    if (!entity.rulesTooltip) {
        const rulesNamesArray = entity.ruleName.split('&#1;');
        const rulesStatusArray = entity.ruleStatus.split('&#1;');

        entity.rulesTooltip = <HtmlList title="RULES_NAMES_COLON" data={rulesNamesArray} status={rulesStatusArray} />;
    }
    return entity.rulesTooltip;
}

function createGroupsTreeDictionary(grupsTree) {
    function getName(name) {
        return name === 'All' ? i18n('ALL_COMPUTERS') : name;
    }

    function reduceGroupTreeChildren(
        node,
        initialObject = {
            [grupsTree.id]: {
                name: getName(grupsTree.name),
                locationType: grupsTree.locationType,
                disabled: grupsTree.accessible !== undefined && !grupsTree.accessible,
            },
        }
    ) {
        return node.children.reduce((dictionary, currentNode) => {
            const updatedDictionary = {
                ...dictionary,
                [currentNode.id]: {
                    name: getName(currentNode.name),
                    locationType: currentNode.locationType,
                    disabled: currentNode.accessible !== undefined && !currentNode.accessible,
                },
            };
            return currentNode.children?.length > 0
                ? reduceGroupTreeChildren(currentNode, updatedDictionary)
                : updatedDictionary;
        }, initialObject);
    }

    return reduceGroupTreeChildren(grupsTree);
}

function createDisabledGroupsFilters(groupsTreeDictionary) {
    return Object.keys(groupsTreeDictionary)
        .filter((id) => groupsTreeDictionary[id].disabled)
        .map((id) => ({ groupId: { type: 'shadow', active: true, operator: 'NE', value: id } }));
}

function accessGroupSubmenu(clbk) {
    return {
        ACCESS_GROUP_MOVE: {
            name: 'MOVE',
            icon: <IconActionMove fill="currentcolor" />,
            clbk: clbk,
        },
        ACCESS_GROUP: {
            name: 'ACCESS_GROUP',
            icon: <IconActionMove fill="currentcolor" />,
            commands: [['ACCESS_GROUP_MOVE']],
            multiSelection: true,
        },
    };
}

function updateAccessGroupSubmenuOnSelectionChange(commanding, entities) {
    const accessGroups = new Set();
    let moveAccessGroupDisabled = false;
    entities.forEach((entity) => {
        accessGroups.add(entity.accessGroupId);
        if (entity.isInternal) {
            moveAccessGroupDisabled = true;
        }
    });
    commanding.updateCommand(
        'ACCESS_GROUP',
        'header',
        accessGroups.size === 1
            ? entities[0].accessGroupPath
            : i18n('DISTINCT_ACCESS_GROUPS', { count: accessGroups.size })
    );
    commanding.updateCommand('ACCESS_GROUP_MOVE', 'disabled', moveAccessGroupDisabled);
}

function showAffectedRowsInfo(selectedRows, affectedRows) {
    EVENT.publish(EventNames.SIMPLE_ALERT_EVENT, {
        type: 'warning',
        message: (
            <Fragment>
                <Text fontWeight="bold" fontSize="14px">
                    {i18n('ROWS_AFFECTED', { affectedRows: affectedRows })}
                </Text>
                <Text fontWeight="bold" fontSize="14px">
                    {i18n('ROWS_NOT_AFFECTED', { notAffectedRows: selectedRows - affectedRows })}
                </Text>
            </Fragment>
        ),
        details: 'SOME_ROWS_WERE_NOT_AFFECTED_BECAUSE_YOU_DONT_HAVE_AN_ACCESS_TO_THEM',
    });
}

function isAffectedRowsLessThanSelectedRows(response, selectedRows) {
    if (response && response.affectedRows !== undefined && response.affectedRows < selectedRows) {
        showAffectedRowsInfo(selectedRows, response.affectedRows);
    }
}

function updateLocalFiltersWithTags(localFilters, activeTags) {
    if (activeTags) {
        let tagsLocalFilters = {
            OR: activeTags.map((tag) => ({ tagId: { EQ: tag.id } })),
        };

        if (tagsLocalFilters.OR.length >= 1) {
            if (tagsLocalFilters.OR.length === 1) {
                // Server responds with error in case of single element array!
                tagsLocalFilters = tagsLocalFilters.OR[0];
            }

            if (Object.keys(localFilters.filterTree).length === 0) {
                localFilters.filterTree = tagsLocalFilters;
            } else if (localFilters.filterTree.AND !== undefined) {
                localFilters.filterTree.AND.push(tagsLocalFilters);
            } else {
                localFilters.filterTree = { AND: [localFilters.filterTree, tagsLocalFilters] };
            }
        }
    }
    return localFilters;
}

function createTimeFrameFromDashboardTimestamp(timestamp, incidents) {
    const now = moment().endOf('day'); // dashboard timestamp uses whole day.
    const startTime = timestamp
        ? moment.utc(timestamp).local()
        : !incidents
        ? moment.utc(now).add(-1, 'M').local()
        : moment.utc(now).add(-30, 'd').local();
    const date = startTime;
    return [...Array(now.diff(startTime, 'days') + 1).keys()].map(() => {
        const dayFrame = {
            UTCtimestamp: date.clone().endOf('day').utc().format().replace(/[tz]/gi, ' ').trim(),
            localTimestamp: date.clone().endOf('day').local().format('YYYY-MM-DD HH:mm:ss'),
        };
        date.add(1, 'days');
        return dayFrame;
    });
}

export {
    openEraInNewTab,
    openComputerDetailsEraInNewTab,
    escapeRegExp,
    isValidDbId,
    createSimpleSelectionBody,
    getMitreIdUrl,
    NotificationString,
    stringCompare,
    targetsTooltip,
    rulesTooltip,
    createGroupsTreeDictionary,
    createDisabledGroupsFilters,
    accessGroupSubmenu,
    updateAccessGroupSubmenuOnSelectionChange,
    showAffectedRowsInfo,
    isAffectedRowsLessThanSelectedRows,
    updateLocalFiltersWithTags,
    createTimeFrameFromDashboardTimestamp,
};
