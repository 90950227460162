import React, { Component, useState } from 'react';
import { getColumns, resolveLocalFiltersConfiguration, requiredFields } from 'Views/incidents';
import { Button, Modal, Theme } from 'components/ui-library';
import { AppContext } from 'Services/AppContext';
import { Navigator } from 'Services';
import Grid from 'Bricks/Grid/Grid';

const INCIDENT_SELECTION_MODAL_THEME = {
    Modal: {
        maxWidth: '80%',
        maxHeight: '90%',
        width: '80%',
        height: '90%',
        innerPadding: '0px',
    },
};

class IncidentsGrid extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.state = {
            menuPortalTarget: null,
        };

        this.gridApi = null;

        this.gridOptions = {
            resetLocalFilters: false,
            requiredFields,
            columns: getColumns(),

            isRowStatusInfo: (entity) => {
                return entity.current;
            },
            onRowClicked: this.onRowClicked,
            onRowSelectionChanged: this.onRowSelectionChanged,
            preselectedItems: this.getPreselectedItems,
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
            },
            onReload: this.onReload,
            url: 'incidents',
            disableFooter: true,
            disableSelection: false,
            disableSelectAll: true,
        };
    }

    reload = () => {
        this.gridApi.reloadData();
        this.props.setSelectedIncident(null);
    };

    onReload = () => {
        this.props.setSelectedIncident([]);
    };

    onRowSelectionChanged = (entities) => {
        this.props.setSelectedIncident(entities);
    };

    onRowClicked = (event, entity) => {
        Navigator.go(event, 'console.incident.graph', { incidentId: entity.id });
    };

    componentDidMount() {
        this.setState({ menuPortalTarget: document.getElementById(this.props.id) });
    }

    render() {
        return (
            <Grid
                id={this.props.id}
                menuPortalTarget={this.state.menuPortalTarget}
                title="INCIDENTS"
                storage={this.props.storage}
                options={this.gridOptions}
                localFiltersOptions={resolveLocalFiltersConfiguration.call(this)}
                singleSelectionMode={true}
                disableGroupChangeSubscribe={true}
            />
        );
    }
}

function IncidentSelectionModal(props) {
    const [selectedIncidents, setSelectedIncidents] = useState([]);

    function onAddToIncident() {
        selectedIncidents[0] && props.onAddToIncident(selectedIncidents[0]);
    }

    return (
        <Theme.Provider theme={INCIDENT_SELECTION_MODAL_THEME}>
            <Modal
                id={props.id}
                show={props.showIncidentSelectionModal}
                title="SELECT_INCIDENT"
                onDispose={props.closeIncidentSelectionModal}
                buttons={[
                    <Button
                        id="eid-add-to-selected-incident"
                        onClick={onAddToIncident}
                        disabled={!selectedIncidents?.length}
                        text="ADD_TO_SELECTED_INCIDENT"
                    />,
                    <Button
                        id="eid-cancel-incident-selection"
                        type="secondary"
                        onClick={props.closeIncidentSelectionModal}
                        text="CANCEL"
                    />,
                ]}
            >
                <IncidentsGrid
                    id={props.id}
                    menuPortalTargetId={props.id}
                    setSelectedIncident={setSelectedIncidents}
                    storage="addToIncidentModal"
                />
            </Modal>
        </Theme.Provider>
    );
}

export default IncidentSelectionModal;
