import { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Checkbox, Section, Input, FormGroup, Modal, CheckboxGroup, checkState } from 'components/ui-library';

import Separator from 'Bricks/separator';
import i18n from 'Services/i18n';

//-----------------------------------------------------------------------------
function Selector(props) {
    const [selection, setSelection] = useState([]);

    const [pattern, setPattern] = useState('');

    const [height, setHeight] = useState(0);

    const sectionDivRef = useRef(null);

    useEffect(() => {
        if (props.options) {
            const newSelection = {};
            for (const option of props.options) {
                newSelection[option.id] = option.checked;
            }
            setSelection(newSelection);
        }
    }, [props.options]);

    const handleSelect = (event) => {
        setPattern('');
        props.onChange(event, selection);
        props.onClose(event);
    };

    const handleCheckboxChange = (checked, value, event) => {
        const newSelection = { ...selection };
        newSelection[value] = checked === checkState.checked;
        setSelection(newSelection);
    };

    const handlePatternChange = (value, event) => {
        setPattern(value);
        // Now that the view was refreshed because of pattern change
        // recalculate the height so the window has fixed top position.
        if (height === 0 && sectionDivRef.current !== null) {
            setHeight(sectionDivRef.current.clientHeight);
        }
    };

    const validateOption = (option) => {
        return pattern === '' || i18n(option.label).toUpperCase().includes(pattern.toUpperCase());
    };

    return (
        <Modal
            show={props.isOpen}
            title={props.title}
            onDispose={props.onClose}
            buttons={[
                <Button
                    id="eid-selector-button-select"
                    type="primary"
                    text={props.selectButtonText}
                    onClick={handleSelect}
                />,
                <Button
                    id="eid-selector-button-cancel"
                    type="secondary"
                    text={props.cancelButtonText}
                    onClick={props.onClose}
                />,
            ]}
        >
            {props.quickSearch && (
                <Section limitContentWidth={false}>
                    <FormGroup
                        labelText="ENTER_QUICK_SEARCH_PATTERN"
                        content={<Input type="text" autoFocus value={pattern} onChange={handlePatternChange} />}
                    />
                </Section>
            )}
            <Section limitContentWidth={false}>
                <div ref={sectionDivRef} style={height !== 0 ? { height } : null}>
                    <CheckboxGroup>
                        {props.options &&
                            props.options.filter(validateOption).map((option) => (
                                <Fragment key={option.id}>
                                    <FormGroup
                                        id={`eid-selector-checkbox-${option.id}`}
                                        content={
                                            <Checkbox
                                                className="ei-text-uppercase"
                                                text={i18n(option.label)}
                                                value={option.id}
                                                checked={
                                                    selection[option.id] ? checkState.checked : checkState.unchecked
                                                }
                                                onChange={handleCheckboxChange}
                                                testDataLabel="eid-checkbox"
                                            />
                                        }
                                    />
                                    <Separator spacing="m-2" />
                                </Fragment>
                            ))}
                    </CheckboxGroup>
                </div>
            </Section>
        </Modal>
    );
}

//-----------------------------------------------------------------------------
Selector.propTypes = {
    isOpen: PropTypes.bool,

    quickSearch: PropTypes.bool,

    title: PropTypes.string,

    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            checked: PropTypes.bool,
        })
    ),

    onChange: PropTypes.func,

    onClose: PropTypes.func,

    selectButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
};

//-----------------------------------------------------------------------------
Selector.defaultProps = {
    isOpen: false,

    quickSearch: false,

    title: '',

    options: [],

    onChange: null,

    onClose: null,

    selectButtonText: 'SELECT',
    cancelButtonText: 'CANCEL',
};

export default Selector;
