import { Component } from 'react';
import { SplitPane, Loading } from 'components/ui-library';
import GroupsPane from 'Bricks/complex-table-view/groups-pane';
import Grid, { TABLE_COLUMN_WIDTH } from 'Bricks/Grid/Grid';
import { BACKEND, withBackend } from 'Services/backend';
import GridCells from 'Bricks/Grid/Cells';
import Panel from 'Bricks/Panel';
import './index.scss';
import { createGroupsTreeDictionary, createDisabledGroupsFilters } from 'Bricks/Helpers';

const DEFAULT_LEARNING_MODE_MODAL_FIRSTPANE_RATIO = 30;
const SETTINGS_URL = `users/settings/learning_mode_modal`;

class Computers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            groups: null,
            groupId: 1,
            initialized: false,
            learningModeModalFirstPaneRatio: DEFAULT_LEARNING_MODE_MODAL_FIRSTPANE_RATIO,
        };

        this.localFiltersOptions = {
            filters: {
                AND: [{ status: { type: 'shadow', active: true, operator: 'NE', value: '-1' } }],
            },

            subgroups: {
                visible: true,
                value: true,
            },
        };

        this.options = {
            requiredFields: ['id', 'groupId', 'status', 'agentVersion'],
            columns: [
                {
                    field: 'name',
                    name: 'NAME',
                    width: TABLE_COLUMN_WIDTH.WIDE,
                    cellComponent: GridCells.ComputerNameActionCell,
                },
            ],
            disableHeader: true,
            disableFooter: true,
            disableSelection: false,
            disableColumnsMenu: true,
            preselectedAll: true,
            onRowSelectionChanged: this.props.onComputersSelectionChange,
            isRowStatusThreat: (entity) => {
                return entity.status === 3;
            },
            isRowStatusWarning: (entity) => {
                return entity.status === 2;
            },
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
            },
            url: () => `machines/${this.state.groupId}`,
        };
    }

    handleGroupsTreeChange = (groupId) => {
        this.setState({ groupId });
        this.gridApi.reloadData();
    };

    loadSettings() {
        BACKEND.get(SETTINGS_URL, this.props.componentUuid)
            .success((response) => {
                response.learningModeModalFirstPaneRatio &&
                    this.setState({ learningModeModalFirstPaneRatio: response.learningModeModalFirstPaneRatio });
            })
            .always(() => {
                this.setState({ initialized: true });
            })
            .execute();
    }

    loadGroups = () => {
        BACKEND.post(
            'groupsTree/0',
            { accessRight: window.serverInfo.constants.accessRight.AR_Exclusion_Management },
            this.props.componentUuid
        )
            .success((response) => {
                this.localFiltersOptions = {
                    filters: {
                        AND: [
                            ...createDisabledGroupsFilters(createGroupsTreeDictionary(response)),
                            { status: { type: 'shadow', active: true, operator: 'NE', value: '-1' } },
                        ],
                    },

                    subgroups: {
                        visible: true,
                        value: true,
                    },
                };

                this.setState({ groups: [response] });
            })
            .execute();
    };

    saveSettings() {
        BACKEND.put(
            SETTINGS_URL,
            { learningModeModalFirstPaneRatio: this.state.learningModeModalFirstPaneRatio },
            this.props.componentUuid
        ).execute();
    }

    saveLearningModeModalFirstPaneRatio = (size) => {
        if (this.state.initialized) {
            this.setState({ learningModeModalFirstPaneRatio: size }, () => {
                this.saveSettings();
            });
        }
    };

    componentDidMount() {
        this.loadSettings();
        this.loadGroups();
    }

    render() {
        return this.state.groups ? (
            <div className="computers-table">
                <Panel>
                    <SplitPane
                        forceFirstPaneRatio={!this.state.initialized}
                        firstPaneRatio={!this.state.initialized ? 0 : this.state.learningModeModalFirstPaneRatio}
                        savePaneRatio={this.saveLearningModeModalFirstPaneRatio}
                    >
                        <GroupsPane groups={this.state.groups} onChange={this.handleGroupsTreeChange} />
                        <Grid
                            storage="machines"
                            options={this.options}
                            localFiltersOptions={this.localFiltersOptions}
                        />
                    </SplitPane>
                </Panel>
            </div>
        ) : (
            <Loading show />
        );
    }
}

export default withBackend(Computers);
