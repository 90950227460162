import { Component, Fragment } from 'react';

import { toast, Modal, Button, FormGroup } from 'components/ui-library';

import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';
import Filters from 'Services/Filters';
import GroupFilterService from 'Services/group-filter';
import { Navigator } from 'Services';
import {
    updateAccessGroupSubmenuOnSelectionChange,
    showAffectedRowsInfo,
    isAffectedRowsLessThanSelectedRows,
} from 'Bricks/Helpers';
import AccessGroupModal from 'Bricks/access-group-modal';
import ComplexTableView from 'Bricks/complex-table-view';
import { TABLE_COLUMN_WIDTH } from 'Bricks/Grid/Grid';
import GridCells from 'Bricks/Grid/Cells';
import WebConsoleUserSelect from 'Bricks/web-console-user-select';
import { AppContext } from 'Services/AppContext';
import createIncidentesCommanding, { COMMANDING_MODE } from './commanding';

const ASSIGNEE_MODAL_ID = 'eid-assignee-modal';
const INCIDENT_SEVERITY = window.serverInfo.constants.incidentSeverity;
const INCIDENT_STATUS = window.serverInfo.constants.incidentStatus;

function getColumns() {
    return [
        {
            field: 'name',
            name: 'NAME',
            width: TABLE_COLUMN_WIDTH.WIDE,
            cellComponent: GridCells.IncidentNameActionCell,
        },
        {
            field: 'description',
            name: 'DESCRIPTION',
        },
        {
            field: 'severity',
            name: 'SEVERITY',
            cellComponent: GridCells.IncidentSeverityCell,
            filter: Filters.incidentSeverity,
            width: TABLE_COLUMN_WIDTH.NARROW,
            padding: true,
        },
        {
            field: 'status',
            name: 'STATUS',
            cellComponent: GridCells.IncidentStatusCell,
            filter: Filters.incidentStatus,
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            padding: true,
        },
        {
            field: 'authorName',
            name: 'AUTHOR',
        },
        {
            field: 'accessGroup',
            name: 'ACCESS_GROUP',
        },
        {
            field: 'createTime',
            name: 'CREATE_TIME',
            filter: Filters.whenSeenOrLocalDate,
        },
        {
            field: 'assigneeName',
            name: 'ASSIGNEE',
        },
        {
            field: 'lastUpdate',
            name: 'LAST_UPDATE',
            filter: Filters.whenSeenOrLocalDate,
        },
        {
            field: 'detections',
            name: 'DETECTIONS',
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            centered: true,
        },
        {
            field: 'computers',
            name: 'COMPUTERS',
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            centered: true,
        },
        {
            field: 'executables',
            name: 'EXECUTABLES',
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            centered: true,
        },
        {
            field: 'processes',
            name: 'PROCESSES',
            width: TABLE_COLUMN_WIDTH.X_NARROW,
            centered: true,
        },
    ];
}

const requiredFields = ['id', 'current', 'severity', 'investigatedByEset', 'accessGroupId', 'accessGroupPath'];

function resolveLocalFiltersConfiguration() {
    return {
        filters: {
            AND: [
                {
                    status: {
                        type: 'shadow',
                        active: true,
                        operator: 'NE',
                        value: INCIDENT_STATUS.deleted,
                    },
                },
                {
                    accessGroupId: {
                        fixed: true,
                        type: 'groupsTree',
                        label: 'ACCESS_GROUP',
                        disableLabelSplit: true,
                    },
                },
                {
                    OR: [
                        // Column field extended by suffix to create unique id.
                        {
                            'severity:low': {
                                type: 'button',
                                value: INCIDENT_SEVERITY.low,
                                icon: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.low)[0],
                                color: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.low)[1],
                                tooltipText: 'SHOW_LOW_SEVERITY_INCIDENTS',
                            },
                        },
                        {
                            'severity:medium': {
                                type: 'button',
                                value: INCIDENT_SEVERITY.medium,
                                icon: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.medium)[0],
                                color: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.medium)[1],
                                tooltipText: 'SHOW_MEDIUM_SEVERITY_INCIDENTS',
                            },
                        },
                        {
                            'severity:high': {
                                type: 'button',
                                value: INCIDENT_SEVERITY.high,
                                icon: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.high)[0],
                                color: Filters.incidentSeverityIcon.get(INCIDENT_SEVERITY.high)[1],
                                tooltipText: 'SHOW_HIGH_SEVERITY_INCIDENTS',
                            },
                        },
                    ],
                },
                {
                    OR: [
                        // Column field extended by suffix to create unique id.
                        {
                            'status:open': {
                                type: 'button',
                                value: INCIDENT_STATUS.open,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.open)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.open)[1],
                                tooltipText: 'SHOW_OPENED_INCIDENTS',
                            },
                        },
                        {
                            'status:inProgress': {
                                type: 'button',
                                value: INCIDENT_STATUS.inProgress,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.inProgress)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.inProgress)[1],
                                tooltipText: 'SHOW_INPROGRESS_INCIDENTS',
                            },
                        },
                        {
                            'status:onHold': {
                                type: 'button',
                                value: INCIDENT_STATUS.onHold,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.onHold)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.onHold)[1],
                                tooltipText: 'SHOW_ONHOLD_INCIDENTS',
                            },
                        },
                        {
                            'status:resolved': {
                                type: 'button',
                                value: INCIDENT_STATUS.resolved,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.resolved)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.resolved)[1],
                                tooltipText: 'SHOW_RESOLVED_INCIDENTS',
                            },
                        },
                        {
                            'status:closed': {
                                type: 'button',
                                value: INCIDENT_STATUS.closed,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.closed)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.closed)[1],
                                tooltipText: 'SHOW_CLOSED_INCIDENTS',
                            },
                        },
                        {
                            'status:invalid': {
                                type: 'button',
                                value: INCIDENT_STATUS.invalid,
                                icon: Filters.incidentStatusIcon.get(INCIDENT_STATUS.invalid)[0],
                                color: Filters.incidentStatusIcon.get(INCIDENT_STATUS.invalid)[1],
                                tooltipText: 'SHOW_INVALID_INCIDENTS',
                            },
                        },
                    ],
                },
                { name: { type: 'text', label: 'NAME' } },
                { description: { type: 'text', label: 'DESCRIPTION' } },
                { authorName: { type: 'text', label: 'AUTHOR' } },
                { createTime: { type: 'timestamp', label: 'CREATE_TIME' } },
                { assigneeName: { type: 'text', label: 'ASSIGNEE' } },
                { lastUpdate: { type: 'timestamp', label: 'LAST_UPDATE' } },
                { detections: { type: 'number', label: 'DETECTIONS' } },
                { computers: { type: 'number', label: 'COMPUTERS' } },
                { executables: { type: 'number', label: 'EXECUTABLES' } },
                { processes: { type: 'number', label: 'PROCESSES' } },
                { incidentSize: { type: 'shadow' } },
            ],
        },

        presets: {
            visible: this.props.$stateParams?.urlFilters === undefined,
            default: [
                {
                    name: 'INCIDENTS_ASSIGNED_TO_ME',
                    filters: {
                        assigneeName: {
                            visible: true,
                            active: true,
                            operator: 'EQ',
                            value: this.context?.username,
                        },
                    },
                },
            ],
        },
    };
}

class IncidentsView extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.gridApi = null;

        this.state = {
            isAssigneeSelectOpen: false,
            isAccessGroupModalOpen: false,
            assignee: null,
        };

        //---------------------------------------------------------
        // EI-GRID CONFIGURATION
        //---------------------------------------------------------
        this.gridOptions = {
            resetLocalFilters: this.props.$stateParams.urlFilters !== undefined,
            requiredFields,
            columns: getColumns(),
            //csvPostProcessors: { detailLink: { columnName: 'detailPage', urlTemplate: 'machines/{id}/details' } },
            isRowStatusInfo: (entity) => {
                return entity.current;
            },
            onRowClicked: this.onRowClicked,
            onRowSelectionChanged: this.onRowSelectionChanged,
            onRegisterApi: (gridApi) => {
                this.gridApi = gridApi;
            },
            url: 'incidents',
        };

        const accessGroupSubmenuClbk = () => {
            this.setState({ isAccessGroupModalOpen: true });
        };

        this.commandingConfiguration = createIncidentesCommanding.call(this, {
            accessGroupSubmenuClbk,
            mode: COMMANDING_MODE.GRID,
            reload: this.reload,
            componentUuid: this.props.componentUuid,
            onAssigneeSelectOpen: this.onAssigneeSelectOpen,
        });
    } // c-tor

    componentDidMount() {
        GroupFilterService.disable();
    }

    componentWillUnmount() {
        GroupFilterService.enable();
    }

    reload = () => {
        this.gridApi.reloadData();
    };

    onRowClicked = (event, entity) => {
        Navigator.go(event, 'console.incident.graph', { incidentId: entity.id });
    };

    onRowSelectionChanged = (entities) => {
        updateAccessGroupSubmenuOnSelectionChange(this.gridApi.commanding, entities);
    };

    onAssigneeSelectOpen = () => {
        this.setState({ isAssigneeSelectOpen: true });
    };

    validateAssignee = (newAssignee) => {
        if (newAssignee) {
            this.setState({ assigneeError: '' });
            return true;
        } else {
            this.setState({ assigneeError: 'ASSIGNEE_IS_REQUIRED' });
            return false;
        }
    };

    onAssigneeChange = (assignee) => {
        this.validateAssignee(assignee);
        this.setState({ assignee });
    };

    assignToIncidents = (event) => {
        if (this.validateAssignee(this.state.assignee)) {
            this.onAssigneeSelectClose();
            this.gridApi.updateBackendWithSelection(
                'post',
                `incidents/assignee/${this.state.assignee.value}`,
                (response, selectedCount) => {
                    this.gridApi.reloadData();
                    if (response && response.affectedRows !== undefined && response.affectedRows < selectedCount) {
                        showAffectedRowsInfo(selectedCount, response.affectedRows);
                    } else {
                        toast.success(i18n('SUCCESSFULLY_ASSIGNED_INCIDENTS'), {
                            autoClose: 3000,
                        });
                    }
                }
            );
        }
    };

    onAssigneeSelectClose = () => {
        this.setState({ isAssigneeSelectOpen: false, assignee: null, assigneeError: '' });
    };

    handleAccessGroupModal = (selectedGroupId) => {
        const requestBody = this.gridApi.getSelectionRequestBody();
        requestBody['accessGroupId'] = selectedGroupId;
        const selectedRows = this.gridApi.getSelectedCountFromState();

        BACKEND.post('incidents/access_group', requestBody, this.props.componentUuid)
            .success((response) => {
                isAffectedRowsLessThanSelectedRows(response, selectedRows);
                this.gridApi.reloadDataFromSession();
            })
            .execute();
    };

    handleAccessGroupModalClose = () => {
        this.setState({ isAccessGroupModalOpen: false });
    };

    render() {
        return (
            <Fragment>
                <ComplexTableView
                    title="INCIDENTS"
                    storage="incidents"
                    tagsObjectType={window.serverInfo.constants.dbObject.incident}
                    options={this.gridOptions}
                    localFiltersOptions={resolveLocalFiltersConfiguration.call(this)}
                    commandingConfiguration={this.commandingConfiguration}
                    linkFilters={this.props.$stateParams.filters}
                />
                <Modal
                    id={ASSIGNEE_MODAL_ID}
                    show={this.state.isAssigneeSelectOpen}
                    title="ASSIGNEE"
                    onDispose={this.onAssigneeSelectClose}
                    buttons={[
                        <Button
                            id="eid-incidents-assignee-button-confirm"
                            type="primary"
                            text="CONFIRM"
                            onClick={this.assignToIncidents}
                            disabled={Boolean(this.state.assigneeError)}
                        />,
                        <Button
                            id="eid-incidents-assignee-button-cancel"
                            type="secondary"
                            text="CANCEL"
                            onClick={this.onAssigneeSelectClose}
                        />,
                    ]}
                >
                    <FormGroup
                        labelText="ASSIGNEE"
                        hasError={Boolean(this.state.assigneeError)}
                        validationText={this.state.assigneeError}
                        content={
                            <WebConsoleUserSelect
                                modalId={ASSIGNEE_MODAL_ID}
                                value={this.state.assignee}
                                onChange={this.onAssigneeChange}
                            />
                        }
                    />
                </Modal>
                <AccessGroupModal
                    show={this.state.isAccessGroupModalOpen}
                    onChange={this.handleAccessGroupModal}
                    close={this.handleAccessGroupModalClose}
                />
            </Fragment>
        );
    }
}

export { getColumns, resolveLocalFiltersConfiguration, requiredFields };

export default withBackend(IncidentsView);
