import {
    IconNetworkConnect,
    IconNetworkDisconnect,
    IconStatusOk,
    IconStatusWarning,
    IconStatusError,
    IconTimeClock,
    IconEngineeringAutomation,
    IconStatusSuspended,
    IconStatusSafe,
    IconSelectionRadio,
    IconStatusBlocked,
    IconArrowCaretDown,
    IconArrowCaretUp,
    IconArrowCaretUpAll,
    IconHardwareComputer,
    IconTerminalExecutable,
    IconMediaPlay,
    IconUserUser,
} from '@uilib/business-components/index';
import i18n from 'Services/i18n';
import moment from 'moment';

const INCIDENT_SEVERITY = window.serverInfo.constants.incidentSeverity;
const INCIDENT_STATUS = window.serverInfo.constants.incidentStatus;

const ISOLATED_ICON_BY_VALUE = new Map([
    [
        window.serverInfo.constants.machineIsolation.notIsolated,
        { icon: <IconNetworkConnect fill="currentcolor" />, label: 'NOT_ISOLATED' },
    ],
    [
        window.serverInfo.constants.machineIsolation.isolated,
        { icon: <IconNetworkDisconnect fill="currentcolor" />, label: 'ISOLATED' },
    ],
    [
        window.serverInfo.constants.machineIsolation.isolatedByRule,
        { icon: <IconNetworkDisconnect fill="currentcolor" />, label: 'ISOLATED_BY_RULE' },
    ],
]);

class Filters {
    static translate(columnFilter, columnValue) {
        const COLUMN_FILTER = window.serverInfo.columnsFilters[columnFilter];
        return i18n(COLUMN_FILTER[columnValue]) || i18n('UNKNOWN_COLUMN_VALUE', { columnValue });
    }

    static ONE_SECOND_AS_MS = 1000;
    static ONE_MINUTE_AS_MS = 60 * Filters.ONE_SECOND_AS_MS;
    static ONE_HOUR_AS_MS = 60 * Filters.ONE_MINUTE_AS_MS;
    static ONE_DAY_AS_MS = 24 * Filters.ONE_HOUR_AS_MS;

    static ONE_WEEK_AS_DAYS = 7;
    static ONE_MONTH_AS_DAYS = 30; // Not very precise...
    static ONE_YEAR_AS_DAYS = 365; // Same as above...

    static WIN10_PREFIX = 'w10.0.';

    static SEVERITY_STATUS_THREAT = 3;
    static SEVERITY_STATUS_WARNING = 2;
    static SEVERITY_STATUS_INFO = 1;
    static SEVERITY_STATUS_OK = 0;

    static cloudAge(value) {
        // Before the data is received asynchronously this method is called with undefined. In this case just return empty string
        if (value === null || value === undefined) {
            return null;
        }

        if (value === 0) {
            return i18n('NEVER_SEEN_IN_LIVEGRID');
        }

        const suffix = i18n(value > 0 ? 'AGO' : 'FROM_NOW');
        return `${moment.duration(value, 'days').humanize()} ${suffix}`;
    }

    static dynamicThreatDetectionStatusName(value) {
        if (value === null) {
            return '';
        }
        const DTD_ANALYSIS_STATUS_NAMES = ['CLEAN', 'SUSPICIOUS', 'VERY_SUSPICIOUS', 'MALICIOUS'];
        return value >= 1 && value <= 4 ? i18n(DTD_ANALYSIS_STATUS_NAMES[value - 1]) : '';
    }

    static DTD_ANALYSIS_STATUS_TAGS = new Map([
        [1, 'success'],
        [2, 'warning'],
        [3, 'error'],
        [4, 'error'],
    ]);

    static DTD_ANALYSIS_STATUS_ICONS = new Map([
        [1, <IconStatusOk fill="currentcolor" />],
        [2, <IconStatusWarning fill="currentcolor" />],
        [3, <IconStatusError fill="currentcolor" />],
        [4, <IconStatusError fill="currentcolor" />],
    ]);

    static dynamicThreatDetectionState(value) {
        const DTD_STATE = ['UNKNOWN', 'ANALYSING', 'FINISHED', 'FAILED', 'REANALYSING'];

        if (typeof value !== 'number') {
            return null;
        }
        return i18n(((value) => 0 && value <= 4) ? i18n(DTD_STATE[value]) : '');
    }

    static whenSeen(value) {
        return moment(value).fromNow();
    }

    static localDate(value) {
        return value !== undefined && value !== null ? moment(value).local().format('ll, LTS') : value;
    }

    static whenSeenAndLocalDate(value) {
        return value !== undefined && value !== null
            ? `${Filters.whenSeen(value)} - ${Filters.localDate(value)}`
            : value;
    }

    static whenSeenOrLocalDate(value, entity, dateType) {
        if (dateType === 'Absolute') {
            return Filters.localDate(value);
        }
        return value !== undefined && value !== null ? Filters.whenSeen(value) : value;
    }

    static isolatedIcon(value) {
        if (!ISOLATED_ICON_BY_VALUE.has(value)) {
            return null;
        }
        return ISOLATED_ICON_BY_VALUE.get(value).icon;
    }

    static isolatedLabel(value) {
        if (!ISOLATED_ICON_BY_VALUE.has(value)) {
            return null;
        }
        return ISOLATED_ICON_BY_VALUE.get(value).label;
    }

    static summaryRuleNames(value) {
        return value.split('&#1;')[0];
    }

    static bytes(value, format) {
        // Before the data is received asynchronously this method is called with undefined. In this case just return empty string
        if (value === null || value === undefined) {
            return null;
        }

        const KB = 1024;
        const MB = 1024 * KB;
        const GB = 1024 * MB;

        const bytes = Number(value);
        if (bytes >= GB) {
            return `${Math.floor((bytes / GB) * 10) / 10} GB`;
        } else if (bytes >= MB) {
            return `${Math.floor((bytes / MB) * 10) / 10} MB`;
        } else if (bytes >= KB) {
            return `${Math.floor((bytes / KB) * 10) / 10} KB`;
        } else if (bytes !== 0) {
            return `${bytes} B`;
        }
        return bytes;
    }

    static OS_FAMILY = {
        UNKNOWN: -1,
        WINDOWS: 0,
        MACOS: 1,
        LINUX: 2,
    };

    static osArchitecture(value) {
        if (value === undefined || value === null) {
            return value;
        }

        switch (value) {
            case 0:
                return '32-bit';
            case 1:
                return '64-bit';

            // Return 'Unknown' by default.
            default:
                return null;
        }
    }

    static isValidSystemPid(pid) {
        return typeof pid === 'number' && pid >= 0;
    }

    static blank(value) {
        return value === null ? i18n('UNKNOWN') : value === '' ? i18n('NONE') : value;
    }

    static moduleDescription(isMacho, isElf, isPe, signatureId, fileDescription) {
        return (
            (isElf && 'ELF') ||
            (isMacho && (signatureId ? `Mach-O: ${signatureId}` : 'Mach-O')) ||
            (isPe && (fileDescription ? `PE: ${fileDescription}` : 'PE')) ||
            'Unknown'
        );
    }

    static processSignType(value) {
        if (value === undefined || value === null) {
            return value;
        }

        switch (value) {
            //-------------------------------------
            // Trusted types by ESET.
            //-------------------------------------
            case 90:
                return i18n('TRUSTED');

            //-------------------------------------
            // Trusted types by other source.
            //-------------------------------------
            case 80:
                return i18n('VALID');

            //-------------------------------------
            // Untrusted types.
            //-------------------------------------
            case 70:
                return ''; // this should be display as "None" by highlightBlank directive

            case 75:
                return i18n('ADHOC');

            case 60:
                return i18n('INVALID');

            case 50:
                return i18n('SIGNATURE_PRESENT');

            //-------------------------------------
            default:
                return null; // this should be display as "Unknown" by highlightBlank directive
        }
    }

    static processSignTypeOnDetection(current, valueOnDetection) {
        if (current === valueOnDetection || valueOnDetection === null || valueOnDetection === undefined) {
            return '';
        }
        const translatedValue = this.processSignType(valueOnDetection);
        const signatureTypeToDisplay =
            translatedValue === '' ? i18n('NONE') : translatedValue === null ? i18n('UNKNOWN') : translatedValue;
        return i18n('SIGNATURE_TYPE_ON_DETECTION_WITH_VALUE', { valueOnDetection: signatureTypeToDisplay });
    }

    static SIGN_TYPE_VALUES = [
        { id: 90, name: i18n('TRUSTED') },
        { id: 80, name: i18n('VALID') },
        { id: 75, name: i18n('ADHOC') },
        { id: 70, name: i18n('NONE') },
        { id: 60, name: i18n('INVALID') },
        { id: 50, name: i18n('SIGNATURE_PRESENT') },
    ];

    static LIVE_GUARD_STATUS_FILTER = [
        { id: 1, name: i18n('CLEAN') },
        { id: 2, name: i18n('SUSPICIOUS') },
        { id: 3, name: i18n('VERY_SUSPICIOUS') },
        { id: 4, name: i18n('MALICIOUS') },
    ];

    static LIVE_GUARD_STATE_FILTER = [
        { id: 0, name: i18n('UNKNOWN') },
        { id: 1, name: i18n('ANALYSING') },
        { id: 2, name: i18n('FINISHED') },
        { id: 3, name: i18n('FAILED') },
        { id: 4, name: i18n('REANALYSING') },
    ];

    static whitelistType(value) {
        switch (value) {
            case 0:
                return ''; // this should be display as "None" by highlightBlank directive

            case 2:
                return i18n('LIVEGRID');

            case 3:
                return i18n('CERTIFICATE');

            default:
                return null; // this should be display as "Unknown" by highlightBlank directive
        }
    }

    static alarmType(input) {
        switch (input) {
            case 1:
                return i18n('RULE_WAS_ACTIVATED');
            case 2:
            case 3:
                return i18n('ANTIVIRUS_DETECTION');
            case 6: // this seems to not be used but anyway this exploit detection
            case 4:
                return i18n('EXPLOIT_WAS_DETECTED');
            case 5:
                return i18n('FIREWALL_DETECTION');
            case 7:
                return i18n('URL_ACCESS_WAS_BLOCKED');
            case 8:
                return i18n('RANSOMWARE_WAS_DETECTED');
            default:
                return '';
        }
    }

    static ipProtocolNumberToName(input) {
        //https://en.wikipedia.org/wiki/List_of_IP_protocol_numbers
        const protName = [
            'IPv6 Hop-by-Hop Option',
            'Internet Control Message Protocol',
            'Internet Group Management Protocol',
            'Gateway-to-Gateway Protocol',
            'IP in IP (encapsulation)',
            'Internet Stream Protocol',
            'Transmission Control Protocol',
            'Core-based trees',
            'Exterior Gateway Protocol',
            'Interior Gateway Protocol',
            'BBN RCC Monitoring',
            'Network Voice Protocol',
            'Xerox PUP',
            'ARGUS',
            'EMCON',
            'Cross Net Debugger',
            'Chaos',
            'User Datagram Protocol',
            'Multiplexing',
            'DCN Measurement Subsystems',
            'Host Monitoring Protocol',
            'Packet Radio Measurement',
            'XEROX NS IDP',
            'Trunk-1',
            'Trunk-2',
            'Leaf-1',
            'Leaf-2',
            'Reliable Data Protocol',
            'Internet Reliable Transaction Protocol',
            'ISO Transport Protocol Class 4',
            'Bulk Data Transfer Protocol',
            'MFE Network Services Protocol',
            'MERIT Internodal Protocol',
            'Datagram Congestion Control Protocol',
            'Third Party Connect Protocol',
            'Inter-Domain Policy Routing Protocol',
            'Xpress Transport Protocol',
            'Datagram Delivery Protocol',
            'IDPR Control Message Transport Protocol',
            'TP++ Transport Protocol',
            'IL Transport Protocol',
            'IPv6 Encapsulation',
            'Source Demand Routing Protocol',
            'Routing Header for IPv6',
            'Fragment Header for IPv6',
            'Inter-Domain Routing Protocol',
            'Resource Reservation Protocol',
            'Generic Routing Encapsulation',
            'Dynamic Source Routing Protocol',
            'Burroughs Network Architecture',
            'Encapsulating Security Payload',
            'Authentication Header',
            'Integrated Net Layer Security Protocol',
            'SwIPe',
            'NBMA Address Resolution Protocol',
            'IP Mobility (Min Encap)',
            'Transport Layer Security Protocol',
            'Simple Key-Management for Internet Protocol',
            'ICMP for IPv6',
            'No Next Header for IPv6',
            'Destination Options for IPv6',
            'Any host internal protocol',
            'CFTP',
            'Any local network',
            'SATNET and Backroom EXPAK',
            'Kryptolan',
            'MIT Remote Virtual Disk Protocol',
            'Internet Pluribus Packet Core',
            'Any distributed file system',
            'SATNET Monitoring',
            'VISA Protocol',
            'Internet Packet Core Utility',
            'Computer Protocol Network Executive',
            'Computer Protocol Heart Beat',
            'Wang Span Network',
            'Packet Video Protocol',
            'Backroom SATNET Monitoring',
            'SUN ND PROTOCOL-Temporary',
            'WIDEBAND Monitoring',
            'WIDEBAND EXPAK',
            'International Organization for Standardization Internet Protocol',
            'Versatile Message Transaction Protocol',
            'Secure Versatile Message Transaction Protocol',
            'VINES',
            'Internet Protocol Traffic Manager or Transaction Transport Protocol',
            'NSFNET-IGP',
            'Dissimilar Gateway Protocol',
            'TCF',
            'EIGRP',
            'Open Shortest Path First',
            'Sprite RPC Protocol',
            'Locus Address Resolution Protocol',
            'Multicast Transport Protocol',
            'AX.25',
            'KA9Q NOS compatible IP over IP tunneling',
            'Mobile Internetworking Control Protocol',
            'Semaphore Communications Sec. Pro',
            'Ethernet-within-IP Encapsulation',
            'Encapsulation Header',
            'Any private encryption scheme',
            'GMTP',
            'Ipsilon Flow Management Protocol',
            'PNNI over IP',
            'Protocol Independent Multicast',
            "IBM's ARIS (Aggregate Route IP Switching) Protocol",
            'SCPS (Space Communications Protocol Standards)',
            'QNX',
            'Active Networks',
            'IP Payload Compression Protocol',
            'Sitara Networks Protocol',
            'Compaq Peer Protocol',
            'IPX in IP',
            'Virtual Router Redundancy Protocol',
            'PGM Reliable Transport Protocol',
            'Any 0-hop protocol',
            'Layer Two Tunneling Protocol Version 3',
            'D-II Data Exchange (DDX)',
            'Interactive Agent Transfer Protocol',
            'Schedule Transfer Protocol',
            'SpectraLink Radio Protocol',
            'Universal Transport Interface Protocol',
            'Simple Message Protocol',
            'Simple Multicast Protocol',
            'Performance Transparency Protocol',
            'Intermediate System to Intermediate System (IS-IS) Protocol over IPv4',
            'Flexible Intra-AS Routing Environment',
            'Combat Radio Transport Protocol',
            'Combat Radio User Datagram',
            'Service-Specific Connection-Oriented Protocol in a Multilink and Connectionless Environment',
            'IPLT',
            'Secure Packet Shield',
            'Private IP Encapsulation within IP',
            'Stream Control Transmission Protocol',
            'Fibre Channel',
            'Reservation Protocol (RSVP) End-to-End Ignore',
            'Mobility Extension Header for IPv6',
            'Lightweight User Datagram Protocol',
            'Multiprotocol Label Switching Encapsulated in IP',
            'MANET Protocols',
            'Host Identity Protocol',
            'Site Multihoming by IPv6 Intermediation',
            'Wrapped Encapsulating Security Payload',
            'Robust Header Compression',
        ];

        return input < protName.length ? protName[input] : input === 16640 ? 'ARP' : i18n('UNKNOWN');
    }

    static alarmUserType(value) {
        return Filters.translate('alarmUserType', value);
    }

    static paths(input) {
        return input ? input.split('&#1;')[0] : '';
    }

    //---------------------------------------------------------
    // ALARM ACTION FILTER
    //---------------------------------------------------------
    static alarmAction(value) {
        const eiAlarmActions = [
            'CLEANED',
            'DELETED',
            'CONNECTION_TERMINATED',
            'CLEANED_BY_DELETING',
            'WAS_PART_OF_DELETED_OBJECT',
            'MARKED_FOR_DELETION',
            'BLOCKED',
        ];

        return i18n(eiAlarmActions[value - 1] || ''); //-1 because indexes start from 1 in this case
    }

    static malwareType(value) {
        return Filters.translate('alarmMalwareType', value);
    }

    static alarmSeverity(value) {
        switch (value) {
            case 1:
                return i18n('INFORMATION');
            case 2:
                return i18n('WARNING');
            case 3:
                return i18n('THREAT');
            default:
                return i18n('UNKNOWN_SEVERITY', { severity: value });
        }
    }

    static eventOpcode(value) {
        return Filters.translate('eventOpcode', value);
    }

    static eventArgument(value) {
        if (value === undefined || value === null || typeof value === 'string') {
            return value;
        } else {
            return value
                .map((argument) => {
                    if (typeof argument === 'string') {
                        return argument;
                    } else {
                        return argument.label;
                    }
                })
                .join('');
        }
    }

    static eventMountPoint(value) {
        switch (value) {
            case 0:
                return '';
            case 1:
                return '%SYSTEM%';
            case 2:
                return '%WINDIR%';
            case 3:
                return '%PROGRAMDATA%';
            case 4:
                return '%PROGRAMFILES%';
            case 5:
                return '%PROGRAMFILES(X86)%';
            case 6:
                return '%APPDATA%';
            case 7:
                return '%LOCALAPPDATA%';
            case 8:
                return '%HOME%';
            case 9:
                return '%TEMP%';
            case 10:
                return 'HKCU';
            case 11:
                return 'HKLM';
            case 12:
                return '%RemovableDrive%';
            case 13:
                return '%RemoteDrive%';
            case 14:
                return '%CDROM%';
            case 15:
                return '%COMMONAPPDATA%';
            case 16:
                return '%COMMONDESKTOP%';
            case 17:
                return '%COMMONDOCUMENTS%';
            case 18:
                return '%COMMONPROGRAMS%';
            case 19:
                return '%COMMONSTARTMENU%';
            case 20:
                return '%COMMONSTARTUP%';
            case 21:
                return '%COMMONTEMPLATES%';
            case 22:
                return '%COMMONMUSIC%';
            case 23:
                return '%COMMONPICTURES%';
            case 24:
                return '%COMMONVIDEO%';
            case 25:
                return '%STARTMENU%';
            case 26:
                return '%STARTUP%';
            case 27:
                return '%DESKTOP%';
            case 28:
                return '%LOCALAPPDATALOW%';
            default:
                return '';
        }
    }

    static computersCountInfo(count) {
        return i18n('COMPUTERS_COUNT', { count });
    }

    static eiReputationValue(value) {
        if (typeof value !== 'number' || value < 1 || value > 9) {
            return '';
        }

        const EI_REPUTATION_VALUE_MAP = {
            1: 'MALICIOUS',
            2: 'MALICIOUS',
            3: 'SUSPICIOUS',
            4: 'SUSPICIOUS',
            5: 'SUSPICIOUS',
            6: 'SUSPICIOUS',
            7: 'SUSPICIOUS',
            8: 'SAFE',
            9: 'SAFE',
        };
        return `${i18n(EI_REPUTATION_VALUE_MAP[value])} (${value})`;
    }

    static isReputationSafe(value) {
        return value === 8 || value === 9;
    }

    static eiReputationInfo(value) {
        return typeof value === 'number' && (value === 3 || value === 4)
            ? ` - ${i18n('UNSEEN_BY_LIVEGRID_POTENTIALLY_UNWANTED_APPLICATION')}`
            : '';
    }

    static eiPopularityValue(value) {
        if (typeof value !== 'number' || value < 0 || value >= 12) {
            return '';
        }

        if (value === 0) {
            return i18n('NEVER_SEEN_IN_LIVEGRID');
        }

        const EI_LOWER_REPUTATION = Math.pow(10, value - 1);
        const EI_UPPER_REPUTATION = Math.pow(10, value) - 1;
        return i18n('RANGE_OF_COMPUTERS', {
            lower: EI_LOWER_REPUTATION.toLocaleString(),
            upper: EI_UPPER_REPUTATION.toLocaleString(),
        });
    }

    static eiPopularityInfo(value) {
        return typeof value === 'number' && value > 0 && value < 12 ? ` (${i18n('APPROXIMATION')})` : '';
    }

    static eiReputationPopularityChange(value) {
        if (value == null || value.current == null) {
            return '';
        }
        if (value.historical == null) {
            return i18n('THIS_DATA_WAS_NOT_AVAILABLE_WHEN_DETECTION_WAS_TRIGGERED');
        }
        const points = value.current - value.historical;
        if (points === 0) {
            return '';
        }

        return i18n(
            points > 0
                ? 'WAS_N_POINTS_LOWER_WHEN_DETECTION_WAS_TRIGGERED'
                : 'WAS_N_POINTS_HIGHER_WHEN_DETECTION_WAS_TRIGGERED',
            { count: Math.abs(points) }
        );
    }

    static taskState(value) {
        switch (value) {
            case 0:
                return i18n('PENDING');
            case 1:
                return i18n('RUNNING');
            case 2:
                return i18n('PAUSED');
            case 3:
                return i18n('COMPLETED');
            case 4:
                return i18n('DETECTIONS_LIMIT_REACHED');
            case 5:
                return i18n('REMOVED');
            case 6:
                return i18n('FAILED');

            default:
                return null;
        }
    }

    static searchDisplayAttribute(name) {
        const SEARCH_ATTRIBUTE_LABEL_MAP = {
            IpAddress: 'IP_ADDRESS',
            MacAddress: 'MAC_ADDRESS',
            VersionInfo: 'VERSION_INFO',
            DropperSha1: 'DROPPER_SHA1',
            CommandLine: 'COMMAND_LINE',
            RuleName: 'RULE_NAME',
        };

        return i18n(SEARCH_ATTRIBUTE_LABEL_MAP[name] || name.toUpperCase());
    }

    static taskNameFromQuery(query) {
        if (query.complex !== undefined) {
            return i18n('ADVANCED_QUERY');
        }

        let taskName = '';
        if (query.simple.lookup !== query.display) {
            taskName += `with ${Filters.searchDisplayLookup(query.simple.lookup)} `;
        }
        return taskName + `with ${Filters.searchDisplayAttribute(query.simple.attribute)} "${query.simple.value}"`;
    }

    static criteriaEntityTypes = {
        ET_PROCESS: 0,
        ET_PARENT_PROCESS: 1,
        ET_ANCESTOR_PROCESS: 2,
        ESF_EVENTS: 3,
        ET_NUM_OF: 4,
    };

    static getExclusionTypeString(type) {
        const criteriaEntityTypes = ['PROCESSES', 'PARENT_PROCESSES', 'ANY_ANCESTOR_PROCESSES', 'EVENTS'];
        return i18n(criteriaEntityTypes[type]);
    }

    static exclusionConditionsConfiguration(criteriaEntityType) {
        const MIN_CONDITION_LENGTH = 2;
        const MAX_CONDITION_LENGTH = 4096;
        const DEFAULT_PATTERN = '.+';
        const SHA1_PATTERN = '^[0-9a-fA-F]{40}$';

        function validateLength(minConditionLength, maxConditionLength) {
            return function (condition) {
                if (condition.length < minConditionLength || condition.length > maxConditionLength) {
                    return i18n('CONDITION_MUST_HAVE_BETWEEN_MIN_AND_MAX_CHARACTERS', {
                        minConditionLength,
                        maxConditionLength,
                    });
                }
                return '';
            };
        }

        function validatePattern(pattern, message) {
            return function (condition) {
                if (new RegExp(pattern || DEFAULT_PATTERN).test(condition) === false) {
                    return i18n(message || 'CONDITION_DOES_NOT_MATCH_PATTERN');
                }
                return '';
            };
        }

        function defaultValidate(condition) {
            return `${validateLength(MIN_CONDITION_LENGTH, MAX_CONDITION_LENGTH)(condition)} ${validatePattern()(
                condition
            )}`.trim();
        }

        function hashValidate(condition) {
            return validatePattern(SHA1_PATTERN, 'SHA1_MUST_BE_40_DIGITS_LONG_HEXADECIMAL_NUMBER')(condition);
        }

        function groupValidate(condition) {
            return `${defaultValidate(condition)} ${validatePattern(
                /^(?!All$)/i,
                i18n('ALL_IS_NOT_VALID_FOR_GROUP_CONDITION', { value: condition })
            )(condition)}`.trim();
        }

        return [
            {
                ref: 'fileNames',
                visible: true,
                name: 'PROCESS_NAME',
                suffix: 'IS_ONE_OF',
                validate: defaultValidate,
            },
            {
                ref: 'filePaths',
                visible: true,
                name: 'PROCESS_PATH',
                suffix: 'STARTS_WITH',
                validate: defaultValidate,
            },
            {
                ref: 'cmdLines',
                visible: true,
                name: 'CMD_LINE',
                suffix: 'CONTAINS',
                validate: defaultValidate,
            },
            {
                ref: 'signers',
                visible: true,
                name: 'SIGNER_NAME',
                suffix: 'IS_ONE_OF',
                validate: defaultValidate,
            },
            {
                signType: true,
                visible: true,
                name: 'SIGNATURE_TYPE',
                suffix: 'IS',
            },
            {
                ref: 'hashes',
                visible: true,
                name: 'SHA1',
                suffix: 'IS_ONE_OF',
                validate: hashValidate,
            },
            {
                ref: 'users',
                visible: true,
                name: 'USER',
                suffix: 'IS_ONE_OF',
                validate: defaultValidate,
            },
        ].map((conditionConf) => ({
            ...conditionConf,
            name: i18n(conditionConf.name),
            suffix: i18n(conditionConf.suffix),
            enabled: new Map(
                Object.keys(Filters.criteriaEntityTypes).map((criteriaEntityType) => [
                    Filters.criteriaEntityTypes[criteriaEntityType],
                    false,
                ])
            ),
        }));
    }

    static getCriteriaConditionsPreviewContext(context) {
        return {
            rulesSummary: context.rules ? context.rules.rulesSummary : [],
            type: context.criteria.type,
            signType: {
                enabled: context.criteria.conditionsConfiguration
                    .find((c) => c.signType)
                    .enabled.get(context.criteria.type),
                operator: Filters.FILTER_RULE_CONVERSION_TABLE[context.criteria.signTypeCurrentOperator],
                name: context.criteria.signTypeCurrentValue.name,
            },
            criteria: context.criteria.conditionsConfiguration
                .filter((c) => !c.signType && c.enabled.get(context.criteria.type))
                .map((c) => ({
                    name: c.name,
                    suffix: c.suffix,
                    tags: context.criteria[c.ref].get(context.criteria.type),
                }))
                .filter((c) => c.tags.length > 0),
        };
    }

    static FILTER_RULE_CONVERSION_TABLE = {
        GE: 'greaterOrEqual',
        LE: 'lessOrEqual',
        EQ: 'is',
        NE: 'isnot',
    };

    static fieldBoolEnabledDisabled(value) {
        return i18n(value ? 'ENABLED' : 'DISABLED');
    }

    static fieldBoolValidInvalid(value) {
        return i18n(value ? 'VALID' : 'INVALID');
    }

    static fieldBoolYesNo(value) {
        return i18n(value ? 'YES' : 'NO');
    }

    static integrityLevel(input) {
        if (input === undefined || input === null) {
            return input;
        }

        const LEVELS = [
            { value: window.serverInfo.constants.integrityLevel.UNTRUSTED, name: 'UNTRUSTED' },
            { value: window.serverInfo.constants.integrityLevel.LOW, name: 'LOW' },
            { value: window.serverInfo.constants.integrityLevel.MEDIUM, name: 'MEDIUM' },
            { value: window.serverInfo.constants.integrityLevel.HIGH, name: 'HIGH' },
            { value: window.serverInfo.constants.integrityLevel.SYSTEM, name: 'SYSTEM' },
            { value: window.serverInfo.constants.integrityLevel.PROTECTED_PROCESS, name: 'PROTECTED_PROCESS' },
        ];
        const filteredLevels = LEVELS.filter(({ value }) => value <= input);

        return i18n(filteredLevels[filteredLevels.length - 1].name);
    }

    static ruleConditionOperator(value) {
        const RULE_CONDITION_OPERATORS_MAP = {
            greater: 'IS_GREATER_THAN',
            greaterOrEqual: 'IS_GREATER_OR_EQUAL_THAN',
            less: 'IS_LESS_THAN',
            lessOrEqual: 'IS_LESS_OR_EQUAL_THAN',
            is: 'IS',
            isnot: 'IS_NOT',
        };

        return i18n(RULE_CONDITION_OPERATORS_MAP[value] || '');
    }
    static eiReputation(value) {
        return Filters.eiReputationValue(value) + Filters.eiReputationInfo(value);
    }

    static eiPopularity(value) {
        return Filters.eiPopularityValue(value) + Filters.eiPopularityInfo(value);
    }

    static incidentSeverity(value) {
        return Filters.translate('incidentSeverity', value);
    }

    static incidentStatus(value) {
        return Filters.translate('incidentStatus', value);
    }

    static isCompromised(flags) {
        const ProcessIsCompromisedFlag = 2;
        return (flags & ProcessIsCompromisedFlag) === ProcessIsCompromisedFlag;
    }

    static toType(value, flags = 0) {
        return value === 3 ? 'error' : value === 2 || this.isCompromised(flags) ? 'warning' : 'info';
    }

    static INCOMPLETE_DATA_REASON = {
        PURGED: 1,
        COMPUTERS_LIMITED_ACCESS: 2,
        ALARMS_LIMITED_ACCESS: 3,
    };

    static incompleteDbDataMessage(reason) {
        switch (reason) {
            case this.INCOMPLETE_DATA_REASON.PURGED:
                return i18n('DATA_OLDER_THAN_N_DAYS_MIGHT_HAVE_BEEN_REMOVED_DURING_DATABASE_CLEANUPS', {
                    count: window.serverInfo.dbStorageDays,
                });
            case this.INCOMPLETE_DATA_REASON.COMPUTERS_LIMITED_ACCESS:
                return i18n('SOME_COMPUTERS_ARE_NOT_VISIBLE_DUE_TO_LIMITED_ACCESS_RIGHTS');
            case this.INCOMPLETE_DATA_REASON.ALARMS_LIMITED_ACCESS:
                return i18n('SOME_DETECTIONS_ARE_NOT_VISIBLE_DUE_TO_LIMITED_ACCESS_RIGHTS');
            default:
                return '';
        }
    }

    static fileSize(fileSize) {
        return (
            fileSize !== undefined && (
                <span id="module-txt-filesize">
                    {Filters.bytes(fileSize)} ({fileSize} {i18n('BYTES')})
                </span>
            )
        );
    }

    static SCANNER_IDS = {
        0x00011000: 'ESET Inspect',
        0x00011200: 'ESET LiveGuard',
        0x01010100: 'On-demand scanner (Windows)',
        0x01030200: 'HTTP Filter (Windows)',
        0x01020200: 'POP3 filter (Windows)',
        0x01020201: 'IMAP filter (Windows)',
        0x01030201: 'FTP filter (Windows)',
        0x01010101: 'Real-time file system protection',
        0x01020100: 'Email filter - Outlook',
        0x01020101: 'Email filter - Outlook Express',
        0x01020102: 'Email filter - Thunderbird',
        0x01024100: 'Mail server filter',
        0x01014200: 'File server filter',
        0x01010102: 'Startup scanner',
        0x01010104: 'Document protection',
        0x01020103: 'Email filter - Windows Mail',
        0x01020104: 'Email filter - Windows Live Mail',
        0x02010106: 'CLI interface',
        0x02020101: 'IMAP filter (Unix)',
        0x02020102: 'MDA wrapper',
        0x02020103: 'Pipe filter',
        0x02020104: 'POP3 filter (Unix)',
        0x02020105: 'Sendmail filter',
        0x02020106: 'SMTP filter',
        0x02020107: 'ZMailer filter',
        0x02010100: 'On-demand scanner (Unix)',
        0x02010101: 'Dazuko access protection',
        0x02010102: 'FTP filter (Unix)',
        0x02030100: 'HTTP filter (Unix)',
        0x02030101: 'HTTP proxy filter (Unix)',
        0x02010103: 'HTTP filter (Unix; obsolete)',
        0x02010104: 'Preload access protection',
        0x02010105: 'SafeSquid filter',
        0x0201010b: 'Real-time file system protection (Unix)',
        0x0200011e: 'ESET Daemon',
        0x02010107: 'ICAP server',
        0x02010109: 'Kernel access control',
        0x02010108: 'Graphical user interface',
        0x02020108: 'CommuniGate Pro filter',
        0x01034300: 'Gateway HTTP filter',
        0x01034301: 'Gateway FTP filter',
        0x01024300: 'Gateway SMTP filter',
        0x01024301: 'Gateway POP3 filter',
        0x01024302: 'Gateway IMAP filter',
        0x01014201: 'File database scanner',
        0x01000100: 'Advanced memory scanner',
        0x01010106: 'Idle scanner',
        0x01010107: 'First scan scanner',
        0x01000101: 'Registry scanner',
        0x01024102: 'Mail database scanner',
        0x01024101: 'Mail database on-demand scanner',
        0x01014400: 'Hyper-V scanner',
        0x01030107: 'JavaScript scanner',
        0x01000102: 'AMSI scanner',
        0x01030202: 'HTTP network protection',
        0x01014300: 'Gateway server filter',
        0x01000103: 'Command line scanner',
        0x01010109: 'Ransomware scanner',
        0x02024101: 'Mail server filter (Unix)',
        0x0202010a: 'Proxy POP3 filter (Unix)',
        0x0202010b: 'Proxy IMAP filter (Unix)',
        0x02030102: 'Proxy FTP filter (Unix)',
    };

    static scanner(value) {
        if (value === null || value === undefined) {
            return value;
        }
        return (
            Filters.SCANNER_IDS[value] ||
            i18n('UNRECOGNIZED_VALUE_X', { value: `0x${('00000000' + value.toString(16)).substr(-8)}` })
        );
    }

    static triggerEvent(value, entity) {
        const getPropertyPrefixed = (entity, property) => {
            if (entity.hasOwnProperty(property)) {
                return entity[property];
            } else if (entity.hasOwnProperty(`Alarms_${property}`)) {
                return entity[`Alarms_${property}`];
            } else {
                return undefined;
            }
        };

        const triggerEvent = getPropertyPrefixed(entity, 'triggerEvent');

        const triggerEventArgument = getPropertyPrefixed(entity, 'triggerEventArgument');

        const triggerEventValue =
            triggerEvent === undefined || triggerEvent === null
                ? triggerEvent
                : `${Filters.eventOpcode(triggerEvent)} ${triggerEventArgument}`;

        return triggerEventValue;
    }

    static ahRemediationKind = (value) => {
        if (value === null || value === undefined) {
            return value;
        }

        const remediationKind = window.serverInfo.constants.actionHistory.remediationKind;
        switch (value) {
            case remediationKind.killProcess:
                return i18n('KILL_PROCESS');
            case remediationKind.banHashes:
                return i18n('BAN_HASHES');
            case remediationKind.forceSync:
                return i18n('WAKE_UP_CALL');
            case remediationKind.reboot:
                return i18n('REBOOT');
            case remediationKind.scan:
                return i18n('SCAN');
            case remediationKind.shutdown:
                return i18n('SHUTDOWN');
            case remediationKind.sysInspectorLog:
                return i18n('GENERATE_SYSINSPECTOR_LOG');
            case remediationKind.isolate:
                return i18n('ISOLATE_FROM_NETWORK');
            case remediationKind.integrate:
                return i18n('END_NETWORK_ISOLATION');
            case remediationKind.logout:
                return i18n('LOGOUT');

            // Return 'Unknown' by default.
            default:
                return null;
        }
    };

    static ahOperation2String = (value, entity) => {
        if (value === null || value === undefined) {
            return value;
        }

        const operation = window.serverInfo.constants.actionHistory.operation;
        return i18n(
            (() => {
                switch (value) {
                    /* common */
                    case operation.note:
                        return 'NOTED';
                    case operation.created:
                        return 'CREATED';
                    case operation.modified:
                        return 'MODIFIED';
                    case operation.deleted:
                        return 'DELETED';
                    case operation.enabled:
                        return 'ENABLED';
                    case operation.disabled:
                        return 'DISABLED';
                    /* detections */
                    case operation.unresolved:
                        return 'MARKED_AS_UNRESOLVED';
                    case operation.resolved:
                        return 'MARKED_AS_RESOLVED';
                    case operation.priorityChanged:
                        return 'PRIORITY_CHANGED';
                    /* modules, scripts, computers */
                    case operation.unsafe:
                        return 'MARKED_AS_UNSAFE';
                    case operation.safe:
                        return 'MARKED_AS_SAFE';
                    case operation.remoteConnect:
                        return 'REMOTE_CONNECT';
                    case operation.remoteDisconnect:
                        return 'REMOTE_DISCONNECT';
                    case operation.remoteCommand:
                        return 'REMOTE_COMMAND';
                    /* modules */
                    case operation.unbanned:
                        return 'UNBANNED';
                    case operation.banned:
                        return 'BANNED';
                    case operation.cleaned:
                        return 'CLEANED';
                    case operation.downloadStart:
                        return 'DOWNLOAD_START';
                    case operation.downloadReady:
                        return 'DOWNLOAD_READY';
                    case operation.downloadRetry:
                        return 'DOWNLOAD_RETRY';
                    /* user */
                    case operation.login:
                        return 'LOGIN';
                    case operation.logout:
                        return 'LOGOUT';
                    /* process */
                    case operation.kill:
                        return 'KILLED';
                    case operation.submitToLiveGuard:
                        return 'LIVEGUARD_SCAN';

                    /* computer */
                    case operation.remediation:
                        const translatedRemediation = i18n('REMEDIATION');
                        return entity !== undefined && entity.ahSecondaryId >= 0
                            ? `${translatedRemediation} - ${Filters.ahRemediationKind(entity.ahSecondaryId)}`
                            : translatedRemediation;

                    case operation.sysInspectorLog:
                        return 'DOWNLOAD_SYSINSPECTOR_LOG';
                    /* exclusion */
                    case operation.addedToRule:
                        return 'ADDED_TO_RULE';
                    case operation.removedFromRule:
                        return 'REMOVED_FROM_RULE';
                    /* task */
                    case operation.stateChanged:
                        return 'STATE_CHANGED';

                    /* tag */
                    case operation.tagAttached:
                        return 'TAGGED';
                    case operation.tagDetached:
                        return 'UNTAGGED';

                    /* incidents */
                    case operation.assigneeChanged:
                        return 'ASSIGNEE_CHANGED';
                    case operation.statusChanged:
                        return 'STATUS_CHANGED';
                    case operation.severityChanged:
                        return 'SEVERITY_CHANGED';
                    case operation.objectAdded:
                        return 'OBJECT_ADDED';
                    case operation.objectRemoved:
                        return 'OBJECT_REMOVED';

                    /* comment */
                    case operation.commentAdded:
                        return 'COMMENT_ADDED';
                    case operation.commentUpdated:
                        return 'COMMENT_UPDATED';
                    case operation.commentDeleted:
                        return 'COMMENT_DELETED';

                    /* notification */
                    case operation.notificationResolved:
                        return 'NOTIFICATION_RESOLVED';
                    case operation.accepted:
                        return 'NOTIFICATION_RESOLVED_WITH_ACCEPT';
                    case operation.rejected:
                        return 'NOTIFICATION_RESOLVED_WITH_REJECT';

                    /* rules */
                    case operation.usersActionsModified:
                        return 'USER_ACTIONS_MODIFIED';

                    /* targets and access group */
                    case operation.targetsModified:
                        return 'TARGETS_MODIFIED';
                    case operation.accessGroupModified:
                        return 'ACCESS_GROUP_MODIFIED';

                    // Return 'Unknown' by default.
                    default:
                        return null;
                }
            })()
        );
    };

    static dbObject = (value) => {
        const object = window.serverInfo.constants.dbObject;
        switch (value) {
            case object.module:
                return i18n('MODULE');
            case object.alarm:
                return i18n('DETECTION');
            case object.script:
                return i18n('SCRIPT');
            case object.computer:
                return i18n('COMPUTER');
            case object.process:
                return i18n('PROCESS');
            case object.rule:
                return i18n('RULE');
            case object.exclusion:
                return i18n('EXCLUSION');
            case object.task:
                return i18n('TASK');
            case object.user:
                return i18n('USER');
            case object.setting:
                return i18n('SETTINGS');
            case object.incident:
                return i18n('INCIDENT');
            case object.notification:
                return i18n('QUESTION');
            case object.tag:
                return i18n('TAG');

            // Return 'Unknown' by default.
            default:
                return null;
        }
    };

    static serverSettingCategory2String = (serverSettingCategory) => {
        const category = window.serverInfo.constants.serverSettings;
        switch (parseInt(serverSettingCategory)) {
            case category.webCertificate:
                return i18n('WEB_CERTIFICATE');
            case category.serverCertificate:
                return i18n('SERVER_CERTIFICATE');
            case category.certificateAuthorities:
                return i18n('CERTIFICATE_AUTHORITIES');
            case category.serverPerformance:
                return i18n('SERVER_PERFORMANCE');
            case category.database:
                return i18n('DATABASE');
            case category.proxy:
                return i18n('PROXY');
            case category.misc:
                return i18n('MISC');
            case category.caStoreClear:
                return i18n('CA_STORE_CLEAR');
            case category.logging:
                return i18n('LOGGING');
            case category.remoteConnector:
                return i18n('REMOTE_CONNECTOR');

            // Return 'Unknown' by default.
            default:
                return null;
        }
    };

    static searchDisplayLookup(value) {
        return i18n((value === 'Alarms' ? 'Detections' : value).toUpperCase());
    }

    static storageExport(value) {
        switch (value) {
            case 'alarms':
                return 'detections';
            case 'dashboardAlarms':
                return 'dashboardDetections';
            case 'computerAlarms':
                return 'computerDetections';
            case 'moduleAlarms':
                return 'moduleDetections';
            case 'processAlarms':
                return 'processDetections';
            case 'taskAlarms':
                return 'taskDetections';
            default:
                return value;
        }
    }

    static dbObjectsToDelete = (value) => {
        const object = window.serverInfo.constants.dbObject;
        switch (value) {
            case object.module:
                return i18n('MODULES');
            case object.alarm:
                return i18n('DETECTIONS');
            case object.computer:
                return i18n('COMPUTERS');
            case object.process:
                return i18n('PROCESSES');
            case object.rule:
                return i18n('RULES');
            case object.exclusion:
                return i18n('EXCLUSIONS');
            case object.task:
                return i18n('TASKS');
            case object.user:
                return i18n('USERS');
            case object.setting:
                return i18n('SETTINGS');
            case object.incident:
                return i18n('INCIDENTS');

            default:
                return i18n('UNKNOWN_OBJECTS');
        }
    };

    static moduleEventsPart = (value) => {
        return `${value}%`;
    };

    static thresholdLabel(value) {
        function pad(num) {
            const afterCommaLen = (Math.floor(num) === 0 ? 4 : 3) - Math.round(num).toString().length;
            const fixedString = Number(num.toFixed(Math.max(afterCommaLen, 0)));
            return fixedString;
        }

        if (value < 1e3) {
            return pad(value);
        } else if (value < 1e6) {
            return `${pad(value / 1e3)}k`;
        } else if (value < 1e9) {
            return `${pad(value / 1e6)}m`;
        } else if (value < 1e12) {
            return `${pad(value / 1e9)}bn`;
        } else if (value < 1e15) {
            return `${pad(value / 1e12)}tn`;
        } else {
            return `${pad(value / 1e15)}qn`;
        }
    }

    static eventsTypes = [
        { id: 0, label: 'FILE_SYSTEM_EVENTS' },
        { id: 1, label: 'TCP_EVENTS' },
        { id: 2, label: 'REGISTRY_EVENTS' },
        { id: 3, label: 'HTTP_EVENTS' },
        { id: 4, label: 'DNS_EVENTS' },
    ];

    static incidentStatusIcon = new Map([
        [INCIDENT_STATUS.open, [IconTimeClock, 'error']],
        [INCIDENT_STATUS.inProgress, [IconEngineeringAutomation, 'warning']],
        [INCIDENT_STATUS.onHold, [IconStatusSuspended, 'warningBig']],
        [INCIDENT_STATUS.resolved, [IconStatusSafe, 'info']],
        [INCIDENT_STATUS.closed, [IconSelectionRadio, 'success']],
        [INCIDENT_STATUS.deleted, [IconStatusBlocked, 'disabled']],
        [INCIDENT_STATUS.invalid, [IconStatusBlocked, 'infoBig']],
    ]);

    static incidentSeverityIcon = new Map([
        [INCIDENT_SEVERITY.low, [IconArrowCaretDown, 'info']],
        [INCIDENT_SEVERITY.medium, [IconArrowCaretUp, 'warning']],
        [INCIDENT_SEVERITY.high, [IconArrowCaretUpAll, 'error']],
    ]);

    static bubbleClassByStatusOrSeverity = new Map([
        [0, 'bubble normal'],
        [1, 'bubble normal'],
        [2, 'bubble warning'],
        [3, 'bubble threat'],
    ]);

    static actionBubbleClassByStatusOrSeverity = new Map([
        [0, 'bubble normal action-normal'],
        [1, 'bubble normal action-normal'],
        [2, 'bubble warning action-warning'],
        [3, 'bubble threat action-threat '],
    ]);

    static getActionBubbleClass(statusOrSeverity) {
        return Filters.actionBubbleClassByStatusOrSeverity.has(statusOrSeverity)
            ? Filters.actionBubbleClassByStatusOrSeverity.get(statusOrSeverity)
            : Filters.actionBubbleClassByStatusOrSeverity.get(0);
    }

    static relatedObjectInfo = new Map([
        [
            window.serverInfo.constants.dbObject.alarm,
            {
                icon: <IconStatusError fill="currentcolor" />,
                addedLabel: 'DETECTION_ADDED',
                removedLabel: 'DETECTION_REMOVED',
            },
        ],
        [
            window.serverInfo.constants.dbObject.computer,
            {
                icon: <IconHardwareComputer fill="currentcolor" />,
                addedLabel: 'COMPUTER_ADDED',
                removedLabel: 'COMPUTER_REMOVED',
            },
        ],
        [
            window.serverInfo.constants.dbObject.module,
            {
                icon: <IconTerminalExecutable fill="currentcolor" />,
                addedLabel: 'MODULE_ADDED',
                removedLabel: 'MODULE_REMOVED',
            },
        ],
        [
            window.serverInfo.constants.dbObject.process,
            {
                icon: <IconMediaPlay fill="currentcolor" />,
                addedLabel: 'PROCESS_ADDED',
                removedLabel: 'PROCESS_REMOVED',
            },
        ],
        [window.serverInfo.constants.dbObject.user, { icon: <IconUserUser fill="currentcolor" /> }],
    ]);

    static NotificationStatus(value) {
        const NOTIFICATION_STATE = window.serverInfo.constants.notificationState;
        switch (value) {
            case NOTIFICATION_STATE.active:
                return i18n('ACTIVE');
            case NOTIFICATION_STATE.accepted:
                return i18n('ACCEPTED');
            case NOTIFICATION_STATE.rejected:
                return i18n('REJECTED');
            case NOTIFICATION_STATE.resolved:
                return i18n('RESOLVED');
            case NOTIFICATION_STATE.dontShow:
                return i18n('DONTSHOW');
            case NOTIFICATION_STATE.unknown:
                return i18n('UNKNOWN');
            default:
                return i18n('UNKNOWN');
        }
    }
}

export default Filters;
