import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { Modal, FormGroup, Button, toast } from 'components/ui-library';

import { EVENT, EventNames } from 'Services/Eventing';
import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';
import RemediationModalIncident from 'Bricks/remediation-modal-incident';
import { CommandingContextBasic, CommandingToolbar } from 'Bricks/Commanding';
import { createSimpleSelectionBody } from 'Bricks/Helpers';
import WebConsoleUserSelect from 'Bricks/web-console-user-select';
import { CommentEditor } from 'Bricks/comments';
import CommentDeleteWindow from 'Bricks/comments/commentDeleteWindow';
import createIncidentesCommanding from 'Views/incidents/commanding';
import { TagsEditModalWithBackend } from 'Bricks/tags-edit-modal';
import AccessGroupModal from 'Bricks/access-group-modal';
import { updateAccessGroupSubmenuOnSelectionChange } from 'Bricks/Helpers';

const ASSIGNEE_MODAL_ID = 'eid-assignee-modal';

function IncidentCommandingToolbar(props) {
    const incidentCommanding = useMemo(
        () =>
            createIncidentesCommanding({
                tagsEditOpen: tagsEditOpen,
                getIncidentInfo: getIncidentInfo,
                accessGroupSubmenuClbk: accessGroupSubmenuClbk,
                mode: props.mode,
                reload: reload,
                componentUuid: props.componentUuid,
                onAssigneeSelectOpen: assigneeSelectOpen,
                onAddComment: handleAddComment,
                onRemediate: onRemediate,
            }),
        []
    );

    function createCommanding(commanding) {
        return new CommandingContextBasic({
            ...commanding,
            commands: {
                ...incidentCommanding.commands,
                ...commanding?.commands,
            },
            toolbar: {
                ...(commanding?.toolbar || []),
                right: [...incidentCommanding.toolbar.right, ...(commanding?.toolbar?.right || [])],
                left: [...incidentCommanding?.toolbar.left, ...(commanding?.toolbar?.left || [])],
            },
        });
    }

    const [commanding, setCommanding] = useState(createCommanding(props.commanding));
    const [isLoading, setIsLoading] = useState(true);
    const [incidentDetails, setIncidentdDetails] = useState({});
    const [isAssigneeSelectOpen, setIsAssigneeSelectOpen] = useState(false);
    const [assignee, setAssignee] = useState(null);
    const [assigneeError, setAssigneeError] = useState('');
    const [isCommentEditorOpen, setIsCommentEditorOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [activeComment, setActiveComment] = useState({ commentId: 0, commentValue: '' });
    const [showRemediationModal, setShowRemediationModal] = useState(false);
    const [isTagsEditOpen, setIsTagsEditOpen] = useState(false);
    const [isAccessGroupModalOpen, setIsAccessGroupModalOpen] = useState(false);

    function tagsEditOpen() {
        setIsTagsEditOpen(true);
    }

    function assigneeSelectOpen() {
        setIsAssigneeSelectOpen(true);
    }

    function handleTagsEditClose() {
        setIsTagsEditOpen(false);
    }

    function accessGroupSubmenuClbk() {
        setIsAccessGroupModalOpen(true);
    }

    function handleAccessGroupModalClose() {
        setIsAccessGroupModalOpen(false);
    }

    function handleAccessGroupModal(selectedGroupId) {
        const requestBody = createSimpleSelectionBody([props.incidentId]);
        requestBody['accessGroupId'] = selectedGroupId;

        BACKEND.post('incidents/access_group', requestBody, props.componentUuid).success(reload).execute();
    }

    function getIncidentInfo() {
        return {
            incidentId: props.incidentId,
        };
    }

    function reload(notNotify) {
        setIsLoading(true);
        BACKEND.post(`incident/${props.incidentId}`, {}, props.componentUuid)
            .onStatus(404, () => {
                // hmmm not found
            })
            .success((incident) => {
                setIncidentdDetails(incident);
            })
            .always(() => {
                !notNotify && EVENT.publish(EventNames.INCIDENT_MODIFIED_EVENT);
                props.reload?.();
                setIsLoading(false);
            })
            .execute();
    }

    useEffect(() => {
        props.setReloadLeftWing?.(reload);
        reload(true);
    }, []);

    function isRemoved() {
        return !incidentDetails || incidentDetails.status === window.serverInfo.constants.incidentStatus.deleted;
    }

    function updateBackend(uri, body = {}) {
        if (!isRemoved()) {
            return BACKEND.post(uri, { ...createSimpleSelectionBody([props.incidentId]), ...body }, props.componentUuid)
                .success(reload)
                .execute();
        }
    }

    function handleAddComment() {
        if (!isRemoved()) {
            setActiveComment({ commentId: 0, commentValue: '' });
            setIsCommentEditorOpen(true);
        }
    }

    function handleDeleteWindowClose() {
        if (!isRemoved()) {
            setIsDeleteOpen(false);
        }
    }

    function validateAssignee(newAssignee) {
        if (newAssignee) {
            setAssigneeError('');
            return true;
        } else {
            setAssigneeError('ASSIGNEE_IS_REQUIRED');
            return false;
        }
    }

    function onAssigneeChange(assignee) {
        validateAssignee(assignee);
        setAssignee(assignee);
    }

    function assignToIncident(event) {
        if (validateAssignee(assignee)) {
            closeSelectAssignee();
            updateBackend(`incidents/assignee/${assignee.value}`).then(() => {
                EVENT.publish(EventNames.INCIDENT_MODIFIED_EVENT);
                toast.success(i18n('SUCCESSFULLY_ASSIGNED_INCIDENT'), {
                    autoClose: 3000,
                });
            });
        }
    }

    function closeSelectAssignee() {
        setIsAssigneeSelectOpen(false);
        setAssignee(null);
        setAssigneeError('');
    }

    function closeCommentEditor() {
        setIsCommentEditorOpen(false);
    }

    function onRemediate() {
        setShowRemediationModal(true);
    }

    function handleRemediationModaleClose() {
        setShowRemediationModal(false);
    }

    useEffect(() => {
        const commanding = createCommanding(props.commanding);

        incidentDetails &&
            updateAccessGroupSubmenuOnSelectionChange(commanding, [
                {
                    accessGroupId: incidentDetails.accessGroup,
                    accessGroupPath: incidentDetails.accessGroupPath,
                    isInternal: incidentDetails.isInternal,
                },
            ]);

        const componentCommandingKeys = Object.keys(props.commanding?.commands || {});
        if (props.isLoading) {
            commanding.disable(...componentCommandingKeys);
        } else {
            commanding.enable(...componentCommandingKeys);
        }

        const incidentCommandingKeys = Object.keys(incidentCommanding?.commands || {});
        if (isRemoved() || isLoading) {
            commanding.disable(...incidentCommandingKeys);
            commanding.setTooltip('CANNOT_EXECUTE_ACTION_ON_REMOVED_INCIDENT', incidentCommandingKeys);
        } else {
            commanding.enable(...incidentCommandingKeys);
        }

        setCommanding(commanding);
    }, [props.commanding, props.isLoading, isLoading, incidentDetails]);

    return (
        <Fragment>
            <CommandingToolbar commanding={commanding} groupingOptionId={props.groupingOptionId} />
            <Modal
                id={ASSIGNEE_MODAL_ID}
                show={isAssigneeSelectOpen}
                title="ASSIGNEE"
                onDispose={closeSelectAssignee}
                buttons={[
                    <Button
                        id="eid-incidents-assignee-button-confirm"
                        type="primary"
                        text="CONFIRM"
                        onClick={assignToIncident}
                        disabled={Boolean(assigneeError)}
                    />,
                    <Button
                        id="eid-incidents-assignee-button-cancel"
                        type="secondary"
                        text="CANCEL"
                        onClick={closeSelectAssignee}
                    />,
                ]}
            >
                <FormGroup
                    labelText="ASSIGNEE"
                    hasError={Boolean(assigneeError)}
                    validationText={assigneeError}
                    content={
                        <WebConsoleUserSelect
                            modalId={ASSIGNEE_MODAL_ID}
                            value={assignee}
                            onChange={onAssigneeChange}
                        />
                    }
                />
            </Modal>
            {props.incidentId !== undefined && (
                <Fragment>
                    <CommentEditor
                        isOpen={isCommentEditorOpen}
                        activeComment={activeComment}
                        objectId={props.incidentId}
                        objectType={window.serverInfo.constants.dbObject.incident}
                        onClose={closeCommentEditor}
                        onChange={reload}
                    />
                    <CommentDeleteWindow
                        isOpen={isDeleteOpen}
                        commentId={activeComment.commentId}
                        objectId={props.incidentId}
                        objectType={window.serverInfo.constants.dbObject.incident}
                        onClose={handleDeleteWindowClose}
                        onChange={reload}
                    />
                    <RemediationModalIncident
                        show={showRemediationModal}
                        close={handleRemediationModaleClose}
                        incidentId={props.incidentId}
                    />
                    <TagsEditModalWithBackend
                        tagsObjectId={props.incidentId}
                        tagsObjectType={window.serverInfo.constants.dbObject.incident}
                        open={isTagsEditOpen}
                        onClose={handleTagsEditClose}
                        onChange={reload}
                    />
                    <AccessGroupModal
                        show={isAccessGroupModalOpen}
                        onChange={handleAccessGroupModal}
                        close={handleAccessGroupModalClose}
                        preselectedGroupId={incidentDetails?.accessGroup}
                    />
                </Fragment>
            )}
        </Fragment>
    );
}

export default withBackend(IncidentCommandingToolbar);
