import i18n from 'Services/i18n';
import { toast } from 'components/ui-library';

const REQUEST_ERROR_TOAST_OPTIONS = {
    autoClose: false,
};

function parseErrorResponse(response) {
    const jsonType = 'application/json' === response.headers.get('content-type') ? true : false;
    return jsonType ? response.json() : response.text();
}

function handleRemediationBadRequest(_method, _url, response, actionName) {
    parseErrorResponse(response).then((errorResponse) => {
        const toastTitle = `${i18n(actionName)}: ${i18n('BAD_REQUEST')}`;
        const toastMessage = () => (
            <span>
                {i18n(errorResponse.message)} <br />
                {i18n('IF_ERROR_PERSISTS_PLEASE_CONSULT_HELP_OR_CONTACT_CUSTOMER_CARE')}
            </span>
        );
        toast.error(toastMessage, REQUEST_ERROR_TOAST_OPTIONS, toastTitle);
    });
    return Promise.reject(response);
}

function handleRemediationInternalServerError(_method, _url, response, actionName) {
    parseErrorResponse(response).then((_) => {
        const toastTitle = `${i18n(actionName)}: ${i18n('INTERNAL_SERVER_ERROR')}`;
        const toastMessage = i18n('IF_ERROR_PERSISTS_PLEASE_CONSULT_HELP_OR_CONTACT_CUSTOMER_CARE');
        toast.error(toastMessage, REQUEST_ERROR_TOAST_OPTIONS, toastTitle);
    });
    return Promise.reject(response);
}

function requestFailed() {
    toast.error(
        i18n('FAILED_TO_COMMUNICATE_WITH_SERVER_WHICH_MAY_BE_DOWN_OR_THERE_COULD_BE_NETWORK_CONNECTION_PROBLEM'),
        REQUEST_ERROR_TOAST_OPTIONS
    );
}

export { handleRemediationBadRequest, handleRemediationInternalServerError, requestFailed };
