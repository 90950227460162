import PropTypes from 'prop-types';
import classnames from 'classnames';

import i18n from 'Services/i18n';

function HighlightBlankLayout(props) {
    const isValueKnown = typeof props.value === 'string' && props.value !== '';
    const isValueUnknown = props.value === null;
    const isValueNone = props.value === '';

    const className = classnames('ei-cell-overflow', {
        'ei-valign-middle': props.alignCell,
        'ei-text-uppercase': props.uppercase && isValueKnown,
        'ei-monospace-font': props.monospace && isValueKnown,
        'ei-bleak-text': props.bleak || isValueUnknown || isValueNone,
        'ei-white-space-preserve': props.keepWhiteSpaces,
    });

    return <span className={className}>{i18n(isValueUnknown ? 'UNKNOWN' : isValueNone ? 'NONE' : props.value)}</span>;
}

HighlightBlankLayout.propTypes = {
    alignCell: PropTypes.bool,
    bleak: PropTypes.bool,
    keepWhiteSpaces: PropTypes.bool,
    monospace: PropTypes.bool,
    value: PropTypes.any,
    uppercase: PropTypes.bool,
};

HighlightBlankLayout.defaultProps = {
    alignCell: false,
    bleak: false,
    keepWhiteSpaces: false,
    monospace: false,
    uppercase: false,
};

export default HighlightBlankLayout;
