import { IconLetterI, IconArrowDown, IconArrowUp } from '@uilib/business-components/index';
import { Fragment } from 'react';
import { RightSidebar } from 'components/ui-library';
import IconButton from 'Bricks/icon-button';
import classnames from 'classnames';
import './preview-sidebar.scss';

function PreviewSidebar(props) {
    function onInfoClick(event) {
        props.onInfoClick(event, props.data.entity, null);
    }

    const { PreviewComponent, HeaderComponent } = props;
    return (
        <RightSidebar
            className="preview-sidebar"
            headerType="small"
            headline={
                <Fragment>
                    <div className={classnames('clickable', 'table-cell', 'ei-cell-overflow')} onClick={onInfoClick}>
                        <HeaderComponent
                            entity={props.data.entity}
                            value={props.data.entity.name || props.data.entity.description}
                        />
                    </div>
                    <IconButton
                        className="icon-button-sidebar"
                        icon={<IconLetterI fill="currentcolor" />}
                        onClick={onInfoClick}
                    />
                    <IconButton
                        className="icon-button-sidebar"
                        icon={<IconArrowDown fill="currentcolor" />}
                        onClick={props.previewNext}
                        disabled={props.previewedRow.isLast}
                    />
                    <IconButton
                        className="icon-button-sidebar"
                        icon={<IconArrowUp fill="currentcolor" />}
                        onClick={props.previewPrevious}
                        disabled={props.previewedRow.isFirst}
                    />
                </Fragment>
            }
            onClose={props.previewClose}
        >
            <PreviewComponent entity={props.data.entity} reload={props.reload} />
        </RightSidebar>
    );
}

export default PreviewSidebar;
