import { useRef, useState, useReducer } from 'react';

import { Button, FileUpload, ConfirmationModal } from 'components/ui-library';

import i18n from 'Services/i18n';

import './index.css';

const MAX_ALLOWED_FILE_SIZE = 10 * 1024 * 1024; // 10MB should be enough for everyone.
const UNLIMITED_FILES = Infinity;

function FileUploader(props) {
    const fileUploadRef = useRef(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    const [fileUploadkey, forceUpdateFileUpload] = useReducer((key) => key + 1, 0);

    // base64 encoded ascii to ucs-2 string
    // origin: https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa#Unicode_strings
    function atou(str) {
        return decodeURIComponent(escape(window.atob(str)));
    }

    function handleFileLoadChange(fileInfo, fileContent) {
        const FILE_BASE_64 = fileContent.substr(fileContent.indexOf(',') + 1);
        props.onChange(fileInfo.name, props.base64 ? FILE_BASE_64 : atou(FILE_BASE_64));

        fileUploadRef.current.removeFile(0);
    }

    function handleMessages(message) {
        let text = '';
        switch (message) {
            case 'reached_file_limit':
                // Not a problem actually.
                return;
            case 'wrong_size':
                text = 'FILE_SIZE_LIMIT_EXCEEDED_MAX_10_MB';
                break;
            case 'wrong_type':
                text = i18n('WRONG_FILE_TYPE_TYPE_ACCEPTED_ONLY', { accept: props.accept });
                break;
            case 'too_many_files':
                text = 'TOO_MANY_FILES';
                break;
            default:
                text = 'PLEASE_TRY_AGAIN_WITH_CORRECT_FILE';
                break;
        }
        setModalText(text);
        setModalIsOpen(true);
    }

    function handleModalDispose(message) {
        /**
         * HACK: in custom upload button it is impossible to set internal reject state, therefore complete rerender is necessary
         */
        forceUpdateFileUpload();

        setModalIsOpen(false);
    }

    return (
        <div className="ei-file-uploader">
            <Button text={props.text} disabled={props.disabled} type="secondary" />
            <FileUpload
                key={fileUploadkey}
                ref={fileUploadRef}
                className="ei-file-uploader__core"
                disablePreview={true}
                placeholderIcon={null}
                accept={props.accept}
                onFileLoad={handleFileLoadChange}
                disabled={props.disabled}
                maxFiles={props.multiple ? UNLIMITED_FILES : 1}
                maxSize={MAX_ALLOWED_FILE_SIZE}
                rejectionMessages={handleMessages}
            />
            <ConfirmationModal
                show={modalIsOpen}
                type="warning"
                message="IMPORTED_FILE_WAS_REJECTED"
                text={modalText}
                onDispose={handleModalDispose}
                buttons={[
                    <Button id="eid-rules-import-ok-button" type="primary" text="OK" onClick={handleModalDispose} />,
                ]}
            />
        </div>
    );
}

export default FileUploader;
