import { SummaryItem } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

function SummaryItemWrapper(props) {
    return <SummaryItem {...props} content={i18n(props.content)} labelText={i18n(props.label)} />;
}

function EmptyValueWrapper(props) {
    return <SummaryItem.EmptyValue children={i18n(props.children)} />;
}

SummaryItemWrapper.EmptyValue = EmptyValueWrapper;

export default SummaryItemWrapper;
