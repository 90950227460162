import React from 'react';
import { Tooltip } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

const TooltipWrapper = React.forwardRef((props, ref) => {
    return <Tooltip {...props} ref={ref} tooltipContent={i18n(props.tooltipContent)} />;
});

export default TooltipWrapper;
