import classnames from 'classnames';
import { Text } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

export default function TextWrapper(props) {
    return (
        <Text
            {...props}
            color={props.color}
            className={classnames({ [props.className]: typeof props.className === 'string' })}
            noMargin={props.noMargin !== undefined ? props.noMargin : true}
            children={i18n(props.children)}
        />
    );
}
