import { IconHardwareComputer, IconTerminalExecutable, IconMediaPlay } from '@uilib/business-components/index';
import { isValidElement } from 'react';

import { BACKEND } from 'Services/backend';
import { EVENT, EventNames } from 'Services/Eventing';
import { isValidDbId } from '../../Bricks/Helpers';
import { getGroupIcon } from 'Bricks/groups-tree';
import EmptyIcon from 'Bricks/empty-icon';

const CRUMB_ICONS = {
    PLAIN_CRUMB_ICON: <EmptyIcon fill="currentcolor" />,
    MACHINE_CRUMB_ICON: <IconHardwareComputer fill="currentcolor" />,
    MODULE_CRUMB_ICON: <IconTerminalExecutable fill="currentcolor" />,
    PROCESS_CRUMB_ICON: <IconMediaPlay fill="currentcolor" />,
};

const CSS_STATUS = ['normal', 'normal', 'warning', 'threat'];

class NavigationBarService {
    constructor() {
        /** Chain of "promises" objects of React.
         *
         *  Due to nature of asynchronical connection, the order of responses from Backend is not known, thus order of
         *  expanding array is not known. Thus, a way to be sure that one response will be back before other is needed.
         *  navigationBar variable is used to guarantee it.
         */
        this.workersChain = Promise.resolve();

        /** Array of objects. Each of objects should have following values:
         *  - getCssClass - function returning string, getter of name of class used to display particular crumb
         *  - getLink - function returning string, getter of link to which page should go after clicking on crumb (navigationBar
         *    field is used only if crumb is clickable)
         *  - getText - function returning string, getter of title of particular crumb
         *  - isClickable - function returning bool, returns true if breadcrumb is clickable
         */
        this.breadcrumbsParts = [];

        this.title = '';
        this.titleItem = null;
        this.rightAlignedItems = [];
    }

    init() {
        this.componentUuid = BACKEND.init();
    }

    clear() {
        BACKEND.clear(this.componentUuid);
    }

    publishNavigationBarChangedEvent = () => {
        EVENT.publish(EventNames.NAVIGATION_BAR_CHANGED_EVENT, this);
    };

    promiseChainErrorHandler = (error) => {
        return Promise.reject(error);
    };

    promiseErrorHandler = (error) => {
        console.error(error);
    };

    clearRightAlignedItems = () => {
        this.rightAlignedItems = [];
        this.publishNavigationBarChangedEvent();
        return this;
    };

    addRightAlignedItem = (item) => {
        if (!isValidElement(item)) {
            throw new Error('NavigationBar::addRightAlignedItem - parameter must be a valid react item.');
        }
        this.rightAlignedItems.push(item);
        this.publishNavigationBarChangedEvent();
        return this;
    };

    updatePageTitle = () => {
        document.titleManager.item = this.breadcrumbsParts[this.breadcrumbsParts.length - 1]?.text;
        document.titleManager.page = this.title;
    };

    setTitle = (title, titleItem = null, withoutPageTitle = false) => {
        if (typeof title !== 'string') {
            throw new Error('NavigationBar::setTitle - parameter must be a string.');
        }

        this.workersChain = this.workersChain.then(() => {
            this.title = title;
            this.titleItem = titleItem;
            !withoutPageTitle && this.updatePageTitle();
            this.publishNavigationBarChangedEvent();
        }, this.promiseErrorHandler);

        return this;
    };

    getBreadcrumbsParts = () => {
        return new Promise((resolve) => {
            this.workersChain = this.workersChain.then(() => resolve(this.breadcrumbsParts), this.promiseErrorHandler);
        });
    };

    clearBreadcrumbs = () => {
        this.workersChain = this.workersChain.then(() => {
            this.breadcrumbsParts = [];
            this.title = '';
            this.titleItem = null;
            this.publishNavigationBarChangedEvent();
        }, this.promiseErrorHandler);

        return this;
    };

    _addCrumb = (text, icon, url, status, integrityLevel) => {
        this.breadcrumbsParts.push({
            text: text,
            icon: icon,
            url: url,
            status: status,
            integrityLevel: integrityLevel,
        });

        this.publishNavigationBarChangedEvent();
    };

    addCrumbsOfGroupOfMachines = (idOfGroup) => {
        /** Add multiple crumbs, related to hierarchy of groups, under which is currently viewed machine or group.
         *
         * \param[in] idOfGroup string, ID of group, for which crumbs are added to breadcrumb bar.
         */
        if (isValidDbId(idOfGroup)) {
            this.workersChain = this.workersChain.then(() => {
                return BACKEND.get(`groupPath/${idOfGroup}`, this.componentUuid)
                    .success((response) => {
                        if (response.path === undefined) {
                            // \todo Report error after discussing how errors should be handled.
                            alert('Unable to get datapath for machine with ID: ' + idOfGroup);
                            return;
                        }

                        let status = CSS_STATUS[0];

                        // Standard loop (instead to loop over array) is used to keep order.
                        const lastIndex = response.path.length - 1;
                        for (let levelIndex = 0; lastIndex !== levelIndex; ++levelIndex) {
                            // Inserting new element in array.
                            // Navigation bar allows to have other crumbs after crumbs of group of machines. But CSS does not
                            // have "last-of-class", only "last-of-type" (i.e. can target "p", but cannot target ".class").
                            // Thus, CSS selectors cannot be used to uniquely select last element of crumbs of machines. Thus,
                            // class has to be set manually.
                            this._addCrumb(
                                response.path[levelIndex].name,
                                getGroupIcon(response.path[levelIndex].locationType),
                                undefined,
                                status
                            );
                        }

                        // Last element is clickable.
                        const level = response.path[lastIndex];
                        status = CSS_STATUS[response.machineStatus || 0];
                        const url = {
                            state: 'console.computer.details',
                            params: { computerId: level.id },
                        };
                        this._addCrumb(level.name, CRUMB_ICONS.MACHINE_CRUMB_ICON, url, status);
                        this.publishNavigationBarChangedEvent();
                    })
                    .execute();
            }, this.promiseChainErrorHandler);
        }
        return this;
    };

    refreshCrumbsOfGroupOfMachines = (idOfGroup) => {
        if (isValidDbId(idOfGroup)) {
            this.workersChain = this.workersChain.then(() => {
                return BACKEND.get(`groupPath/${idOfGroup}`, this.componentUuid)
                    .success((response) => {
                        const lastIndex = response.path.length - 1;
                        const newCssStatus = CSS_STATUS[response.machineStatus || 0];
                        if (this.breadcrumbsParts[lastIndex].status !== newCssStatus) {
                            this.breadcrumbsParts[lastIndex].status = newCssStatus;
                            this.publishNavigationBarChangedEvent();
                        }
                    })
                    .execute();
            }, this.promiseChainErrorHandler);
        }
        return this;
    };

    addModuleCrumb = (moduleId) => {
        if (isValidDbId(moduleId)) {
            this.workersChain = this.workersChain.then(() => {
                BACKEND.post(`module/${moduleId}`, { requiredFields: ['status', 'name'] }, this.componentUuid)
                    .success((response) => {
                        const status = CSS_STATUS[response.status || 0];
                        const name = response.name;
                        const url = {
                            state: 'console.module.details',
                            params: { moduleId: moduleId },
                        };
                        this._addCrumb(name, CRUMB_ICONS.MODULE_CRUMB_ICON, url, status);
                        this.publishNavigationBarChangedEvent();
                    })
                    .execute();
            }, this.promiseChainErrorHandler);
        }
        return this;
    };

    addPlainCrumb = (title) => {
        this.workersChain = this.workersChain.then(() => {
            this._addCrumb(title, CRUMB_ICONS.PLAIN_CRUMB_ICON, undefined, CSS_STATUS[0]);
            this.publishNavigationBarChangedEvent();
        }, this.promiseErrorHandler);

        return this;
    };

    addProcessCrumb = (processId) => {
        if (isValidDbId(processId)) {
            this.workersChain = this.workersChain.then(() => {
                BACKEND.post(
                    `process/${processId}`,
                    { requiredFields: ['status', 'moduleName', 'integrityLevel'] },
                    this.componentUuid
                )
                    .success((response) => {
                        const status = CSS_STATUS[response.status || 0];
                        const url = {
                            state: 'console.process.details',
                            params: { processId },
                        };
                        this._addCrumb(
                            response.moduleName,
                            CRUMB_ICONS.PROCESS_CRUMB_ICON,
                            url,
                            status,
                            response.integrityLevel
                        );
                        this.publishNavigationBarChangedEvent();
                    })
                    .execute();
            }, this.promiseChainErrorHandler);
        }
        return this;
    };
}

const navigationBarService = new NavigationBarService();

export default navigationBarService;
