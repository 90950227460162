import { Wizard } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

function WizardWrapper(props) {
    return (
        <Wizard
            {...props}
            buttons={props.buttons.map((button) => ({
                ...button,
                text: i18n(button.text),
            }))}
        />
    );
}

WizardWrapper.PageController = Wizard.PageController;
WizardWrapper.Validation = Wizard.Validation;

export default WizardWrapper;
