import { Spacer } from 'components/ui-library';
import PropTypes from 'prop-types';

import './index.scss';

function Separator(props) {
    return (
        <Spacer type={props.spacing}>
            <div className="ei-separator"></div>
        </Spacer>
    );
}

Separator.propTypes = {
    spacing: PropTypes.string,
};

Separator.defaultProps = {
    spacing: 'm-0 p-0',
};

export default Separator;
