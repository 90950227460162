import moment from 'moment';
import { throttle } from 'lodash';

import { EVENT, EventNames } from 'Services/Eventing';
import { BACKEND } from 'Services/backend';

const TOKEN_UPDATE_KEY = 'tokenUpdate';
const TOKEN_UPDATE_ID = moment().valueOf();
const HEARTBEAT_TIMEOUT = 30000;

function parseToken(token) {
    const payloadBase64url = token.split('.')[1];
    const payloadBase64 = payloadBase64url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(payloadBase64));
}

class Authorization {
    #timeout;
    #uuid;
    #wasTfaUsed;

    get token() {
        return window.sessionStorage.token;
    }

    get timeout() {
        return this.#timeout || moment();
    }

    get userUuid() {
        return this.#uuid;
    }

    get isAuthenticated() {
        if (this.#timeout === undefined) {
            if (!this.token) {
                return false;
            }
            const parsedToken = parseToken(this.token);
            this.#timeout = parsedToken.exp === 0 ? null : moment.unix(parsedToken.exp);
        }

        if (this.#timeout === null) {
            return true;
        }

        return this.#timeout - moment() > 0;
    }

    get wasTfaUsed() {
        return this.#wasTfaUsed;
    }

    set wasTfaUsed(value) {
        this.#wasTfaUsed = value;
    }

    init = () => {
        this.componentUuid = BACKEND.init();
        this.#timeout = undefined;
        this.#uuid = undefined;
        this.#wasTfaUsed = false;

        this.authorizationTokenUnsubscribe = EVENT.subscribe(EventNames.AUTHORIZATION_TOKEN_EVENT, (event, token) => {
            window.sessionStorage.token = token;
            if (token) {
                const { exp, sub, amr } = parseToken(token);
                this.#uuid = sub;
                this.#timeout = exp === 0 || window.serverInfo.useIdentityServer ? null : moment.unix(exp);
                if (Array.isArray(amr)) {
                    this.#wasTfaUsed = amr[0] === 'tfa';
                }
            }
            window.localStorage.setItem(TOKEN_UPDATE_KEY, JSON.stringify({ id: TOKEN_UPDATE_ID, token: token }));
            window.localStorage.removeItem(TOKEN_UPDATE_KEY);
        });

        if (window.addEventListener) {
            window.addEventListener('storage', this.onStorageUpdate, false);
        }

        this.heartbeat = throttle(this.#heartbeat, HEARTBEAT_TIMEOUT);
    };

    clear = () => {
        this.authorizationTokenUnsubscribe();
        BACKEND.clear(this.componentUuid);
        if (window.removeEventListener) {
            window.removeEventListener('storage', this.onStorageUpdate, false);
        }
    };

    onStorageUpdate = (event) => {
        if (event.newValue && event.key === TOKEN_UPDATE_KEY) {
            const EVENT_VALUE = JSON.parse(event.newValue) || {};
            if (EVENT_VALUE.id !== TOKEN_UPDATE_ID && EVENT_VALUE.token) {
                window.sessionStorage.token = EVENT_VALUE.token;
                const { exp } = parseToken(EVENT_VALUE.token);
                this.#timeout = exp === 0 ? null : moment.unix(exp);
            }
        }
    };

    removeToken = () => {
        window.sessionStorage.removeItem('token');
        this.#timeout = undefined;
    };

    expireToken = () => {
        this.#timeout = moment().subtract(1, 'seconds');
    };

    #heartbeat = () => {
        BACKEND.post('heartbeat', {}, this.componentUuid).execute();
    };
}

const authorization = new Authorization();

export default authorization;
