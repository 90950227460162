import { IconSymbolHelp, IconMediaPlay } from '@uilib/business-components/index';
import { Tooltip, Link, FlexContainer, Text, Checkbox, Spacer, List, Modal, Button, Tag } from 'components/ui-library';
import i18n from 'Services/i18n';
import Filters from 'Services/Filters';
import React, { Fragment } from 'react';
import colors from 'eset-ui-colors';

const REMEDIATION_PROCESS_TYPES = {
    current: 0,
    parent: 1,
    module: 2,
    none: 3,
    invalid: 4,
    unfetched: 5,
};

function remediationProcessTypeFromString(processString) {
    if (processString === 'current') {
        return REMEDIATION_PROCESS_TYPES.current;
    } else if (processString === 'parent') {
        return REMEDIATION_PROCESS_TYPES.parent;
    } else if (processString === 'module') {
        return REMEDIATION_PROCESS_TYPES.module;
    } else if (processString === 'none') {
        return REMEDIATION_PROCESS_TYPES.none;
    } else {
        return REMEDIATION_PROCESS_TYPES.invalid;
    }
}

function RemediationCheckbox(props) {
    const action = props.action;
    return (
        <Spacer type="py-1">
            <FlexContainer direction="row" justify="space-between" align="center" className="flex-nowrap">
                <Checkbox
                    disabled={props.shouldActionBeDisabled(action)}
                    checked={props.isChecked(action.value)}
                    onChange={() => props.onChange(action.value)}
                    text={i18n(action.name)}
                    testDataLabel="eid-checkbox"
                />
                <Tooltip placement="auto" tooltipContent={action.tooltip}>
                    <IconSymbolHelp fill={colors.iconColor} />
                </Tooltip>
            </FlexContainer>
        </Spacer>
    );
}

function RemediationCheckboxGroup(props) {
    return (
        <FlexContainer direction="column" justify="center">
            {props.actions.filter(props.shouldActionBeDisplayed).map((action) => (
                <RemediationCheckbox
                    key={action.value}
                    action={action}
                    shouldActionBeDisabled={props.shouldActionBeDisabled}
                    isChecked={props.isChecked}
                    onChange={props.onChange}
                />
            ))}
        </FlexContainer>
    );
}

function RemediationActionBubbleLink(props) {
    return (
        <Link onClick={props.onClick} className={Filters.getActionBubbleClass(props.severity)}>
            <FlexContainer
                direction="row"
                align={props.align || 'center'}
                justify={props.justify || 'center'}
                className="ei-tags--cell"
            >
                {props.icon &&
                    React.cloneElement(props.icon, {
                        className: 'default-cell-icon',
                    })}
                <span>{props.text}</span>
            </FlexContainer>
        </Link>
    );
}

function RemediationActionsList(props) {
    return (
        <Fragment>
            <Spacer type="mb-4">
                <Text>THE_FOLLOWING_REMEDIATION_ACTIONS_WILL_BE_APPLIED_ACROSS_THE_NETWORK</Text>
            </Spacer>
            <List>
                {props.actions.map((action) => (
                    <List.Item key={action.value}>
                        <FlexContainer direction="row" justify="space-between" align="center">
                            <Text fontWeight="bold">{action.name}</Text>
                            <Tooltip placement="auto" tooltipContent={action.tooltip}>
                                <IconSymbolHelp fill={colors.iconColor} />
                            </Tooltip>
                        </FlexContainer>
                    </List.Item>
                ))}
            </List>
        </Fragment>
    );
}

const REMEDIATION_CONFIRMATION_TYPE = {
    BLOCK: 'BLOCK',
    DANGER: 'DANGER',
    DEFAULT: 'DEFAULT',
};

function TrustedExecutablesWarning(props) {
    return (
        <Fragment>
            <Spacer type="mb-4">
                <Text>THE_SELECTED_EXECUTABLE_IS_CONSIDERED_TO_HAVE_A_HIGH_REPUTATION_ON_ESET_LIVEGRID</Text>
            </Spacer>
            <Spacer type="mb-4">
                <Text fontWeight="bold">
                    THE_EXECUTABLE_IS_LIKELY_TO_BE_SAFE_AND_BLOCKING_IT_COULD_CAUSE_PROBLEMS_ON_AFFECTED_DEVICES
                </Text>
            </Spacer>
        </Fragment>
    );
}

function getRemediationConfirmationButtons(type, { onSubmit, disableSubmit, onClose }) {
    switch (type) {
        case REMEDIATION_CONFIRMATION_TYPE.BLOCK:
            return [
                <Button type="primary" text="BLOCK" onClick={onSubmit} disabled={disableSubmit} />,
                <Button type="secondary" text="CANCEL" onClick={onClose} />,
            ];
        case REMEDIATION_CONFIRMATION_TYPE.DANGER:
            return [
                <Button type="secondary" text="BLOCK" onClick={onSubmit} disabled={disableSubmit} />,
                <Button type="primary" text="CANCEL" onClick={onClose} />,
            ];
        case REMEDIATION_CONFIRMATION_TYPE.DEFAULT:
        default:
            return [
                <Button type="primary" text="REMEDIATE" onClick={onSubmit} disabled={disableSubmit} />,
                <Button type="secondary" text="CANCEL" onClick={onClose} />,
            ];
    }
}

function getRemediationConfirmationTitle(type) {
    switch (type) {
        case REMEDIATION_CONFIRMATION_TYPE.BLOCK:
            return 'BLOCK_EXECUTABLE';
        case REMEDIATION_CONFIRMATION_TYPE.DANGER:
            return 'BLOCK_TRUSTED_EXECUTABLE';
        case REMEDIATION_CONFIRMATION_TYPE.DEFAULT:
        default:
            return 'CONFIRM_REMEDIATION_ACTIONS';
    }
}

function RemediationConfirmation(props) {
    return props.actions?.length > 0 ? (
        <Fragment>
            {props.type === REMEDIATION_CONFIRMATION_TYPE.DANGER && <TrustedExecutablesWarning />}
            <RemediationActionsList actions={props.actions} />
        </Fragment>
    ) : (
        <Spacer type="my-4">
            <Text fontWeight="bold">NO_ITEM_SELECTED_FOR_REMEDIATION</Text>
        </Spacer>
    );
}

function RemediationConfirmationModal(props) {
    return (
        <Modal
            show={props.show}
            onDispose={props.onClose}
            type={props.type === REMEDIATION_CONFIRMATION_TYPE.DANGER ? 'error' : 'warning'}
            title={getRemediationConfirmationTitle(props.type)}
            buttons={getRemediationConfirmationButtons(props.type, {
                onSubmit: props.onSubmit,
                onClose: props.onClose,
            })}
        >
            <div style={{ minHeight: '150px' }}>
                <RemediationConfirmation actions={props.actionsToExecute} type={props.type} />
            </div>
        </Modal>
    );
}

function AlarmActions(props) {
    return (
        <FlexContainer className={props.cell ? 'ei-tags--cell' : ''}>
            {props.value > 0 && (
                <Tag
                    icon={<IconMediaPlay fill="currentcolor" />}
                    key={props.value}
                    color="red"
                    border={true}
                    title={true}
                >
                    {Filters.alarmAction(props.value)}
                </Tag>
            )}
        </FlexContainer>
    );
}

export {
    REMEDIATION_PROCESS_TYPES,
    REMEDIATION_CONFIRMATION_TYPE,
    remediationProcessTypeFromString,
    RemediationCheckboxGroup,
    RemediationActionBubbleLink,
    RemediationConfirmationModal,
    RemediationActionsList,
    RemediationConfirmation,
    getRemediationConfirmationTitle,
    getRemediationConfirmationButtons,
    TrustedExecutablesWarning,
    AlarmActions,
};
