import React from 'react';
import { Dropdown } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

const DropdownWrapper = React.forwardRef((props, ref) => {
    return (
        <Dropdown
            {...props}
            testDataLabel="eid-dropdown"
            ref={ref}
            title={i18n(props.title)}
            content={
                props.content
                    ? props.content.map(({ header, items }) => ({
                          header: i18n(header),
                          items: items.map((item) => ({
                              ...item,
                              title: i18n(item.title),
                          })),
                      }))
                    : null
            }
        />
    );
});

export default DropdownWrapper;
