import { Tab } from '@uilib/business-components/index';

import i18n from 'Services/i18n';

function TabWrapper(props) {
    return <Tab {...props} />;
}

function TabHeaderWrapper(props) {
    return (
        <Tab.Header
            {...props}
            tabs={props.tabs.map((tab) => ({
                ...tab,
                text: i18n(tab.text),
                tooltip: i18n(tab.tooltip),
            }))}
        />
    );
}

TabWrapper.Header = TabHeaderWrapper;
TabWrapper.Container = Tab.Container;
TabWrapper.Content = Tab.Content;
TabWrapper.Pane = Tab.Pane;

export default TabWrapper;
