import { IconDataAddRow } from '@uilib/business-components/index';
import { useState, Fragment, useContext } from 'react';
import { withBackend } from 'Services/backend';

import { List } from 'Bricks/List';

import { Link } from 'components/ui-library';

import CommentEditor from './commentEditor';
import CommentDeleteWindow from './commentDeleteWindow';
import CommentList from './commentList';

import { AppContext } from 'Services/AppContext';
import colors from 'eset-ui-colors';

function Comments(props) {
    const [editorOpen, setEditorOpen] = useState(false);
    const [activeComment, setActiveComment] = useState({ commentId: 0, commentValue: '' });
    const [deleteOpen, setDeleteOpen] = useState(false);
    const { username } = useContext(AppContext);

    function handleEditorClose() {
        setEditorOpen(false);
    }

    function handleDeleteWindowClose() {
        setDeleteOpen(false);
    }

    function handleAddComment() {
        setActiveComment({ commentId: 0, commentValue: '' });
        setEditorOpen(true);
    }

    function handleEditComment(id, oldComment) {
        setActiveComment({ commentId: id, commentValue: oldComment });
        setEditorOpen(true);
    }

    function handleDeleteComment(id) {
        setActiveComment({ commentId: id, commentValue: '' });
        setDeleteOpen(true);
    }

    return (
        <Fragment>
            <CommentList
                comments={props.data}
                handleEditComment={handleEditComment}
                handleDeleteComment={handleDeleteComment}
                username={username}
                disabled={props.disabled}
            />
            <List.Item label="">
                <Link
                    onClick={handleAddComment}
                    icon={<IconDataAddRow fill={colors.primary} disabled={props.disabled} />}
                    text="ADD_COMMENT"
                    disabled={props.disabled}
                />
            </List.Item>
            <CommentEditor
                isOpen={editorOpen}
                objectId={props.objectId}
                objectType={props.objectType}
                onClose={handleEditorClose}
                onChange={props.onChange}
                activeComment={activeComment}
                additionalDataForRequestBody={props.additionalDataForRequestBody}
            />
            <CommentDeleteWindow
                isOpen={deleteOpen}
                objectId={props.objectId}
                objectType={props.objectType}
                onClose={handleDeleteWindowClose}
                onChange={props.onChange}
                commentId={activeComment.commentId}
                additionalDataForRequestBody={props.additionalDataForRequestBody}
            />
        </Fragment>
    );
}

const CommentsWithBackend = withBackend(Comments);

export { CommentsWithBackend as Comments, CommentEditor };
