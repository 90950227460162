import { useEffect, useState } from 'react';
import { Theme, Modal, Button } from 'components/ui-library';
import GroupsPane, { DEFAULT_ACTIVE_GROUP } from 'Bricks/complex-table-view/groups-pane';

const ACCESS_GROUP_MODAL_THEME = {
    Modal: {
        maxWidth: '70%',
        maxHeight: '70%',
        width: '45%',
        height: '70%',
        innerPadding: '0px',
        simple: {
            borderTopColor: 'white',
        },
    },
};

function AccessGroupModal(props) {
    const defaultSelectedGroupIdState = props.preselectedGroupId || DEFAULT_ACTIVE_GROUP;

    const [selectedGroupId, setSelectedGroupId] = useState(defaultSelectedGroupIdState);

    function handleSubmit() {
        props.onChange(selectedGroupId);
        props.close();
    }

    function handleCancel() {
        setSelectedGroupId(defaultSelectedGroupIdState);
        props.close();
    }

    function handleGroupsChange(treeGroupId) {
        setSelectedGroupId(treeGroupId);
    }

    useEffect(() => {
        setSelectedGroupId(defaultSelectedGroupIdState);
    }, [defaultSelectedGroupIdState]);

    return (
        <Theme.Provider theme={ACCESS_GROUP_MODAL_THEME}>
            <Modal
                id={props.id}
                type="simple"
                show={props.show}
                title="PLEASE_SELECT_A_GROUP"
                onDispose={handleCancel}
                buttons={[
                    <Button id="access-group-modal-ok" type="primary" text="OK" onClick={handleSubmit} />,
                    <Button id="access-group-modal-cancel" type="secondary" text="CANCEL" onClick={handleCancel} />,
                ]}
            >
                <GroupsPane
                    accessRight={window.serverInfo.constants.accessRight.AR_Groups_Computers_View}
                    groups={props.groups}
                    defaultActive={selectedGroupId}
                    disableGroupChangeSubscribe
                    onChange={handleGroupsChange}
                />
            </Modal>
        </Theme.Provider>
    );
}

export default AccessGroupModal;
