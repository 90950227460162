import { IconSelectionCheckboxCollapse, IconSelectionCheckboxExpand } from '@uilib/business-components/index';
import { Component, Children } from 'react';
import classnames from 'classnames';

import { ConditionalWrap } from 'components/ui-library';

import i18n from 'Services/i18n';

import HighlightBlank from 'Bricks/highlight-blank';
import IconButton from 'Bricks/icon-button';

import './index.scss';

function List(props) {
    return (
        <div className={classnames('ei-details-list', props.className)}>
            <ul>{props.children}</ul>
        </div>
    );
}

class Item extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: this.props.isOpen };
    }

    toggle = () => {
        if (this.props.onToggle) {
            this.props.onToggle(this.props.id, !this.state.isOpen);
        }
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        let content = this.props.group ? this.props.value : this.props.children;
        if (this.props.highlightBlank) {
            content = (
                <HighlightBlank value={content} monospace={this.props.monospace} uppercase={this.props.uppercase} />
            );
        }

        const ID_PREFIX = this.props.id || 'eid-list-item';
        return (
            <li
                data-label={this.props.testDataLabel}
                className={classnames('list-item', { group: this.props.group }, this.props.liClassName)}
                ref={this.props.scrollTargetRef}
            >
                <ConditionalWrap
                    condition={this.props.group}
                    wrap={(chld) => (
                        <div className="header">
                            {chld}
                            {this.props.children !== undefined && (
                                <IconButton
                                    className="toggle"
                                    icon={
                                        this.state.isOpen ? (
                                            <IconSelectionCheckboxCollapse fill="currentcolor" />
                                        ) : (
                                            <IconSelectionCheckboxExpand fill="currentcolor" />
                                        )
                                    }
                                    onClick={this.toggle}
                                />
                            )}
                        </div>
                    )}
                >
                    {this.props.label !== undefined && this.props.sublabel !== undefined ? (
                        <div className={classnames('list-label', { group: this.props.group })}>
                            <div id={`${ID_PREFIX}-label`} className="list-label-title">
                                {i18n(this.props.label)}
                            </div>
                            <div>{i18n(this.props.sublabel)}</div>
                        </div>
                    ) : this.props.label !== undefined ? (
                        <span
                            id={`${ID_PREFIX}-label`}
                            className={classnames('list-label', 'list-label-title', {
                                group: this.props.group,
                            })}
                        >
                            {i18n(this.props.label)}
                        </span>
                    ) : null}
                    <div id={`${ID_PREFIX}-header`} className={classnames('list-value', this.props.className)}>
                        <div>{i18n(content)}</div>
                    </div>
                </ConditionalWrap>
                {this.props.group === true && Children.count(this.props.children) ? (
                    <ul children={this.props.children} className={classnames({ hidden: !this.state.isOpen })} />
                ) : null}
            </li>
        );
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen,
            });
        }
    }
}

Item.defaultProps = {
    highlightBlank: true,
};

List.Item = Item;

export { List };
