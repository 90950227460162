import { IconNumericGreq, IconNumericLeeq, IconNumericEq, IconNumericNoteq } from '@uilib/business-components/index';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import lodash from 'lodash';
import classnames from 'classnames';

import { Checkbox, checkState, TagsPanel, Select } from 'components/ui-library';

import { EVENT, EventNames } from 'Services/Eventing';
import { BACKEND, withBackend } from 'Services/backend';
import i18n from 'Services/i18n';

import { escapeRegExp, stringCompare, openEraInNewTab } from 'Bricks/Helpers';

import LocalFiltersComplex from './complex';
import LocalFiltersPresets from './Presets';
import colors from 'eset-ui-colors';
import './index.scss';

//-----------------------------------------------------------------------------
const SELECT_DIVIDER_ID = -1;
const SELECT_UNKNOWN_ID = -2;
const SELECT_KNOWN_ID = -3;

const SELECT_DIVIDER_OPTION = { id: SELECT_DIVIDER_ID, name: '________', disabled: true };
const SELECT_UNKNOWN_OPTION = { id: SELECT_UNKNOWN_ID, name: 'UNKNOWN', blank: true };
const SELECT_KNOWN_OPTION = { id: SELECT_KNOWN_ID, name: 'KNOWN', blank: true };

function isPresetRangeSelected(localFilter) {
    return (
        localFilter.options.ranges &&
        typeof localFilter.value.timeRange === 'string' &&
        localFilter.value.timeRange !== getTimeRangeKey(localFilter.options.locale.customRangeLabel)
    );
}

function translateSeverity(severityNumber) {
    switch (severityNumber) {
        case 1:
            return 'info';
        case 2:
            return 'warning';
        case 3:
            return 'threat';
        default:
            return 'unknown';
    }
}

function translateIncidentSeverity(severityNumber) {
    switch (severityNumber) {
        case 0:
            return 'low';
        case 1:
            return 'medium';
        case 2:
            return 'high';
        default:
            return 'unknown';
    }
}

function translateIncidentStatus(statusNumber) {
    switch (statusNumber) {
        case 0:
            return 'open';
        case 1:
            return 'inprogress';
        case 2:
            return 'onhold';
        case 3:
            return 'resolved';
        case 4:
            return 'closed';
        case 6:
            return 'invalid';
        default:
            return 'unknown';
    }
}

const TIME_RANGE = [
    { key: '1_HOUR', translation: () => i18n('1_HOUR'), filter: '1h' },
    { key: '1_DAY', translation: () => i18n('1_DAY'), filter: '1d' },
    { key: '2_DAYS', translation: () => i18n('2_DAYS'), filter: '2d' },
    { key: '3_DAYS', translation: () => i18n('3_DAYS'), filter: '3d' },
    { key: '1_WEEK', translation: () => i18n('1_WEEK'), filter: '1w' },
    { key: '1_MONTH', translation: () => i18n('1_MONTH'), filter: '1m' },
    { key: '3_MONTHS', translation: () => i18n('3_MONTHS'), filter: '3m' },
    { key: '1_YEAR', translation: () => i18n('1_YEAR'), filter: '1y' },
    { key: 'CUSTOM', translation: () => i18n('CUSTOM'), filter: null },
];

function translateTimeRange(key) {
    const TIME_RANGE_TRANSLATION = TIME_RANGE.reduce((translated, range) => {
        translated[range.key] = range.translation;
        return translated;
    }, {});
    return TIME_RANGE_TRANSLATION[key] ? TIME_RANGE_TRANSLATION[key]() : key;
}

function getTimeRangeKey(translation) {
    const TIME_RANGE_TRANSLATION_KEYS = TIME_RANGE.reduce((keys, range) => {
        keys[range.translation()] = range.key;
        return keys;
    }, {});
    return TIME_RANGE_TRANSLATION_KEYS[translation] ? TIME_RANGE_TRANSLATION_KEYS[translation] : 'CUSTOM';
}

class LocalFilters extends Component {
    constructor(props) {
        super(props);

        const filtersTree = lodash.cloneDeep(this.props.filters);

        // Parse filters tree and create array with filters settings.
        this.uiSettings = [];
        this.parseFiltersTree(filtersTree, (filtersTreeNode) => {
            let key = Object.keys(filtersTreeNode)[0];
            let filter = filtersTreeNode[key];

            filter.id = key;
            filter.column = key.split(':')[0];

            if (filter.visible === undefined) {
                filter.visible = filter.type === 'button'; // Buttons are visible by default.
            }

            if (filter.type === 'button' || filter.type === 'shadow') {
                if (filter.operator === undefined) {
                    filter.operator = 'EQ';
                }
            }

            if (filter.type === 'checkbox') {
                if (filter.operator === undefined) {
                    filter.operator = 'EQ';
                }
                filter.value = filter.value ? 1 : 0;
                filter.blankValues = false;
            }

            if (filter.type === 'groupsTree') {
                if (filter.operator === undefined) {
                    filter.operator = 'EQ';
                }

                filter.blankValues = false;
            }

            if (filter.type === 'text') {
                if (filter.blankValues === undefined) {
                    filter.blankValues = true;
                }
                if (filter.operators === undefined) {
                    filter.operators = { initial: 1, items: ['NOTLIKE', 'LIKE'] };
                }
                filter.value = '';
                filter.submitValue = '';
            }

            if (filter.type === 'number') {
                if (filter.blankValues === undefined) {
                    filter.blankValues = true;
                }
                if (filter.suspiciousIfLess === undefined) {
                    filter.operators = { initial: 1, items: ['NE', 'EQ', 'LE', 'GE'] };
                } else if (filter.suspiciousIfLess) {
                    filter.operators = { initial: 2, items: ['NE', 'EQ', 'LE', 'GE'] };
                } else {
                    filter.operators = { initial: 3, items: ['NE', 'EQ', 'LE', 'GE'] };
                }
                filter.value = filter.value !== undefined ? filter.value : '';
                filter.submitValue = '';
            }

            if (filter.type === 'timestamp') {
                if (filter.blankValues === undefined) {
                    filter.blankValues = true;
                }
                filter.operators = { initial: 0, items: ['GE', 'LE', 'EQ'] };
                filter.operator = filter.operators.items[filter.operators.initial];

                filter.options = this.getTimestampOptions(filter);
                filter.value = filter.options.eei_defaultValue;
            }

            if (filter.type === 'dashboardTimestamp') {
                filter.blankValues = false;
                filter.operators = { initial: 0, items: ['LE'] };
                filter.operator = filter.operators.items[filter.operators.initial];

                filter.options = this.getDashboardTimestampOptions();
                filter.value = filter.options.eei_defaultValue;
            }

            if (filter.type === 'select') {
                if (filter.blankValues) {
                    filter.values.items = filter.values.items.concat(
                        SELECT_DIVIDER_OPTION,
                        SELECT_UNKNOWN_OPTION,
                        SELECT_KNOWN_OPTION
                    );
                }
                filter.blankValues = false;
            }

            if (filter.type === 'select-time-lg') {
                filter.blankValues = false;
                filter.operators = { initial: 0, items: ['LE', 'GE'] };
                filter.values = {
                    initial: 3,
                    items: [
                        { id: 0, name: 'NEVER_SEEN_IN_LIVEGRID' },
                        { id: 1, name: '1_DAY' },
                        { id: 2, name: '2_DAYS' },
                        { id: 3, name: '3_DAYS' },
                        { id: 5, name: '5_DAYS' },
                        { id: 8, name: '1_WEEK' },
                        { id: 15, name: '2_WEEKS' },
                        { id: 30, name: '1_MONTH' },
                        { id: 92, name: '3_MONTHS' },
                        { id: 183, name: '6_MONTHS' },
                        { id: 366, name: '1_YEAR' },
                        { id: 731, name: '2_YEARS' },
                        { id: 1826, name: '5_YEARS' },
                        { id: 2555, name: '7_YEARS' },
                        SELECT_DIVIDER_OPTION,
                        SELECT_UNKNOWN_OPTION,
                        SELECT_KNOWN_OPTION,
                    ],
                };
            }

            if (filter.type === 'multi-select') {
                filter.value = [];
            }

            if (null != filter.operators) {
                filter.operator = filter.operators.items[filter.operators.initial];
            }

            if (null != filter.values && filter.type !== 'multi-select') {
                filter.value = filter.values.items[filter.values.initial];
            }

            this.uiSettings.push(filter);
        });

        this.state = {
            groupingFilterOptions: props.grouping.options.map((option) => ({
                isDisabled: false,
                isStatic: true,
                label: i18n(option.name),
                value: option.id,
            })),
            groupingFilterValue: props.grouping.value,
            subgroupsFilterValue: props.subgroups.value,
            dashboardFilterVisible: props.dashboard.visible,

            filterPanelOptions: [],
            filterPanelValue: [],
        };

        if (typeof props.onRegisterApi === 'function') {
            props.onRegisterApi(this);
        }
    }

    handleTagsLoadOptions = (inputValue) => {
        const requestBody = {
            localFilters: { filterTree: inputValue !== '' ? { name: { LIKE: escapeRegExp(inputValue) } } : {} },
            sortOrders: [{ column: 'name', ascend: true }],
        };

        return BACKEND.post('tags/0', requestBody, this.props.componentUuid)
            .success((response) => {
                return response.entities.map((tag) => ({ value: tag.id, label: tag.name }));
            })
            .execute();
    };

    createFilterPanelOptions() {
        let valueIdx = 0;
        const OPTIONS = [];

        if (this.props.grouping.visible) {
            OPTIONS.push({
                isStatic: true,
                label: '',
                color: 'active',
                content: (
                    <Select
                        menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                        styles={{ menuPortal: this.handleMenuPortalStyle }}
                        allowEmpty={false}
                        className="local-filters-select"
                        options={this.state.groupingFilterOptions}
                        value={
                            this.state.groupingFilterOptions.find(
                                (option) => option.value === this.state.groupingFilterValue
                            ) || this.state.groupingFilterOptions[0]
                        }
                        onChange={this.handleGroupingChange}
                    />
                ),
                value: valueIdx++,
            });
        }

        if (this.props.visible) {
            const BUTTON_FILTERS = this.uiSettings.filter((localFilter) => localFilter.type === 'button');

            let previousColumn = '';
            for (const BUTTON_FILTER of BUTTON_FILTERS) {
                if (BUTTON_FILTER.column !== previousColumn) {
                    const COLUMN_BUTTON_FILTERS = BUTTON_FILTERS.filter(
                        (buttonFilter) => buttonFilter.column === BUTTON_FILTER.column
                    );

                    OPTIONS.push({
                        isStatic: true,
                        label: '',
                        color: COLUMN_BUTTON_FILTERS.find((filter) => filter.active) ? 'active' : 'inactive',
                        content: COLUMN_BUTTON_FILTERS.map((filter) => {
                            const Icon = filter.icon;
                            return (
                                <div
                                    key={filter.id}
                                    id={`eid-${filter.id}`}
                                    title={i18n(filter.tooltipText)}
                                    className={classnames('local-filter-plain', {
                                        'local-filter-plain--active': filter.active,
                                    })}
                                    onClick={(event) => this.onButtonFilterClicked(event, filter)}
                                >
                                    {Icon && React.isValidElement(<Icon />) && (
                                        <Icon fill={colors[filter.active ? filter.color || 'iconColor' : 'disabled']} />
                                    )}
                                </div>
                            );
                        }),
                        value: valueIdx++,
                    });
                    previousColumn = BUTTON_FILTER.column;
                }
            }
        }

        if (this.props.subgroups.visible) {
            OPTIONS.push({
                isStatic: true,
                color: 'active',
                label: 'SHOW_SUBGROUPS',
                content: (
                    <Checkbox
                        checked={Number(this.state.subgroupsFilterValue)}
                        onChange={this.handleSubgroupsChange}
                        testDataLabel="eid-checkbox"
                    />
                ),
                value: valueIdx++,
            });
        }

        if (this.state.dashboardFilterVisible) {
            OPTIONS.push({
                ei_visible: true,
                ei_dashboard: true,
                color: 'active',
                label: this.props.dashboard.label,
                value: valueIdx++,
            });
        }

        if (this.props.tags !== undefined) {
            OPTIONS.push({
                isStatic: true,
                label: '',
                color: this.props.activeTags.length > 0 ? 'active' : 'inactive',
                content: (
                    <Select.Async
                        menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                        styles={{ menuPortal: this.handleMenuPortalStyle }}
                        className="local-filters-select"
                        isMulti
                        defaultOptions={this.convertDbTagsIntoUiTags(this.props.tags)}
                        loadOptions={this.handleTagsLoadOptions}
                        noOptionsMessage={() => i18n('N/A')}
                        onChange={this.handleActiveTagsChange}
                        value={this.convertDbTagsIntoUiTags(this.props.activeTags)}
                        placeholder={`${i18n('TAGS')}...`}
                    />
                ),
                value: valueIdx++,
            });
        }

        if (this.props.visible) {
            OPTIONS.push(
                ...this.uiSettings
                    .filter((localFilter) => localFilter.type !== 'button' && localFilter.type !== 'shadow')
                    .map((localFilter) => ({
                        eid: localFilter.id,
                        ei_visible: localFilter.visible,
                        isStatic: localFilter.fixed,
                        color: localFilter.active ? 'active' : 'inactive',
                        label: localFilter.label,

                        component: (
                            <LocalFiltersComplex
                                key={localFilter.id}
                                id={localFilter.id}
                                type={localFilter.type}
                                active={localFilter.active}
                                label={localFilter.label}
                                operator={localFilter.operator}
                                operators={localFilter.operators}
                                options={localFilter.values}
                                onOperatorChange={(event) => this.operatorChangeHandle(event, localFilter)}
                                onInputOptionClicked={(event) => this.uiOnUserInputClicked(event, localFilter)}
                                onBlankOptionClicked={(event, operator, value) =>
                                    this.onBlankOptionClicked(event, localFilter, operator, value)
                                }
                                onSubmit={(event) => this.onTextFilterSubmitted(event, localFilter)}
                                onBlur={(event) => this.uiOnInputLostFocus(event, localFilter)}
                                blank={localFilter.blank}
                                blankValues={localFilter.blankValues}
                                disableLabelSplit={localFilter.disableLabelSplit}
                                value={localFilter.value}
                                minValue={localFilter.minValue}
                                maxValue={localFilter.maxValue}
                                onValueChange={(event, value, submit) =>
                                    this.valueChangeHandle(event, value, submit, localFilter)
                                }
                                tooltipFilter={localFilter.tooltipFilter}
                                uiOnUserTimestampClicked={(event) => this.uiOnUserTimestampClicked(event, localFilter)}
                                onBlankTimestampClicked={(event, operator, value) =>
                                    this.onBlankTimestampClicked(event, localFilter, operator, value)
                                }
                                onTimestampChanged={(value) => this.onTimestampChanged(value, localFilter)}
                                onGroupsTreeFilterSubmitted={(value) =>
                                    this.onGroupsTreeFilterSubmitted(value, localFilter)
                                }
                                timestampOptions={localFilter.options}
                                menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                            />
                        ),
                        value: valueIdx++,
                    }))
            );
        }

        const filterPanelOptions = OPTIONS.sort((option1st, option2nd) =>
            option1st.label.localeCompare(option2nd.label)
        );
        const filterPanelValue = OPTIONS.filter((filter) => filter.isStatic || filter.ei_visible);

        this.setState({ filterPanelOptions, filterPanelValue });

        return filterPanelValue.length;
    }

    isTooManyFilters = () => {
        return (
            this.defaultFiltersCount <
            this.state.filterPanelValue.filter((filter) => filter.color !== 'inactive').length
        );
    };

    componentDidMount() {
        this.defaultFiltersCount = this.createFilterPanelOptions();
        //     Event.subscribe('logout', function (eventName, message) {
        //         $scope.uiOnCancelClicked();
        //}
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.publishChange !== undefined) {
            EVENT.publish(EventNames.LOCAL_FILTERS_CHANGED_EVENT, this.publishChange);
            this.publishChange = undefined;
        }
        if (
            prevState.filterPanelOptions === this.state.filterPanelOptions ||
            prevProps.menuPortalTarget !== this.props.menuPortalTarget
        ) {
            this.createFilterPanelOptions();
        }
    }

    getDashboardTimestampOptions() {
        const DEFAULT_TIMESTAMP_RANGE_LABEL = translateTimeRange('1_WEEK');

        // Operator is fixed here - presets should operate on 'days' units:
        // * LE 2 DAYS  - today + yesterday,
        // * LE 3 DAYS  - today + yesterday + day before,
        // * LE 1 WEEK  - today + 6 last days,
        // * LE 1 MONTH - doesn't matter as it's not fixed in reality.
        const startRange2Days = moment().subtract(1, 'days').startOf('day');
        const startRange3Days = moment().subtract(2, 'days').startOf('day');
        const startRange1Week = moment().subtract(6, 'days').startOf('day');
        const startRange1Month = moment().subtract(1, 'month').startOf('day');
        const endRangeNotUsed = moment();

        const ranges = {
            [translateTimeRange('2_DAYS')]: [startRange2Days, endRangeNotUsed],
            [translateTimeRange('3_DAYS')]: [startRange3Days, endRangeNotUsed],
            [translateTimeRange('1_WEEK')]: [startRange1Week, endRangeNotUsed],
            [translateTimeRange('1_MONTH')]: [startRange1Month, endRangeNotUsed],
        };

        return {
            eei_defaultValue: {
                timeRange: getTimeRangeKey(DEFAULT_TIMESTAMP_RANGE_LABEL),
                startDate: ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][0],
                endDate: ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][1],
            },
            startDate: ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][0],
            endDate: ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][1],
            autoUpdateInput: false,
            locale: {
                format: 'L',
                fromLabel: i18n('FROM'),
                toLabel: i18n('TO'),
            },
            getTimeRangeKey: getTimeRangeKey,
            translateTimeRange: translateTimeRange,
            showCustomRangeLabel: false,
            ranges: ranges,
        };
    }

    getTimestampOptions(filter, startDate, endDate) {
        const DEFAULT_TIMESTAMP_RANGE_LABEL = translateTimeRange('1_WEEK');

        // Operator is not fixed here - its changes modify startOf/endOf day.
        // Second date is unsed in case of range operator.
        // ToDo? According to requirements each time user switches LE/GE into EQ then
        // we should open dateRangePicker - unfortunately we use external component
        // which doesn't provide this option... or maybe this would be annoying to user?
        const endRangeUsed = moment();
        const ranges = {
            [translateTimeRange('1_HOUR')]: [moment().subtract(1, 'hour'), endRangeUsed],
            [translateTimeRange('1_DAY')]: [moment().subtract(0, 'days'), endRangeUsed],
            [translateTimeRange('2_DAYS')]: [moment().subtract(1, 'days'), endRangeUsed],
            [translateTimeRange('1_WEEK')]: [moment().subtract(6, 'days'), endRangeUsed],
            [translateTimeRange('1_MONTH')]: [moment().subtract(1, 'month'), endRangeUsed],
            [translateTimeRange('3_MONTHS')]: [moment().subtract(3, 'month'), endRangeUsed],
            [translateTimeRange('1_YEAR')]: [moment().subtract(1, 'year'), endRangeUsed],
        };

        return {
            eei_defaultValue: {
                timeRange: getTimeRangeKey(DEFAULT_TIMESTAMP_RANGE_LABEL),
                startDate: ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][0].toDate(),
                endDate: ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][1].toDate(),
            },
            startDate: startDate || ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][0].toDate(),
            endDate: endDate || ranges[DEFAULT_TIMESTAMP_RANGE_LABEL][1].toDate(),
            timePicker: true,
            timePicker24Hour: true,
            timePickerSeconds: true,
            autoUpdateInput: false,
            singleDatePicker: filter.operator !== 'EQ',
            locale: {
                customRangeLabel: translateTimeRange('CUSTOM'),
                format: 'L',
                fromLabel: i18n('FROM'),
                toLabel: i18n('TO'),
            },
            getTimeRangeKey: getTimeRangeKey,
            translateTimeRange: translateTimeRange,
            ranges: filter.operator !== 'EQ' ? ranges : undefined,
            maxDate: moment().endOf('day'),
        };
    }

    parseFiltersTree(filtersTree, onFilterCallback) {
        // Input schema uses tree structure.
        for (let key in filtersTree) {
            // Parse each object in a node.
            if ('AND' === key || 'OR' === key || 'NOT' === key) {
                // These keys contain arrays with deeper nodes.
                filtersTree[key].forEach((cell) => {
                    // Parse each node again.
                    this.parseFiltersTree(cell, onFilterCallback);
                });

                // Remove filter if empty.
                for (let i = filtersTree[key].length - 1; i >= 0; --i) {
                    if (0 === Object.keys(filtersTree[key][i]).length) {
                        filtersTree[key].splice(i, 1);
                    }
                }

                if (0 === filtersTree[key].length) {
                    // Remove operator if empty.
                    delete filtersTree[key];
                } else if (1 === filtersTree[key].length) {
                    // Change operator into a filter if only one is present.
                    const FILTER = filtersTree[key][0];
                    delete filtersTree[key];
                    Object.assign(filtersTree, FILTER);
                }
            } else {
                // This key contains a filter data.
                onFilterCallback(filtersTree);
            }
        }
    }

    //---------------------------------------------------------
    // USER INTERFACE METHODS
    //---------------------------------------------------------
    handleGroupingChange = (option) => {
        this.setState({
            groupingFilterValue: option.value,
        });

        this.notifyChange(false);
    };

    handleSubgroupsChange = (checked, value, event) => {
        this.setState({
            subgroupsFilterValue: !!checked,
        });

        this.notifyChange(false);
    };

    handleDashboardClose = (event) => {
        this.setState({
            dashboardFilterVisible: false,
        });

        if (this.props.dashboard.action) {
            this.props.dashboard.action(event);
        }

        this.notifyChange(false);
    };

    onButtonFilterClicked = (event, localFilter) => {
        localFilter.active = !localFilter.active;

        this.notifyChange(false);
    };

    //-------------------------------------
    notifyChange(layoutOnly, filterShifted) {
        // Force render method - it's an exception here!!!
        // But in case of many filters and their complicated structure
        // it would be quite difficult to properly update states.
        this.publishChange = {
            layoutOnly,
            filterShifted,
            filterId: this.props.tableApi?.filterId || this.props.filterId,
        };
        this.forceUpdate();
    }

    valueChangeHandle = (event, value, submit, localFilter) => {
        localFilter.value = value;

        if (submit) {
            this.notifyChange(false); // TODO: revert this operator...
        } else if (localFilter.type === 'text' || localFilter.type === 'number') {
            this.forceUpdate();
        }
    };

    getSubmitValue(filterSettings, value) {
        if (!filterSettings.postProcessors) {
            return value;
        }

        let submitResult = value;
        filterSettings.postProcessors.forEach((processor) => (submitResult = processor(submitResult)));
        return submitResult;
    }

    onTextFilterSubmitted(event, localFilter) {
        localFilter.active =
            !localFilter.blank && (null == localFilter.value || '' === localFilter.value) ? false : true;
        localFilter.submitValue = localFilter.value;

        if (event) {
            event.preventDefault();
        }

        this.notifyChange(false);
    }

    onGroupsTreeFilterSubmitted(value, localFilter) {
        localFilter.value = value;
        localFilter.active = localFilter.value !== undefined;
        localFilter.submitValue = localFilter.value;

        this.notifyChange(false);
    }

    uiOnInputLostFocus(event, filter) {
        if (filter.submitValue !== filter.value) {
            this.onTextFilterSubmitted(event, filter);
        }
    }

    uiOnUserTimestampClicked = (event, localFilter) => {
        localFilter.operators = { initial: 0, items: ['LE', 'GE', 'EQ'] };
        localFilter.operator = localFilter.operators.items[localFilter.operators.initial];
        localFilter.value = localFilter.options.eei_defaultValue;
        this.initializeTimestampFilter(localFilter);

        localFilter.blank = false;

        this.notifyChange(false);
    };

    onBlankTimestampClicked = (localFilter, option) => {
        localFilter.operator = option.operator;
        localFilter.value = option.value;

        localFilter.blank = true;

        this.notifyChange(false);
    };

    onTimestampChanged = (value, localFilter) => {
        localFilter.value = value;
        if (localFilter.type === 'timestamp') {
            this.initializeTimestampFilter(localFilter);
        } else {
            localFilter.options = this.getDashboardTimestampOptions(localFilter.value.timeRange);
        }
        this.notifyChange(false);
    };

    initializeTimestampFilter(filter) {
        if (filter.value !== null) {
            // Recreate picker options as type of custom range depends on operator!
            filter.options = this.getTimestampOptions(
                filter,
                moment(filter.value.startDate),
                moment(filter.value.endDate)
            );
        }
    }

    operatorChangeHandle = (event, localFilter) => {
        localFilter.active =
            !localFilter.blank && (null == localFilter.value || '' === localFilter.value) ? false : true;

        const idx = localFilter.operators.items.indexOf(localFilter.operator);
        if (-1 !== idx && localFilter.operators.items.length > idx + 1) {
            localFilter.operator = localFilter.operators.items[idx + 1];
        } else {
            localFilter.operator = localFilter.operators.items[0];
        }

        if ('timestamp' === localFilter.type) {
            this.initializeTimestampFilter(localFilter);

            if (localFilter.operator === 'EQ') {
                // Date-Range >> no matter what kind of dates are selected adjust their times so whole days are selected.
                const START_RANGE = moment(localFilter.value.startDate).startOf('day');
                const END_RANGE = moment(localFilter.value.endDate).endOf('day');

                localFilter.options = this.getTimestampOptions(localFilter, START_RANGE.toDate(), END_RANGE.toDate());
                localFilter.value.startDate = START_RANGE.toDate();
                localFilter.value.endDate = END_RANGE.toDate();
            } else if (isPresetRangeSelected(localFilter)) {
                // Preset-Range >> this is quite tricky as it's visible to user as a range (i.e. 1 week) but here
                // we have to use it as a single date - we use startDate only as in this case endDate points to the
                // current time set by a call 'moment()'. In addition we have to remember about opposite effect of
                // opeartors here (a date LESS than a week means that we have to find timestamp GREATER than this date)
                const START_DATE = moment(localFilter.value.startDate);
                const POINT_DATE = localFilter.operator === 'LE' ? START_DATE.startOf('day') : START_DATE.endOf('day');

                localFilter.options = this.getTimestampOptions(localFilter, POINT_DATE.toDate(), POINT_DATE.toDate());
                localFilter.value.startDate = POINT_DATE.toDate();
            } else if (localFilter.operator === 'LE') {
                // User-Range/Single-Date >> use last moment of the endDate.
                const POINT_DATE = moment(localFilter.value.endDate).endOf('day');
                localFilter.options = this.getTimestampOptions(localFilter, POINT_DATE.toDate(), POINT_DATE.toDate());
                localFilter.value.endDate = POINT_DATE.toDate();
            } else if (localFilter.operator === 'GE') {
                // User-Range/Single-Date >> use first moment of the startDate.
                const POINT_DATE = moment(localFilter.value.startDate).startOf('day');
                localFilter.options = this.getTimestampOptions(localFilter, POINT_DATE.toDate(), POINT_DATE.toDate());
                localFilter.value.startDate = POINT_DATE.toDate();
            }
        }

        this.notifyChange(false);
    };

    uiOnUserInputClicked = (event, localFilter) => {
        localFilter.operator = localFilter.operators.items[localFilter.operators.initial];
        localFilter.value = '';

        localFilter.blank = false;

        this.onTextFilterSubmitted(event, localFilter);
    };

    onBlankOptionClicked = (event, localFilter, operator, value) => {
        localFilter.operator = operator;
        localFilter.value = value;

        localFilter.blank = true;

        this.onTextFilterSubmitted(event, localFilter);
    };

    //---------------------------------------------------------
    // STATE HANDLING METHODS
    //---------------------------------------------------------
    getDashboardTimestamp() {
        const FILTERS_TREE = this.get().filterTree;
        return FILTERS_TREE.timestamp ? FILTERS_TREE.timestamp.GE : undefined;
    }

    /**
     * Returns a state which might be saved and reload later.
     */
    getCurrentState() {
        const commonFilters = this.uiSettings
            .filter((setting) => setting.type !== 'shadow')
            .map((setting) => {
                return {
                    // Used to uniquely identify a filter.
                    id: setting.id,
                    type: setting.type,

                    visible: setting.visible,
                    active: setting.active,
                    operator: setting.operator,
                    value: setting.value,
                    blank: setting.blank,
                };
            });
        return {
            grouping: this.state.groupingFilterValue,
            subgroups: this.state.subgroupsFilterValue,
            common: commonFilters,
        };
    }

    /**
     * Sets a state in case there is a need to restore one.
     *
     * @param state   a new state
     */
    setCurrentState(state, notify) {
        if (state) {
            if (this.props.grouping.visible) {
                // Grouping settings v1.3
                if (state.grouping !== undefined) {
                    this.setState({
                        groupingFilterValue: state.grouping,
                    });
                }
                // Grouping settings v1.2.1
                if (state.selectValue !== undefined) {
                    this.setState({
                        groupingFilterValue: state.selectValue.id,
                    });
                }
            }

            if (this.props.subgroups.visible) {
                // Subgroups settings v1.3
                if (state.subgroups !== undefined) {
                    this.setState({
                        subgroupsFilterValue: state.subgroups,
                    });
                }
                // Subgroups settings v1.2.1
                if (state.subgroupsActive !== undefined) {
                    this.setState({
                        subgroupsFilterValue: state.subgroupsActive,
                    });
                }
            }

            const COMMON_FILTERS = state.common || state.settings || [];
            if (COMMON_FILTERS.length > 0) {
                this.uiSettings.forEach((uiSetting) => {
                    COMMON_FILTERS.forEach((setting) => {
                        if (setting.visible === undefined) {
                            // Handling of v1.2.1 settings - 'visible' field was not used there.
                            setting.visible = setting.type === 'button' ? true : setting.active;

                            if (setting.empty !== undefined) {
                                setting.blank = setting.empty;
                            }

                            if (setting.type === 'button' || setting.type === 'number') {
                                setting.value = Number(setting.value);
                            }

                            if (setting.type === 'checkbox') {
                                setting.value = setting.value ? 1 : 0;
                            }

                            if (setting.type === 'timePicker') {
                                setting.type = 'timestamp';
                                if (setting.value !== null) {
                                    setting.value.timeRange = getTimeRangeKey(setting.value.rangeLabel);
                                    setting.value.rangeLabel = undefined;
                                }
                            }

                            if (setting.type === 'select') {
                                const NUMERICAL_ID = Number(setting.value.id);
                                if (!isNaN(NUMERICAL_ID)) {
                                    setting.value.id = NUMERICAL_ID;
                                }
                            }
                        }

                        if (
                            uiSetting.type !== 'shadow' &&
                            uiSetting.id === setting.id &&
                            uiSetting.type === setting.type
                        ) {
                            uiSetting.visible = setting.visible;
                            uiSetting.active = setting.active;

                            uiSetting.blank = setting.blank;

                            if (null != uiSetting.operators && 0 < uiSetting.operators.items.length) {
                                // Check if saved operator exists in current filter.
                                const idx = uiSetting.operators.items.indexOf(setting.operator);
                                if (-1 !== idx) {
                                    uiSetting.operator = uiSetting.operators.items[idx];
                                } else if (uiSetting.blank) {
                                    uiSetting.operator = setting.operator;
                                }
                            } else {
                                uiSetting.operator = setting.operator;
                            }

                            if (null != uiSetting.values && 0 < uiSetting.values.items.length) {
                                // Check if saved value (object by id) exists in current filter.
                                const idx = uiSetting.values.items
                                    .map((item) => {
                                        return item.id;
                                    })
                                    .indexOf(setting.value.id);
                                if (-1 !== idx) {
                                    uiSetting.value = uiSetting.values.items[idx];
                                }
                            } else {
                                uiSetting.value = setting.value;
                            }

                            if (uiSetting.type === 'text' || uiSetting.type === 'number') {
                                uiSetting.submitValue = setting.value;
                            }

                            if (uiSetting.type === 'timestamp') {
                                this.initializeTimestampFilter(uiSetting);
                            }

                            if (uiSetting.type === 'dashboardTimestamp') {
                                uiSetting.options = this.getDashboardTimestampOptions(uiSetting.value.timeRange);
                            }

                            if (uiSetting.type === 'multi-select') {
                                uiSetting.value = setting.value;
                            }
                        }
                    });
                });
            }
            this.notifyChange(!notify);
        }
    }

    setCreationTimeOperator(leCreationTimeDetected, geCreationTimeDetected) {
        if (leCreationTimeDetected && geCreationTimeDetected) {
            return 'EQ';
        } else if (leCreationTimeDetected) {
            return 'LE';
        } else if (geCreationTimeDetected) {
            return 'GE';
        }
        return '';
    }

    setStateFromLinkFilters(linkFilters) {
        let leCreationTimeDetected = false;
        let geCreationTimeDetected = false;
        let startDate = moment();
        let endDate = moment();
        this.parseFiltersTree(linkFilters, (filterTree) => {
            for (const filterName in filterTree) {
                const operatorWithValue = filterTree[filterName];
                for (const operator in operatorWithValue) {
                    const value = filterTree[filterName][operator];

                    this.uiSettings.forEach((uiSetting) => {
                        if (uiSetting.type === 'timestamp' && stringCompare(filterName, uiSetting.id, true)) {
                            uiSetting.active = true;
                            uiSetting.visible = true;
                            uiSetting.value.timeRange = undefined;
                            if (operator === 'GE') {
                                geCreationTimeDetected = true;
                                startDate = moment(value);
                            } else if (operator === 'LE') {
                                leCreationTimeDetected = true;
                                endDate = moment(value);
                            }
                            uiSetting.operator = this.setCreationTimeOperator(
                                leCreationTimeDetected,
                                geCreationTimeDetected
                            );
                            if (uiSetting.operator === 'GE') {
                                uiSetting.value.startDate = startDate;
                                uiSetting.value.endDate = startDate;
                            } else if (uiSetting.operator === 'LE') {
                                uiSetting.value.startDate = endDate;
                                uiSetting.value.endDate = endDate;
                            } else {
                                uiSetting.value.startDate = startDate;
                                uiSetting.value.endDate = endDate;
                            }
                            this.initializeTimestampFilter(uiSetting);
                        } else if (uiSetting.type === 'checkbox' && stringCompare(filterName, uiSetting.id, true)) {
                            uiSetting.active = true;
                            uiSetting.visible = true;
                            uiSetting.value = value ? checkState.checked : checkState.unchecked;
                        } else if (uiSetting.type === 'select' && stringCompare(filterName, uiSetting.id, true)) {
                            const matchingValue = uiSetting.values.items.find((obj) =>
                                stringCompare(obj.name, value, true)
                            );
                            if (matchingValue) {
                                uiSetting.active = true;
                                uiSetting.visible = true;
                                uiSetting.value = matchingValue;
                            }
                        } else if (stringCompare(filterName, uiSetting.id, true)) {
                            uiSetting.active = true;
                            uiSetting.visible = true;
                            uiSetting.value = value;
                        } else {
                            if (stringCompare(filterName, 'severity', true)) {
                                const id = 'severity:' + translateSeverity(value);
                                if (uiSetting.id.toLowerCase() === id) {
                                    uiSetting.active = true;
                                }
                            } else if (stringCompare(filterName, 'incidentSeverity', true)) {
                                const id = 'severity:' + translateIncidentSeverity(value);
                                if (uiSetting.id.toLowerCase() === id) {
                                    uiSetting.active = true;
                                }
                            } else if (stringCompare(filterName, 'incidentStatus', true)) {
                                const id = 'status:' + translateIncidentStatus(value);
                                if (uiSetting.id.toLowerCase() === id) {
                                    uiSetting.active = true;
                                }
                            }
                        }
                    });
                }
            }
        });
    }

    /**
     * Returns a tree with current filters settings.
     */
    get(presetsMode) {
        const filtersTree = lodash.cloneDeep(this.props.filters);

        // Modify filters tree:
        // * each filter is updated with current settings,
        // * each inacitve filter is removed,
        // * each active filter is simplified to name, operator and value.
        let index = 0;

        const TIME_RANGE_FILTER = TIME_RANGE.reduce((filters, range) => {
            filters[range.key] = range.filter;
            return filters;
        }, {});

        this.parseFiltersTree(filtersTree, (filtersTreeNode) => {
            const key = Object.keys(filtersTreeNode)[0];
            const setting = this.uiSettings[index];

            let operator = setting.operator;
            let value = setting.value;

            // Perform special modifications.
            if (setting.type === 'select' || setting.type === 'select-time-lg') {
                if (value.blank) {
                    operator = value.id === SELECT_UNKNOWN_ID ? 'EQ' : 'NE';
                    value = null;
                } else {
                    value = value.id;
                }
            }

            delete filtersTreeNode[key];
            if (setting.active) {
                if ((setting.type === 'timestamp' || setting.type === 'dashboardTimestamp') && setting.value != null) {
                    const START_MOMENT = moment(setting.value.startDate).utc().format('YYYY-MM-DD HH:mm:ss');
                    const END_MOMENT = moment(setting.value.endDate).utc().format('YYYY-MM-DD HH:mm:ss');

                    if (
                        setting.value.timeRange !== undefined &&
                        TIME_RANGE_FILTER[setting.value.timeRange] === undefined
                    ) {
                        /** WORKAROUND: TRY FIX OLD TYPE TIMESTAMP: ENG TRANSLATION INSTEAD OF KEY */

                        switch (setting.value.timeRange) {
                            case '1 hour':
                                setting.value.timeRange = '1_HOUR';
                                break;
                            case '1 day':
                                setting.value.timeRange = '1_DAY';
                                break;
                            case '2 days':
                                setting.value.timeRange = '2_DAYS';
                                break;
                            case '3 days':
                                setting.value.timeRange = '3_DAYS';
                                break;
                            case '1 week':
                                setting.value.timeRange = '1_WEEK';
                                break;
                            case '1 month':
                                setting.value.timeRange = '1_MONTH';
                                break;
                            case '3 months':
                                setting.value.timeRange = '3_MONTHS';
                                break;
                            case '1 year':
                                setting.value.timeRange = '1_YEAR';
                                break;
                            case 'Custom':
                                setting.value.timeRange = 'CUSTOM';
                                break;
                            default:
                                /** impossible to repair we need to display error filter will not work */
                                setting.value.timeRange = 'ERROR';
                                break;
                        }
                    }

                    if (TIME_RANGE_FILTER[setting.value.timeRange] !== undefined) {
                        if (setting.operator === 'EQ') {
                            // Create a range date using two filters connected with AND.
                            filtersTreeNode.AND = [
                                { [setting.column]: { GE: START_MOMENT } },
                                { [setting.column]: { LE: END_MOMENT } },
                            ];
                        } else {
                            const IS_PRESET_RANGE_SELECTED = isPresetRangeSelected(setting);
                            let DATE;
                            if (IS_PRESET_RANGE_SELECTED && TIME_RANGE_FILTER[setting.value.timeRange]) {
                                DATE = `${TIME_RANGE_FILTER[setting.value.timeRange]} ${moment().utcOffset()}`;
                            } else {
                                DATE = setting.operator === 'GE' ? START_MOMENT : END_MOMENT;
                            }

                            filtersTreeNode[setting.column] = {
                                [setting.operator]: DATE,
                            };
                        }
                    }
                } else {
                    filtersTreeNode[setting.column] = { [operator]: this.getSubmitValue(setting, value) };
                }

                if (presetsMode) {
                    if (filtersTreeNode['AND'] !== undefined) {
                        filtersTreeNode['AND'][0][setting.column].property = setting.property;
                        filtersTreeNode['AND'][0][setting.column].label = setting.label;
                        filtersTreeNode['AND'][0][setting.column].tooltipText = setting.tooltipText;

                        filtersTreeNode['AND'][1][setting.column].property = setting.property;
                        filtersTreeNode['AND'][1][setting.column].label = setting.label;
                        filtersTreeNode['AND'][1][setting.column].tooltipText = setting.tooltipText;
                    } else {
                        filtersTreeNode[setting.column].property = setting.property;
                        filtersTreeNode[setting.column].label = setting.label;
                        filtersTreeNode[setting.column].tooltipText = setting.tooltipText;
                    }
                }
            }
            // Increment the index each time this function is being called.
            // This assures proper connection between state and body.
            ++index;
        });

        return {
            filterTree: filtersTree,
            subgroups: this.props.subgroups?.visible ? this.state.subgroupsFilterValue : this.props.subgroups?.value,
        };
    }

    getWithCustomFilter(value) {
        let filters = this.get();
        if (Object.keys(filters.filterTree).length === 0) {
            filters.filterTree = value;
        } else {
            if (!('AND' in filters.filterTree)) {
                filters.filterTree = { AND: [filters.filterTree] };
            }
            filters.filterTree.AND.push(value);
        }
        return filters;
    }

    //---------------------------------------------------------
    // ADD FILTERS MODAL WINDOW
    //---------------------------------------------------------
    getActivationOptions(column, value) {
        const SELECT_OPERATORS = {
            GE: { name: 'SHOW_HIGHER', icon: <IconNumericGreq fill="currentcolor" /> },
            LE: { name: 'SHOW_LOWER', icon: <IconNumericLeeq fill="currentcolor" /> },
            EQ: { name: 'SHOW_ONLY_THIS', icon: <IconNumericEq fill="currentcolor" /> },
            NE: { name: 'HIDE_THIS', icon: <IconNumericNoteq fill="currentcolor" /> },

            LIKE: { name: 'SHOW_ONLY_THIS', icon: <IconNumericEq fill="currentcolor" /> },
            NOTLIKE: { name: 'HIDE_THIS', icon: <IconNumericNoteq fill="currentcolor" /> },
        };

        const FILTER = this.uiSettings.find((setting) => setting.id === column);
        if (FILTER !== undefined) {
            switch (FILTER.type) {
                case 'checkbox':
                    return {
                        EQ: {
                            name: 'SHOW_ONLY_THIS',
                            icon: <IconNumericEq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'EQ', value ? 1 : 0),
                        },
                        NE: {
                            name: 'HIDE_THIS',
                            icon: <IconNumericNoteq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'EQ', value ? 0 : 1),
                        },
                    };
                case 'text':
                    return {
                        EQ: {
                            name: 'SHOW_ONLY_THIS',
                            icon: <IconNumericEq fill="currentcolor" />,
                            clbk: () =>
                                this.activateLocalFilter(FILTER, value === '' || value === null ? 'EQ' : 'LIKE', value),
                        },
                        NE: {
                            name: 'HIDE_THIS',
                            icon: <IconNumericNoteq fill="currentcolor" />,
                            clbk: () =>
                                this.activateLocalFilter(
                                    FILTER,
                                    value === '' || value === null ? 'NE' : 'NOTLIKE',
                                    value
                                ),
                        },
                    };
                case 'number':
                    return {
                        EQ: {
                            name: 'SHOW_ONLY_THIS',
                            icon: <IconNumericEq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'EQ', value),
                        },
                        NE: {
                            name: 'HIDE_THIS',
                            icon: <IconNumericNoteq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'NE', value),
                        },
                        LE: {
                            name: 'SHOW_LOWER',
                            icon: <IconNumericLeeq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'LE', value),
                        },
                        GE: {
                            name: 'SHOW_HIGHER',
                            icon: <IconNumericGreq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'GE', value),
                        },
                    };
                case 'slider':
                    return {
                        EQ: {
                            name: 'SHOW_ONLY_THIS',
                            icon: <IconNumericEq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'EQ', value),
                        },
                        NE: {
                            name: 'HIDE_THIS',
                            icon: <IconNumericNoteq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'NE', value),
                        },
                        LE: {
                            name: 'SHOW_LOWER',
                            icon: <IconNumericLeeq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'LE', value),
                        },
                        GE: {
                            name: 'SHOW_HIGHER',
                            icon: <IconNumericGreq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'GE', value),
                        },
                    };
                case 'timestamp':
                    return value === null
                        ? {
                              EQ: {
                                  name: 'SHOW_ONLY_THIS',
                                  icon: <IconNumericEq fill="currentcolor" />,
                                  clbk: () => this.activateLocalFilter(FILTER, 'EQ', null),
                              },
                              NE: {
                                  name: 'HIDE_THIS',
                                  icon: <IconNumericNoteq fill="currentcolor" />,
                                  clbk: () => this.activateLocalFilter(FILTER, 'NE', null),
                              },
                          }
                        : {
                              EQ: {
                                  name: 'SHOW_ONLY_THIS',
                                  icon: <IconNumericEq fill="currentcolor" />,
                                  clbk: () =>
                                      this.activateLocalFilter(FILTER, 'EQ', { startDate: value, endDate: value }),
                              },
                              LE: {
                                  name: 'SHOW_BEFORE',
                                  icon: <IconNumericLeeq fill="currentcolor" />,
                                  clbk: () =>
                                      this.activateLocalFilter(FILTER, 'LE', { startDate: value, endDate: value }),
                              },
                              GE: {
                                  name: 'SHOW_AFTER',
                                  icon: <IconNumericGreq fill="currentcolor" />,
                                  clbk: () =>
                                      this.activateLocalFilter(FILTER, 'GE', { startDate: value, endDate: value }),
                              },
                          };
                case 'select-time-lg':
                    return {
                        LE: {
                            name: 'SHOW_BEFORE',
                            icon: <IconNumericLeeq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'GE', value),
                        },
                        GE: {
                            name: 'SHOW_AFTER',
                            icon: <IconNumericGreq fill="currentcolor" />,
                            clbk: () => this.activateLocalFilter(FILTER, 'LE', value),
                        },
                    };
                case 'select':
                    return FILTER.operators.items.reduce((acc, operator) => {
                        acc[operator] = {
                            name: SELECT_OPERATORS[operator].name,
                            icon: SELECT_OPERATORS[operator].icon,
                            clbk: () => this.activateLocalFilter(FILTER, operator, value),
                        };
                        return acc;
                    }, {});
                case 'groupsTree':
                    return {
                        EQ: {
                            clbk: () => this.activateLocalFilter(FILTER, 'EQ', value),
                        },
                    };
                default:
                    return null;
            }
        }
        return null;
    }

    activateLocalFilter(filter, operator, value) {
        filter.visible = true;
        filter.active = true;
        filter.blank = false;
        filter.operator = operator;

        if (filter.type === 'select' || filter.type === 'select-time-lg') {
            if (value === null) {
                filter.operator = 'EQ';
                filter.value = operator === 'EQ' ? SELECT_UNKNOWN_OPTION : SELECT_KNOWN_OPTION;
            } else {
                filter.value = filter.values.items.find((item) => item.id === value);
                if (filter.value === undefined) {
                    filter.value = filter.values.items.find((item) => value.startsWith(item.id));
                }
            }
        } else {
            if (value === '' || value === null) {
                filter.blank = true;
            }
            filter.value = value;
        }
        this.notifyChange(false);
    }

    handleFiltersPanelChange = (newVisibleFilters) => {
        const visibleFilters = newVisibleFilters || [];
        let localFiltersChanged = false;
        let localFiltersLayoutChanged = false;
        let localFiltersObjectShifted = null;

        for (const setting of this.uiSettings) {
            const FILTER = visibleFilters.find((filter) => filter.eid === setting.id);
            if (FILTER !== undefined && !setting.visible) {
                // Filter was selected.
                setting.visible = true;
                localFiltersLayoutChanged = true;
                localFiltersObjectShifted = { id: setting.id, visible: true };

                if (
                    !setting.blank &&
                    (setting.type === 'text' || setting.type === 'number' || setting.type === 'groupsTree')
                ) {
                    // Text and number filter but without blank option set.
                    setting.value = '';
                    if (!setting.visible && setting.active) {
                        setting.active = false;
                        localFiltersChanged = true;
                    }
                } else {
                    // These filters are active (blue) if visible and inactive if not.
                    if (setting.visible !== setting.active) {
                        setting.active = setting.visible;
                        localFiltersChanged = true;
                    }
                }
            } else if (FILTER === undefined && setting.type !== 'button' && setting.visible) {
                // Filter was closed.
                setting.visible = false;
                localFiltersLayoutChanged = true;
                localFiltersObjectShifted = { id: setting.id, visible: false };

                if (!setting.blank && (setting.type === 'text' || setting.type === 'number')) {
                    setting.value = '';
                }

                if (setting.active) {
                    setting.active = false;
                    localFiltersChanged = true;
                }
            }
        }

        if (this.state.dashboardFilterVisible) {
            if (!visibleFilters.find((filter) => filter.ei_dashboard)) {
                this.handleDashboardClose();
            }
        }
        this.setState({ filterPanelValue: visibleFilters });

        if (localFiltersChanged) {
            this.notifyChange(false, localFiltersObjectShifted);
        } else if (localFiltersLayoutChanged) {
            this.notifyChange(true, localFiltersObjectShifted);
        }
    };

    handleMenuListStyles = (base) => ({
        ...base,
        textTransform: 'uppercase',
    });

    handleMenuPortalStyle = (base) => ({
        ...base,
        zIndex: 99,
    });

    convertDbTagsIntoUiTags(tags) {
        return tags !== undefined ? tags.map((tag) => ({ value: tag.id, label: tag.name })) : [];
    }

    handleActiveTagsChange = (value) => {
        if (this.props.onTagsChange !== null) {
            this.props.onTagsChange(null, value);
        }
    };

    //---------------------------------------------------------
    render() {
        return (
            <TagsPanel
                className="ei-full-width"
                testDataLabel="eid-filter"
                isFilterPanel
                addFilterLabel={i18n('ADD_FILTER')}
                presetsLabel={i18n('PRESETS')}
                actionsPanelContent={
                    this.props.presets && (
                        <LocalFiltersPresets
                            tableApi={this.props.tableApi}
                            storage={this.props.storage}
                            visible={this.props.presets && this.props.presets.visible}
                            default={this.props.presets && this.props.presets.default}
                            actions={this.props.presets && this.props.presets.actions}
                            initialTableState={this.props.initialTableState}
                            activeTags={this.convertDbTagsIntoUiTags(this.props.activeTags)}
                            onActiveTagsChange={this.handleActiveTagsChange}
                            localFilters={this}
                        />
                    )
                }
                btnInspect={
                    this.props.idParameter === undefined
                        ? false
                        : {
                              title: i18n('ESMC'),
                              onClick: () => openEraInNewTab(this.props.componentUuid, this.props.idParameter),
                          }
                }
                btnRefresh={this.props.onRefresh && { title: i18n('REFRESH'), onClick: this.props.onRefresh }}
            >
                <Select
                    menuPortalTarget={this.props.menuPortalTarget || document.getElementById('root')}
                    className={!this.props.visible ? 'local-filters-hidden' : 'local-filters-text-uppercase'}
                    options={this.state.filterPanelOptions}
                    value={this.state.filterPanelValue}
                    onChange={this.handleFiltersPanelChange}
                    styles={{
                        menuList: this.handleMenuListStyles,
                        menuPortal: this.handleMenuPortalStyle,
                    }}
                />
            </TagsPanel>
        );
    }
}

//-----------------------------------------------------------------------------
LocalFilters.propTypes = {
    visible: PropTypes.bool,
    onTagsChange: PropTypes.func,

    grouping: PropTypes.shape({
        visible: PropTypes.bool,
        //options: [],
        //value: null,
    }),

    dashboard: PropTypes.shape({
        visible: PropTypes.bool,
        label: PropTypes.string,
        //action: null,
    }),

    subgroups: PropTypes.shape({
        visible: PropTypes.bool,
        value: PropTypes.bool,
    }),

    // filters: {

    // },
};

//-----------------------------------------------------------------------------
LocalFilters.defaultProps = {
    visible: true,
    onTagsChange: null,

    grouping: {
        visible: false,
        options: [],
        value: 0,
    },

    dashboard: {
        visible: false,
        label: '',
        action: null,
    },

    subgroups: {
        visible: false,
        value: false,
    },

    filters: {},
};

export default withBackend(LocalFilters);
